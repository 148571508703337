import React, { Component } from "react";
import { Card, Row, Col, Statistic, Typography, Space, message, Popover } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import Sequence from "../../../components/sequence.jsx";
import DataApi from "../../../lib/api/data/index";
const { Title, Text } = Typography;
// eslint-disable-next-line
const { Meta } = Card;
const content1 = (
  <div>
    <p>本月销售所有订单金额总和</p>
  </div>
);
const content2 = (
  <div>
    <p>本月销售所有订单总量</p>
  </div>
);
const content3 = (
  <div>
    <p>昨日销售所有订单总金额</p>
  </div>
);
require("./index.less");
// const Demo = () => {
//   let rank_card = [];
//   let rank_list = [];
//   for (let i = 1; i < 7; i++) {

//   }

//   return rank_card;
// };
class Overview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      monthOrderTotal: 0,
      monthSaleTotal: 0,
      saleUserTotal: 0,
      yesterdaySaleTotal: 0,
      groupUserList: [],
    };
  }
  componentWillMount() {
    this.GetUserInfo();
  }
  GetUserInfo = () => {
    DataApi.GetOverViewData({}).then(
      (res) => {
        console.log(res);
        if (res.code === 1) {
          this.setState({
            monthOrderTotal: res.data.monthOrderTotal ? res.data.monthOrderTotal:0,
            monthSaleTotal: res.data.monthSaleTotal ? res.data.monthSaleTotal:0,
            saleUserTotal: res.data.saleUserTotal,
            yesterdaySaleTotal: res.data.yesterdaySaleTotal,
            groupUserList: res.data.groupUserCensus,
          });
        } else {
          message.error(res.msg);
        }
      },
      (error) => {
        console.log("failed!");
      }
    );
  };
  GetRankList = (list) => {
    let rankList = [];
    list.forEach((items, index) => {
      rankList.push(
        <li
          className="item flex justfiy-content-betweent align-item-center"
          key={index}
        >
          <Sequence
            index={index + 1}
            color={index <= 2 ? "#002140" : null}
            key={index}
          ></Sequence>
          <Text ellipsis={true} style={{ width: "120px" }}>
            {items.nickname}
          </Text>
          <Text ellipsis={true} style={{ width: "60px" }}>
            {items.orderTotal}
          </Text>
          <Text ellipsis={true} style={{ width: "60px" }}>
            {items.saleTotal}
          </Text>
        </li>
      );
    });
    return rankList;
  };
  render = () => {
    let rankCard = [];
    this.state.groupUserList.forEach((item, index) => {
      rankCard.push(
        <Col span={8} key={index}>
          <Card className="large">
            <div className="content flex justfiy-content-betweent align-item-center">
              <Title level={5}>{item.groupName}</Title>
              <Space direction="vertical" className="detail">
                <Text ellipsis={true} style={{ width: "180px" }}>
                  总业绩：{item.saleTotal}
                </Text>
                <Text ellipsis={true} style={{ width: "180px" }}>
                  订单数：{item.orderTotal}
                </Text>
                <Text ellipsis={true} style={{ width: "180px" }}>
                  平均业绩：
                  {item.groupUserCount === 0
                    ? "0"
                    : (item.saleTotal / item.groupUserCount).toFixed(2)}
                </Text>
              </Space>
            </div>
            <div className="footer">
              <ul className="rank ">
                <li className="header flex justfiy-content-betweent align-item-center">
                  <Text ellipsis={true} style={{ width: "20px" }}></Text>
                  <Text ellipsis={true} style={{ width: "120px" }}>
                    员工
                  </Text>
                  <Text ellipsis={true} style={{ width: "60px" }}>
                    订单数
                  </Text>
                  <Text ellipsis={true} style={{ width: "60px" }}>
                    金额
                  </Text>
                </li>
                {this.GetRankList(item.rank)}
              </ul>
            </div>
          </Card>
        </Col>
      );
    });
    return (
      <div className="data-page overview">
        <Row gutter={30}>
          <Col span={6}>
            <Card className="middle">
              <div className="header flex justfiy-content-betweent">
                <Statistic
                  title="本月总销售额"
                  value={this.state.monthSaleTotal}
                ></Statistic>
                <Popover content={content1} title="Title">
                <InfoCircleOutlined
                  style={{ color: "rgba(0, 0, 0, 0.45)", lineHeight: "25px" }}
                ></InfoCircleOutlined>
                </Popover>
              </div>
              <div className="content">
                <p>
                  人均销售额
                  {this.state.saleUserTotal!==0 ? (
                    this.state.monthSaleTotal / this.state.saleUserTotal
                  ).toFixed(2) : ""}
                </p>
              </div>
            </Card>
          </Col>
          <Col span={6}>
            <Card className="middle">
              <div className="header flex justfiy-content-betweent">
                <Statistic
                  title="本月订单量"
                  value={this.state.monthOrderTotal}
                ></Statistic>
                <Popover content={content2} title="Title">
                <InfoCircleOutlined
                  style={{ color: "rgba(0, 0, 0, 0.45)", lineHeight: "25px" }}
                ></InfoCircleOutlined>
                </Popover>
              </div>
              <div className="content">
                <p>
                  人均订单量
                  {
                    this.state.saleUserTotal!==0 ? (
                      this.state.monthOrderTotal / this.state.saleUserTotal
                    ).toFixed(2) : ''
                  }
                </p>
              </div>
            </Card>
          </Col>
          {/* <Col span={6}>
            <Card className="middle">
              <div className="header flex justfiy-content-betweent">
                <Statistic title="本月人效比" value={112893}></Statistic>
                <InfoCircleOutlined
                  style={{ color: "rgba(0, 0, 0, 0.45)", lineHeight: "25px" }}
                ></InfoCircleOutlined>
              </div>
              <div className="content opacity-0">
                <p>人均销售额 12000</p>
              </div>
            </Card>
          </Col> */}
          <Col span={6}>
            <Card className="middle">
              <div className="header flex justfiy-content-betweent">
                <Statistic
                  title="昨日营收"
                  value={this.state.yesterdaySaleTotal}
                ></Statistic>
                <Popover content={content3} title="Title">
                <InfoCircleOutlined
                  style={{ color: "rgba(0, 0, 0, 0.45)", lineHeight: "25px" }}
                ></InfoCircleOutlined>
                </Popover>
              </div>
              <div className="content opacity-0">
                <p>人均销售额 12000</p>
              </div>
            </Card>
          </Col>
        </Row>
        <Row gutter={20} style={{ marginTop: "30px" }}>
          {rankCard}
        </Row>
      </div>
    );
  };
}
export default Overview;
