import request from '../../request';
/**
 * 体验班级用户列表
 */
const GetClassUserList = ({
    id,
    start,
    rows,
    // userName,
    userMobile,
    userDubbingId,
    courseCountMin,
    courseCountMax,
    couponCountMin,
    couponCountMax,
    ageMin,
    ageMax,
    foundationMin,
    foundationMax,
    goodType,
    level,
    intention,
    isVipUser = 1
}) => {
    return request
        .get(`/magicClass/tasteClassUser`, {
            params: {
                classId: id,
                start,
                rows,
                isAppUser: isVipUser,
                mobile: userMobile,
                userNumber: userDubbingId,
                goodType,
                level,
                finishCourseCountMin: courseCountMin,
                finishCourseCountMax: courseCountMax,
                couponCountMin: couponCountMin,
                couponCountMax: couponCountMax,
                ageMin,
                ageMax,
                foundationMin,
                foundationMax,
                purpose: intention,
            }
        })
        .then(result => {
            return result.data
        })
}

const GetUserCoupoon = ({
    uid,
}) => {
    return request
        .get(`/user/userCoupon`, {
            params: {
                uid,
            }
        })
        .then(result => {
            return result.data
        })
}

const GetAllCoupon = ({
    title,
}) => {
    return request
        .get(`/coupon/lists`, {
            params: {
                title,
            }
        })
        .then(result => {
            return result.data
        })
}

/**
 * 发券---post请求
 */
const SendCoupon = ({
    userNumbers,
    couponId,
}) => {
    return request
        .post(`/coupon/giveCoupon`, {
            userNumbers,
            couponId,
        })
        .then(response => {
            return response.data
        })
}

const GetStudyReport = ({
    uid,
    type = 0,
    levelId,
    dumbvld,
    day
}) => {
    return request
        .get(`/magicClass/studyReport`, {
            params: {
                uid,
                type,
                levelId,
                dumbvld,
                day
            }
        })
        .then(result => {
            return result.data
        })
}

// eslint-disable-next-line
export default {
    GetClassUserList,
    GetUserCoupoon,
    GetAllCoupon,
    SendCoupon,
    GetStudyReport
}