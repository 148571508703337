import request from '../../request';


/**
 * 获取歌曲列表---Get请求示例
 */
function GetUserInfo() {
    return request
        .get(`/index/index`, {
            params: {}
        })
        .then(result => {
            return result.data
        })
}

// eslint-disable-next-line
export default {
    GetUserInfo
}