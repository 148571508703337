import request from '../request';
const env = process.env.NODE_ENV

function IsTest() {
    let hostUrl = window.location.href
    return hostUrl.indexOf('crm.qsongxue.com') === -1
}
const tokenHost =
    env === 'development' ?
    'https://actsdtest.qupeiyin.cn' :
    IsTest() === true ?
    'https://actsdtest.qupeiyin.cn' :
    'https://actsd.qupeiyin.cn'

const GetQiniuToken = () => {
    return request
        .get(`${tokenHost}/qiniu/token`, {
            needSign: true,
            needAuth: false,
        })
        .then(response => {
            return response.data
        })
}

//eslint-disable-next-line
export default {
    GetQiniuToken
}