import axios from "axios";
import qs from 'qs'
import {
    HTTP_CODE
} from './const'

const env = process.env.NODE_ENV

console.log(env)

function IsTest() {
    let hostUrl = window.location.href
    return hostUrl.indexOf('https://crm-h5.qsongxue.com') === -1
}

const LoginUrl =
    window.location.href.indexOf('https://crm-h5.qsongxue.com') !== -1 ?
    'https://crm.qsongxue.com/login' :
    'https://crm-test.qsongxue.com/login'
    
console.log(LoginUrl)
const HOST =
    env === 'development' ?
    'https://crm-test.qsongxue.com' :
    IsTest() === true ?
    'https://crm-test.qsongxue.com' :
    'https://crm.qsongxue.com'

const CancelToken = axios.CancelToken
const source = CancelToken.source()

const request = axios.create({
    baseURL: HOST,
    timeout: 30000
})

request.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

request.interceptors.request.use(config => {
    if (config.cancel) {
        config.cancelToken = source.token
        source.cancel()
    }
    let token = localStorage.getItem("token");

    config.url = subSplash(config.url)
    // token验证
    // eslint-disable-next-line
    if (token !== '') {
        if (token && config.noToken !== true) {
            if (config.method === 'get') {
                config.params = {
                    ...config.params,
                }
            } else if (config.method === 'post') {
                config.data = qs.stringify({
                    ...config.data
                })
            }
        }
    }

    // if (config.needSign) {
    //     if (!config.data) {
    //         config.params = auth.Auth(config.params)
    //     } else {
    //         config.data = auth.Auth(config.data)
    //     }
    // }
    if (config.needSign || config.needFormData) {
        console.log("1122")
    }else {
        config.headers['Authorization'] = `Bearer ${token}`
    }
    // if (config.needFormData) {
    //     config.data = qs.stringify(config.data)
    //     console.log("上传图片")
    //     config.headers['Content-Type'] = 'multipart/form-data'
    // }

    // config.headers['Access-Control-Allow-Origin'] = `*`

    return config
})

// 删除restapi末尾的反斜杠
function subSplash(url) {
    return url.endsWith('/') ? url.slice(0, -1) : url
}

request.interceptors.response.use(
    response => {
        // 错误统一拦截
        // if (parseInt(response.data.status) === HTTP_CODE.ServerError) {
        //     alert('登录已过期，请重新登录');
        //     window.location.href = LoginUrl
        // }
        if (parseInt(response.data.code) === HTTP_CODE.ServerError) {
            console.log("response", response)
            alert('登录已过期，请重新登录');
            if(env === 'development'){
                // window.location.href = 'http://crm.qsongxue.com/h5/userlogin'
              window.location.href = '/login'
            }else {
              window.location.href = LoginUrl
            }
        }
        return response
    },
    error => {
        return Promise.reject(error)
    }
)

/**
 * http request 拦截器
 */
axios.interceptors.request.use(
    (config) => {
        config.data = JSON.stringify(config.data);
        config.headers = {
            "Content-Type": "application/x-www-form-urlencoded",
        };
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default request