import React, { Component, useState } from "react";
import {
  message,
  Modal,
  Button,
  Table,
  Tag,
  Space,
  Select,
  Input,
  Affix,
} from "antd";
import { ExclamationCircleOutlined, CloseOutlined, FormOutlined } from "@ant-design/icons";
import TagApi from "../../../lib/api/tag/index";
import MenuApi from "../../../lib/api/menus/index";
require("./index.less");
const { Option } = Select;

class TagManage extends Component {
  state = {
    isModalVisible: false,
    tagLibrary: false,
    addTagGroupModal: false,
    actionTagData: [],
    allTagLists: [],
    editRecord: {},
    selectedActTagList: [],
    groupName: "", //新增分组名称
    groupTagLists: [], //全部分组
    editTagModal: false, //编辑分组对话框
    editTagNameModal: false, //编辑标签名称弹窗
    editGroupInfo: {}, //分组消息
    editGroupIndex: null, //被编辑组的index
    addTagInput: false, //是否开启添加标签
    tagName: "", //新标签页的名字
    editTagName: "",
    editTagId: "",
    allRuleInfo: [],
    allRuleList: [
      {
        name: "enterpriseWechat/actionTagAddOrMod",
        title: "编辑行为标签",
        show: false,
      },
      {
        name: "enterpriseWechat/createTagOrTagGroup",
        title: "添加分组",
        show: false,
      },
      {
        name: "enterpriseWechat/updateTagOrTagGroup",
        title: "编辑标签或标签组",
        show: false,
      },
      {
        name: "enterpriseWechat/deleteTagOrTagGroup",
        title: "删除标签或标签组",
        show: false,
      },
    ],
  };
  UNSAFE_componentWillMount() {
    this.GetActionTagInfo(); //获取行为标签
    this.GetTagInfo(); //获取标签分组信息
    this.GetMenus()
  }
  GetMenus = () => {
    MenuApi.GetMenuList().then(
      (res) => {
        if (res) {
          if (res.code === 1) {
            this.setState({
              allRuleInfo: res.data.auth,
              // isSuperAdmin: res.data.isSuperAdmin,
            });
            this.GetRuleList();
          } else {
            message.warning(res.msg);
          }
        }
      },
      (error) => {
        console.log("failed!");
      }
    );
  };
  GetRuleList = () => {
    let authList = this.state.allRuleInfo;
    let list = authList.map((item, index) => {
      return {
        name: item.name,
        title: item.title,
        id: item.id,
      };
    });
    this.GetBtnStatus(list);
  };

  //获取按钮显示隐藏
  GetBtnStatus = (list) => {
    let allRule = this.state.allRuleList;
    list.forEach((item) => {
      allRule.map((items) => {
        if (item.name === items.name) {
          items.show = true;
        }
        return {
          ...items,
        };
      });
    });
    console.log("this.state.allRuleList", this.state.allRuleList);
  };
  GetActionTagInfo = () => {
    TagApi.GetActionTag()
      .then((res) => {
        console.log("res2222", res);
        let list = res.data.tagLists;
        this.setState(
          {
            allTagLists: res.data.tagLists,
            actionTagData: res.data.enterpriseWechatActionTagLists.map(
              (item, index) => {
                let tagList = [];
                let nameList = [];
                if (item.tag !== "") {
                  list.forEach((tag, tagIndex) => {
                    item.tag.split(",").forEach((items, indexs) => {
                      if (items === tag.id) {
                        nameList.push(tag.name);
                      }
                    });
                  });
                }
                return {
                  ...item,
                  nameList: nameList,
                  tagList: tagList,
                };
              }
            ),
          },
          function () {
            console.log(this.state.allTagLists);
          }
        );
      })
      .catch((res) => {
        console.log("error");
      });
  };
  GetTagInfo = () => {
    TagApi.GetTagList()
      .then((res) => {
        console.log("标签库信息", res);
        this.setState(
          {
            groupTagLists: res.data.groupTagLists.map((item, index) => {
              item.index = index;
              return {
                ...item,
              };
            }),
          },
          function () {
            if (this.state.editGroupIndex !== null) {
              this.setState({
                editGroupInfo: this.state.groupTagLists[
                  this.state.editGroupIndex
                ],
              });
            }
          }
        );
      })
      .catch((res) => {
        console.log("error");
      });
  };
  OpenEditActTagModal = () => {
    this.setState({
      isModalVisible: true,
    });
  };
  HandleChangeTagList = (res) => {
    this.setState(
      {
        selectedActTagList: res,
      },
      function () {
        console.log(this.state.selectedActTagList);
      }
    );
  };
  //提交编辑行为标签
  HandleEditActTag = () => {
    TagApi.EditActionTag({
      id: this.state.editRecord.id,
      tagIds: this.state.selectedActTagList.join(","),
    }).then((res) => {
      if (res.code === 1) {
        message.success("操作成功");
        this.GetActionTagInfo();
        this.setState({
          isModalVisible: false,
        });
      } else {
        message.error(res.msg);
      }
    });
  };
  //输入框输入绑定
  AddName = (e) => {
    this.setState({
      groupName: e.target.value,
    });
  };
  AddTagName = (e) => {
    this.setState({
      tagName: e.target.value,
    });
  };
  EditTagNameInfo = (e) => {
    this.setState({
      editTagName: e.target.value,
    });
  }
  AddNewTag = () => {
    let continueAdd = true
    console.log("失去焦点", this.state.editGroupInfo);
    if (this.state.tagName === "") {
      message.info("请输入标签名称");
      return false;
    }
    this.state.allTagLists.forEach((item)=>{
      if(this.state.tagName===item.name){
        message.error("标签名重复")
        continueAdd = false
      }
    })
    if(continueAdd){
      this.AddNewTagClick()
    }
  };
  AddNewTagClick =()=>{
    console.log("继续执行")
    TagApi.AddTagList({
      tagName: this.state.tagName,
      groupId: this.state.editGroupInfo.group_id,
    }).then((res) => {
      if (res.code === 1) {
        message.success("操作成功");
        this.GetTagInfo();
        this.GetActionTagInfo()
        this.setState({
          tagName: "",
          addTagInput: false,
        });
      } else {
        message.error(res.msg);
      }
    });
  }
  HandleEditTagName= ()=> {
    let continueAdd = true
    this.state.allTagLists.forEach((item)=>{
      if(this.state.editTagName===item.name){
        message.error("标签名重复")
        continueAdd = false
      }
    })
    if(continueAdd){
      this.HandleEditTagNameContinue()
    }
  }
  HandleEditTagNameContinue=()=>{
    TagApi.EditTagList({
      id: this.state.editTagId,
      name: this.state.editTagName,
    }).then((res) => {
      if (res.code === 1) {
        message.success("操作成功");
        this.GetTagInfo();
        this.GetActionTagInfo()
        this.setState({
          editTagNameModal: false,
        });
      } else {
        message.error(res.msg);
      }
    });
  }

  HandleAddGroup = () => {
    if (this.state.groupName === "") {
      message.error("分组名称不能为空");
      return false;
    }
    TagApi.AddTagList({
      groupName: this.state.groupName,
      tagName: "默认标签",
    }).then((res) => {
      if (res.code === 1) {
        message.success("操作成功");
        this.GetTagInfo();
        this.setState({
          addTagGroupModal: false,
          groupName: "",
        });
      } else {
        message.error(res.msg);
      }
    });
  };

  //删除标签对话框
  DeleteTagPopup = (e, item) => {
    Modal.confirm({
      title: "删除标签/组",
      icon: <ExclamationCircleOutlined />,
      content: "删除标签后，用户标签将被删除，是否还要继续？",
      okText: "继续",
      cancelText: "取消",
      onOk: () => {
        this.DeleteTag(item.id);
      },
    });
  };
  EditTagPopup = (e,item) => {
    console.log("item",item)
    this.setState({
      editTagNameModal: true,
      editTagName: item.name,
      editTagId: item.id
    })
  }
  DeleteTag = (id) => {
    if(this.state.editGroupInfo.tag.length<=1){
      this.setState({
        editTagModal: false,
      });
    }
    TagApi.DeleteTagList({
      tagId: id,
    }).then((res) => {
      if (res.code === 1) {
        message.success("操作成功");
        this.GetTagInfo();
      } else {
        message.error(res.msg);
      }
    });
  };
  DeleteGroup = (id) => {
    TagApi.DeleteTagList({
      groupId: id,
    }).then((res) => {
      if (res.code === 1) {
        message.success("操作成功");
        this.GetTagInfo();
        this.setState({
          editTagModal: false,
        });
      } else {
        message.error(res.msg);
      }
    });
  };
  HandleCancel = () => {
    this.setState({
      isModalVisible: false,
      addTagGroupModal: false,
      editTagModal: false,
      editTagNameModal: false,
      groupName: "",
    });
  };

  render() {
    const columns = [
      {
        title: "动作",
        dataIndex: "action_description",
        width: 240,
        key: "1",
      },
      {
        title: "标签",
        key: "2",
        dataIndex: "nameList",
        render: (nameList) => (
          <>
            {nameList.length > 0
              ? nameList.map((tag, index) => {
                  return (
                    <Tag color="#2db7f5" key={index}>
                      {tag.toUpperCase()}
                    </Tag>
                  );
                })
              : "暂无标签"}
          </>
        ),
      },
      {
        title: "操作",
        key: "action",
        render: (text, record) => (
          <Space size="middle">
            <Button
            style={{display: this.state.allRuleList[0].show?"block": "none"}}
              onClick={() => {
                console.log("record", record);
                let tagList = [];
                if (record.tag === "") {
                  tagList = [];
                } else {
                  tagList = record.tag.split(",");
                }
                this.setState({
                  editRecord: record,
                  selectedActTagList: tagList,
                  isModalVisible: true,
                });
              }}
            >
              编辑
            </Button>
          </Space>
        ),
      },
    ];
    return (
      <div className="tag-page">
        <div style={{ display: this.state.tagLibrary ? "none" : "block" }}>
          <div className="add-group">
            <Button
              type="primary"
              onClick={() => {
                this.setState({ tagLibrary: true });
              }}
              className="add-btn"
            >
              标签库
            </Button>
          </div>
          <Table
            size={"small"}
            pagination={false}
            columns={columns}
            dataSource={this.state.actionTagData}
          />
        </div>
        <div style={{ display: this.state.tagLibrary ? "block" : "none" }}>
          <Affix offsetTop={100}>
            <div className="add-group">
              <Button
                type="primary"
                onClick={() => {
                  this.GetActionTagInfo();
                  this.setState({ tagLibrary: false });
                }}
                className="back-btn"
              >
                返回
              </Button>
              <Button
                type="primary"
                style={{display: this.state.allRuleList[1].show?"block": "none"}}
                onClick={() => {
                  this.setState({ addTagGroupModal: true });
                }}
                className="add-btn"
              >
                添加分组
              </Button>
            </div>
          </Affix>
          {this.state.groupTagLists.map((item, index) => {
            return (
              <div className="group-list" key={index}>
                <p>{item.group_name}</p>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    {item.tag.length > 0
                      ? item.tag.map((tag, num) => {
                          return (
                            <Tag color="#2db7f5" key={num}>
                              {tag.name}
                            </Tag>
                          );
                        })
                      : "暂无标签"}
                  </div>
                  <Button
                    onClick={() => {
                      this.setState(
                        {
                          editTagModal: true,
                          editGroupInfo: item,
                          editGroupIndex: item.index,
                        },
                        function () {
                          console.log(this.state.editGroupInfo);
                        }
                      );
                    }}
                  >
                    编辑
                  </Button>
                </div>
              </div>
            );
          })}
        </div>
        <Modal
          title="编辑标签"
          visible={this.state.isModalVisible}
          onOk={this.HandleEditActTag}
          onCancel={this.HandleCancel}
        >
          <p>动作：{this.state.editRecord.action_description}</p>
          <Select
            mode="multiple"
            placeholder="请选择"
            value={this.state.selectedActTagList}
            defaultValue={[]}
            onChange={this.HandleChangeTagList}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            maxTagCount={3}
            style={{ width: "100%" }}
          >
            {this.state.allTagLists.map((item, index) => {
              return (
                <Option value={item.id} key={item.id + "" + index}>
                  {item.name}
                </Option>
              );
            })}
          </Select>
        </Modal>
        <Modal
          title="添加分组"
          visible={this.state.addTagGroupModal}
          onOk={this.HandleAddGroup}
          onCancel={this.HandleCancel}
        >
          标签组：
          <Input
            value={this.state.groupName}
            onChange={(e) => this.AddName(e)}
            maxLength={30}
            style={{ width: "160px" }}
          ></Input>
        </Modal>
        <Modal
          title="编辑分组"
          visible={this.state.editTagModal}
          footer={null}
          onCancel={this.HandleCancel}
        >
          <div className="tag-container">
            {this.state.editGroupInfo.tag
              ? this.state.editGroupInfo.tag.map((item, index) => {
                  return (
                    <div className="tag-del" key={index}>
                      <Button>{item.name}</Button>
                      <Button
                        size="small"
                        shape="circle"
                        style={{opacity: this.state.allRuleList[2].show? 1: 0}}
                        icon={<FormOutlined />}
                        onClick={(e) => this.EditTagPopup(e,item)}
                      ></Button>
                      <Button
                        size="small"
                        shape="circle"
                        style={{opacity: this.state.allRuleList[3].show? 1: 0}}
                        icon={<CloseOutlined />}
                        onClick={(e) => this.DeleteTagPopup(e, item)}
                      ></Button>
                    </div>
                  );
                })
              : "暂无标签"}
          </div>
          <div className="add-tag">
            {this.state.addTagInput ? (
              <Input
                onBlur={() => {
                  this.AddNewTag();
                }}
                style={{width: "160px"}}
                maxLength={30}
                onChange={(e) => this.AddTagName(e)}
              ></Input>
            ) : (
              ""
            )}
          </div>
          <Button
            style={{display: this.state.allRuleList[2].show?"block": "none"}}
            onClick={() => {
              this.setState({ addTagInput: true });
            }}
          >
            添加标签
          </Button>
          <Button
            style={{display: this.state.allRuleList[3].show?"block": "none"}}
            onClick={() => {
              this.DeleteGroup(this.state.editGroupInfo.group_id);
            }}
          >
            删除标签组
          </Button>
        </Modal>
        <Modal
          title="编辑标签"
          visible={this.state.editTagNameModal}
          onOk={this.HandleEditTagName}
          onCancel={this.HandleCancel}
        >
          标签名称：
          <Input
            value={this.state.editTagName}
            onChange={(e) => this.EditTagNameInfo(e)}
            maxLength={30}
            style={{ width: "160px" }}
          ></Input>
        </Modal>
      </div>
    );
  }
}
export default TagManage;
