import React, { Component } from 'react';

class Sequence extends Component {
   render() {
      let style = {
         rank: {
            width: "20px",
            height: "20px",
            lineHeight: '20px',
            borderRadius: '50%',
            textAlign: "center",
            color: this.props.color ? "#fff" : 'rgba(0, 0, 0, 0.85)',
            background: this.props.color ? this.props.color : 'rgb(240 242 245)'
         }
      }
      return (<div style={style.rank}>
         {this.props.index}
      </div>)
   }
}
export default Sequence