import React, { Component, Fragment } from "react";
import HeaderSearch from "@/components/headerSearch";
import { Table, Button, message, Modal, Select, Input, Badge } from "antd";
import UserApi from "../../../../lib/api/userManage/user";
import MenuApi from "../../../../lib/api/menus/index";
import { Link } from "react-router-dom";
const { Option } = Select;
const { TextArea } = Input;
var bTeacherList = [];

class UserGuideList extends Component {
  constructor(props) {
    super(props);
    console.log("体验用户", props);
    this.state = {
      isModalVisible: false,
      searchKey: "",
      intention: null,
      userRecord: {},
      intentionList: [],
      remark: "",
      total: 0,
      userList: [],
      pageSize: 10,
      pageSizeOption: [5, 10, 15, 20],
      current: 0,
      nickname: "",
      mobile: "",
      userNumber: "",
      purpose: "",
      levelId: "",
      teacherId: "",
      ageMin: "",
      ageMax: "",
      finishCourseCountMin: "",
      finishCourseCountMax: "",
      foundationMin: "",
      foundationMax: "",
      dailyLeftMin: "",
      dailyLeftMax: "",
      ateacher: "",
      bteacher: "",
      isVip: 0,
      formData: [
        // {
        //   name: "name",
        //   label: "用户名",
        //   required: false,
        // },
        {
          name: "mobile",
          label: "手机",
          type: "inputNum",
          required: false,
        },
        {
          name: "id",
          label: "趣配音账号",
          type: "inputNum",
          required: false,
        },
        {
          name: "bTeacher",
          label: "售前老师",
          required: false,
          type: "option",
          list: bTeacherList,
        },
        {
          name: ["complete_course_start", "complete_course_end"],
          label: "近30天上课",
          type: "inputNum",
          required: false,
        },
        {
          name: ["course_start", "course_end"],
          label: "剩余课时",
          type: "inputNum",
          required: false,
        },
        // {
        //   name: "aTeacher",
        //   label: "售后老师",
        //   required: false,
        //   type: "option",
        //   list: [],
        // },
        // {
        //   name: "isBind",
        //   label: "是否关联微信",
        //   required: false,
        //   type: "option",
        //   list: [
        //     {
        //       text: "请选择",
        //       value: 0,
        //     },
        //     {
        //       text: "是",
        //       value: 1,
        //     },
        //     {
        //       text: "否",
        //       value: 2,
        //     },
        //   ],
        // },
      ],
      allRuleInfo: [],
      isSuperAdmin: 0, //是否超级管理员0-->否，1-->是
      allRuleList: [
        {
          name: "magicClass/tasteUserList",
          title: "获取菜单列表",
          show: false,
        },
        {
          name: "magicClass/tasteDetail",
          title: "详情",
          show: false,
        },
        {
          name: "magicClass/followRecordAdd",
          title: "跟进",
          show: false,
        },
      ],
    };
    this.onSearch = this.onSearch.bind(this);
    this.onResetData = this.onResetData.bind(this);
    this.GoDetail = this.GoDetail.bind(this);
    this.searchTeacher = this.searchTeacher.bind(this);
  }
  UNSAFE_componentWillMount() {
    this.GetUserList();
    this.GetTeacherList();
    this.GetMenus();
  }
  GetMenus = () => {
    MenuApi.GetMenuList().then(
      (res) => {
        if (res) {
          if (res.code === 1) {
            this.setState({
              allRuleInfo: res.data.auth,
              isSuperAdmin: res.data.isSuperAdmin,
            });
            this.GetRuleList();
          } else {
            message.warning(res.msg);
          }
        }
      },
      (error) => {
        console.log("failed!");
      }
    );
  };
  GetRuleList = () => {
    let authList = this.state.allRuleInfo;
    let list = authList.map((item, index) => {
      return {
        name: item.name,
        title: item.title,
        id: item.id,
      };
    });
    console.log("ruleList", list);
    this.GetBtnStatus(list);
  };

  //获取按钮显示隐藏
  GetBtnStatus = (list) => {
    let allRule = this.state.allRuleList;
    list.forEach((item) => {
      allRule.map((items) => {
        if (item.name === items.name) {
          items.show = true;
        }
        return {
          ...items,
        };
      });
    });
    console.log("this.state.allRuleList", this.state.allRuleList);
  };

  GetUserList = () => {
    UserApi.VipUserList({
      start: this.state.current,
      rows: this.state.pageSize,
      mobile: this.state.mobile,
      userNumber: this.state.userNumber,
      takeCourseCountMin: this.state.foundationMin,
      takeCourseCountMax: this.state.foundationMax,
      dailyLeftMin: this.state.dailyLeftMin,
      dailyLeftMax: this.state.dailyLeftMax,
      aTeacherId: this.state.ateacher,
      bTeacherId: this.state.bteacher,
    }).then(
      (res) => {
        console.log("vip用户列表", res);
        if (res.code === 1) {
          this.state.formData.forEach((ele) => {
            if (ele.name === "bTeacher") {
              ele.list = res.data.BTeacher.map((item) => {
                return {
                  value: item.id,
                  text: item.nickname,
                };
              });
            }
          });
          this.setState({
            total: res.data.total,
            intentionList: res.data.purpose,
            userList: res.data.list.map((item, index) => {
              item.studyStatus = item.daily_learned;
              item.lessonFliter = item.daily_total - item.daily_unlock;
              if (item.wechatAvatar) {
                item.avatar = item.wechatAvatar;
              }
              if (item.wechatNickName) {
                item.nickname = item.wechatNickName;
              }
              return {
                ...item,
                key: item.uid,
                createTime: new Date().format("yyyy-MM-dd"),
              };
            }),
          });

          bTeacherList = res.data.BTeacher.map((item) => {
            return {
              value: item.id,
              text: item.nickname,
            };
          });
          console.log("bTeacherList", bTeacherList);
          this.state.formData.forEach((ele) => {
            if (ele.name === "aTeacher") {
              ele.list = res.data.ATeacher.map((item) => {
                return {
                  value: item.id,
                  text: item.nickname,
                };
              });
            }
          });
          console.log("UserList ", this.state.userList);
        } else {
          message.error(res.msg);
        }
      },
      (error) => {
        console.log("failed!");
      }
    );
  };
  GetTeacherList = () => {
    UserApi.CetTeacherList({ name: this.state.searchKey }).then((res) => {
      console.log("this is dddddd-aaaaa", res);
      if (parseInt(res.data.code) === 1) {
        this.state.formData.forEach((ele) => {
          if (ele.name === "teacher_list") {
            ele.list = res.data.data.list.map((res) => {
              return {
                value: res.id,
                text: res.name,
              };
            });
          }
        });
        this.setState({
          formData: this.state.formData,
        });
        console.log(this.state.formData);
      }
    });
  };

  HandleCancel = () => {
    this.setState({
      isModalVisible: false,
    });
  };

  HandleIntention = (value) => {
    console.log("选择", value);
    this.setState({
      intention: `${value}`,
    });
  };

  HandleOk = () => {
    UserApi.FollowRecord({
      uid: this.state.userRecord.uid,
      purpose: this.state.intention,
      remark: this.state.remark,
    })
      .then((res) => {
        if (res.code === 1) {
          message.success("操作成功");
          this.setState({
            intention: null,
            remark: "",
            isModalVisible: false,
          });
          this.GetUserList();
        } else {
          message.error(res.msg);
        }
      })
      .catch((error) => {
        console.log(error.request.status);
      });
  };

  EditUser = (cord) => {
    console.log("记录", cord);
    this.setState({
      userRecord: cord,
      isModalVisible: true,
    });
  };
  AddRemark = (e) => {
    this.setState({
      remark: e.target.value,
    });
  };
  CopyPhone = (mobile) => {
    console.log("手机号", mobile);
    var number = document.createElement("input");
    document.body.appendChild(number);
    number.value = `${mobile}`;
    number.select();
    document.execCommand("copy");
    document.body.removeChild(number);
    message.success(`复制成功-->${mobile}`);
  };
  render() {
    const columns = [
      {
        title: "",
        width: 80,
        // dataIndex: "avatar",
        key: "a",
        render: (record) => (
          <Badge
            count={"wx"}
            offset={[0, 40]}
            style={{
              display: record.wechatAvatar ? "block" : "none",
              backgroundColor: "#52c41a",
            }}
          >
            <img
              alt=""
              src={`${record.avatar}`}
              className="avatar"
              style={{ width: "40px", height: "40px", background: "#000" }}
            />
          </Badge>
        ),
      },
      {
        title: "用户名",
        dataIndex: "nickname",
        key: "0",
      },
      {
        title: "手机",
        width: 150,
        dataIndex: "mobile",
        key: "123",
        render: (res) => {
          if (res) {
            let phone = res.split("");
            phone.splice(3, 5, "*****");
            return (
              <abbr title={res} style={{ textDecoration: "none" }}>
                {phone}
                <Button onClick={() => this.CopyPhone(res)}>复制</Button>
              </abbr>
            );
          } else {
            return (
              <abbr title={res} style={{ textDecoration: "none" }}>
                {"-"}
              </abbr>
            );
          }
        },
      },
      {
        title: "趣配音账号",
        dataIndex: "user_number",
        key: "2",
      },
      {
        title: "近30天上课情况",
        dataIndex: "studyStatus",
        key: "3",
      },
      {
        title: "剩余课时",
        dataIndex: "lessonFliter",
        key: "4",
      },
      {
        title: "售前老师",
        // dataIndex: "teacher",
        key: "5",
        render: (res) => {
          if (res.teacher !== "") {
            return (
              <abbr title={res} style={{ textDecoration: "none" }}>
                {res.teacher}
              </abbr>
            );
          } else {
            return (
              <abbr
                title={res}
                style={{ textDecoration: "none", textAlign: "center" }}
              >
                {"-"}
              </abbr>
            );
          }
        },
      },
      {
        title: "操作",
        dataIndex: "op",
        key: "10",
        render: (text, record) => (
          <Fragment>
            <Link to={`/user/Details/${record.uid}`}>
              {this.state.allRuleList[1].show ||
              this.state.isSuperAdmin === 1 ? (
                <Button
                  style={{ marginRight: "12px" }}
                  size="small"
                  type="primary"
                  onClick={this.GoDetail}
                >
                  详情
                </Button>
              ) : (
                <Button
                  style={{ marginRight: "12px" }}
                  size="small"
                  type="primary"
                  disabled
                  onClick={this.GoDetail}
                >
                  详情
                </Button>
              )}
            </Link>
            {this.state.allRuleList[2].show || this.state.isSuperAdmin === 1 ? (
              <Button
                style={{ marginRight: "12px" }}
                size="small"
                type="primary"
                onClick={() => this.EditUser(record)}
              >
                跟进
              </Button>
            ) : (
              <Button
                style={{ marginRight: "12px" }}
                size="small"
                type="primary"
                disabled
                onClick={() => this.EditUser(record)}
              >
                跟进
              </Button>
            )}
            {/* <Button size="small" type="primary" onClick={this.EditUser}>绑定微信</Button> */}
          </Fragment>
        ),
      },
    ];
    let optionDataList = this.state.intentionList.map((items, indexs) => {
      return (
        <Option value={`${items.value}`} key={items.title}>
          {items.title}
        </Option>
      );
    });
    return (
      <div className="list-container">
        <HeaderSearch
          fromData={this.state.formData}
          onSearch={this.onSearch}
          onResetData={this.onResetData}
        ></HeaderSearch>
        <div className="box"></div>
        <Table
          dataSource={this.state.userList}
          columns={columns}
          pagination={
            this.state.total > 10
              ? {
                  position: ["bottomLeft"],
                  pageSize: this.state.pageSize,
                  total: this.state.total,
                  showQuickJumper: true,
                  pageSizeOptions: this.state.pageSizeOption,
                  onChange: (current, pageSize) => {
                    console.log(current, pageSize, (current - 1) * pageSize);
                    this.setState(
                      {
                        current: (current - 1) * pageSize,
                        pageSize: pageSize,
                      },
                      function () {
                        this.GetUserList();
                      }
                    );
                  },
                  onShowSizeChange: (current, size) => {
                    console.log(current, size);
                    this.setState(
                      {
                        current: current * size,
                        pageSize: size,
                      },
                      function () {
                        this.GetUserList();
                      }
                    );
                  },
                }
              : false
          }
        />

        <Modal
          title="添加跟进记录"
          visible={this.state.isModalVisible}
          onOk={this.HandleOk}
          onCancel={this.HandleCancel}
        >
          <div className="common-input-style">
            <div className="introudce">
              <span> * </span> 用户昵称:
            </div>
            {this.state.userRecord.nickname}
          </div>
          <div className="common-input-style">
            <div className="introudce">
              <span> * </span> 用户意向:
            </div>
            <Select
              style={{
                width: 120,
              }}
              value={this.state.intention}
              onChange={this.HandleIntention}
            >
              {optionDataList}
            </Select>
          </div>
          <div className="common-input-style">
            <div className="introudce">
              <span> </span> 备注:
            </div>
            <TextArea
              value={this.state.remark}
              onChange={(e) => this.AddRemark(e)}
              rows={4}
            />
          </div>
        </Modal>
      </div>
    );
  }
  onSearch(data) {
    // todo 搜索请求
    let mobile = "";
    let userNumber = "";
    let course1 = "";
    let course2 = "";
    let ateacher = "";
    let bteacher = "";
    let dailyLeftMin = "";
    let dailyLeftMax = "";
    if (data.mobile) {
      mobile = data.mobile;
    }
    if (data.id) {
      userNumber = data.id;
    }
    if (data.complete_course_start) {
      course1 = data.complete_course_start;
    }
    if (data.complete_course_end) {
      course2 = data.complete_course_end;
    }
    if (data.course_start) {
      dailyLeftMin = data.course_start;
    }
    if (data.course_end) {
      dailyLeftMax = data.course_end;
    }
    if (data.aTeacher) {
      ateacher = data.aTeacher;
    }
    if (data.bTeacher) {
      bteacher = data.bTeacher;
    }
    this.setState(
      {
        mobile: mobile,
        userNumber: userNumber,
        foundationMin: course1,
        foundationMax: course2,
        dailyLeftMin: dailyLeftMin,
        dailyLeftMax: dailyLeftMax,
        ateacher: ateacher,
        bteacher: bteacher,
      },
      function () {
        console.log(this.state.teacherId);
        this.GetUserList();
      }
    );
  }
  onResetData() {
    this.setState(
      {
        start: 0,
        rows: 10,
        mobile: "",
        userNumber: "",
        foundationMin: "",
        foundationMax: "",
        dailyLeftMin: "",
        dailyLeftMax: "",
        ateacher: "",
        bteacher: "",
      },
      function () {
        this.GetUserList();
      }
    );
  }
  GoDetail() {}
  searchTeacher() {
    console.log("输入中");
  }
}

export default UserGuideList;
