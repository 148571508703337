import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Menu } from "antd";
import { BulbOutlined } from "@ant-design/icons";
const { SubMenu } = Menu;
class Menus extends Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }
  render() {
    // console.log("菜单参数",this.props);
    let menus = this.props.menusList.map((item, index) => {
      let chrild = []
      if (item.child) {
        chrild = item.child.map((items, i) => {
          return (
            <Menu.Item key={`/${items.name}`}>
              <Link to={{pathname:`/${items.name}`,state:{menuid:items.id}}}> {items.title} </Link>
            </Menu.Item>
          );
        });
      }
      return (
        <SubMenu
          key={`sub${item.sub}`}
          icon={this.props.iconList[index]}
          title={item.title}
        >
          {chrild}
        </SubMenu>
      );
    });
    return (
      <Menu theme="dark" defaultSelectedKeys={this.props.pathKey} defaultOpenKeys={[`${this.props.openKey}`]} mode="inline">
        <Menu.Item key="/user/WorkBench" icon={<BulbOutlined />}>
          <Link to="/user/WorkBench">工作台</Link>
        </Menu.Item>
        {menus}
      </Menu>
    );
  }
}
export default Menus;
