import React, { Component } from "react";
import { message, Modal, Button, Tabs, Input, Select } from "antd";
import DetailApi from "../../../lib/api/userManage/details";
import UserApi from "../../../lib/api/userManage/user";
import { GetUserDetail, GetWxUserDetail } from "@/lib/api/userManage/vip.js";
import {
  GetQyUserId,
  GetJsapiSign,
  BindUserInfo,
  BindUserMobile,
} from "@/lib/api/weixin/index";

require("./index.less");
const { TabPane } = Tabs;
const { Option } = Select;
const { TextArea } = Input;

class H5userDetails extends Component {
  state = {
    startEdit: false,
    isModalVisible: false,
    intention: "", //用户意向
    remark: "",
    userAvatar: "https://peiyinimg.qupeiyin.cn/1595298884368-10.jpg",
    isBind: true, //用户是否绑定
    userMobile: "", //绑定手机
    userInfo: {
      age: "",
      foundation: "",
    },
    nickName: "我是昵称",
    intentionList: [
      {
        id: 1,
        title: "即将成交",
      },
      {
        id: 2,
        title: "高意向",
      },
      {
        id: 3,
        title: "中意向",
      },
      {
        id: 4,
        title: "低意向",
      },
      {
        id: 5,
        title: "无效用户",
      },
    ],
    orderInfo: [],
    followInfo: [],
    courseInfo: [],
    logistics: [],
    lessonInfo: [],
    creatTimeArrInfo: "", //报名时间
    editAge: "",
    editFoundation: "",
    //企业微信相关
    appId: "",
    timestamp: "",
    nonceStr: "",
    signature: "",
    userId: "wm8fzvEAAAah0C1xCh4NN1FsAiF_zhsw", //最后获取到的userId
    wxCode: "",
    loginUserId: "wm8fzvEAAAbwb572aRLXTqmTqbynGaZQ",
  };
  UNSAFE_componentWillMount() {
    // this.GetUserDetail();
    console.log("无localstorage")
      let url = window.location.href.indexOf("code") !== -1;
      console.log(url);
      if (!url) {
        window.location.href =
          "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wwe62c0bd0aff019bb&redirect_uri=https://crm-h5.qsongxue.com/h5/userDetails&response_type=code&scope=snsapi_base&state=wxwork#wechat_redirect";
      } else {
        console.log(
          "重定向后的URL",
          window.location.href,
          // this.getQueryVariable("code")
        );
        this.setState(
          {
            wxCode: this.getQueryVariable("code"),
          },
          function () {
            this.GetUserId();
          }
        );
      }
    document.title = "用户详情";
  }
  // 组件即将销毁
  componentWillUnmount() {
    // localStorage.removeItem("userId");
  }
  getQueryVariable = (variable) => {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      // eslint-disable-next-line eqeqeq
      if (pair[0] == variable) {
        return pair[1];
      }
    }
    return false;
  };
  GetUserId = () => {
    GetQyUserId({
      code: this.state.wxCode,
    }).then((res) => {
      console.log("获取企业ID", res);
      if (res.code === 1) {
        if(res.data.UserId){
          this.setState({
            loginUserId: res.data.UserId
          },function(){
            this.Init()
          })
        }else  {
          message.error("用户非法")
        }
      }
    });
  };
  async Init() {
    try {
      await this.GetConfig();
      await this.LoadConfig();
      await this.GetCurUserId();
    } catch (error) {
      console.log(error);
    }
  }
  async GetConfig() {
    return new Promise((resolve, reject) => {
      GetJsapiSign({
      }).then((res) => {
        console.log("GetJsapiSign", res);
        this.setState({
          appId: res.data.appId,
          timestamp: res.data.timestamp,
          nonceStr: res.data.nonceStr,
          signature: res.data.signature,
        });
        resolve(1);
      });
    });
  }
  async LoadConfig() {
    return new Promise((resolve, reject) => {
      let that = this
      this.getScript("https://res.wx.qq.com/open/js/jweixin-1.2.0.js", () => {
        this.getScript(
          "https://open.work.weixin.qq.com/wwopen/js/jwxwork-1.0.0.js",
          () => {
            console.log("222", resolve, reject);
            // eslint-disable-next-line no-undef
            wx.agentConfig({
              debug: true,
              corpid: that.state.appId, // 必填，企业微信的corpid，必须与当前登录的企业一致
              agentid: 1000002, // 必填，企业微信的应用id （e.g. 1000247）
              timestamp: that.state.timestamp, // 必填，生成签名的时间戳
              nonceStr: that.state.nonceStr, // 必填，生成签名的随机串
              signature: that.state.signature, // 必填，签名，见附录-JS-SDK使用权限签名算法
              jsApiList: ["getCurExternalContact"], //必填
              success: function (res) {
                // 回调
                resolve(1);
                console.log("agentConfig", res);
              },
              fail: function (res) {
                console.log("agentConfig err", res);
                if (res.errMsg.indexOf("function not exist") > -1) {
                  alert("版本过低请升级");
                }
              },
            });
            // eslint-disable-next-line no-undef
            wx.ready(() => {
              console.log("ready");
            });
            // eslint-disable-next-line no-undef
            wx.error((err) => {
              console.log("error", err);
            });
          }
        );
      });
    });
  }
  async GetCurUserId() {
    let that = this;
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line no-undef
      wx.invoke("getCurExternalContact", {}, function (res) {
        console.log("GetCurUserId", res);
        if (res.err_msg === "getCurExternalContact:ok") {
          resolve(res.userId);
          that.setState({
            userId: res.userId,
          });
          that.GetUserDetail();
          that.SendUserInfo();
        } else {
          //错误处理
          that.$toast(res.err_msg);
          reject(res.err_msg);
        }
      });
    });
  }
  //动态添加js文件
  getScript(url, callback) {
    var head = document.getElementsByTagName("head")[0],
      js = document.createElement("script");

    js.setAttribute("type", "text/javascript");
    js.setAttribute("src", url);
    head.appendChild(js);
    //执行回调
    var callbackFn = function () {
      if (typeof callback === "function") {
        callback();
      }
    };
    if (document.all) {
      //IE
      js.onreadystatechange = function () {
        if (js.readyState === "loaded" || js.readyState === "complete") {
          callbackFn();
        }
      };
    } else {
      js.onload = function () {
        callbackFn();
      };
    }
  }
  GetUserDetail = () => {
    GetWxUserDetail({
      id: this.state.userId,
      uid: this.state.loginUserId
    }).then((res) => {
      if (res.data.code === 1) {
        if (res.data.data.userInfo.age === null) {
          res.data.data.userInfo.age = "";
        }
        if (res.data.data.userInfo.foundation === null) {
          res.data.data.userInfo.foundation = "";
        }
        this.setState({
          userInfo: res.data.data.userInfo,
          orderInfo: res.data.data.orderInfo,
          followInfo: res.data.data.followRecords,
          logistics: res.data.data.deliveryRecords,
          lessonInfo: res.data.data.integrateRecords,
          courseInfo: res.data.data.courseInfo,
          userAvatar: res.data.data.userInfo.avatar,
        });
        if (res.data.data.orderInfo.length > 0) {
          this.setState({
            creatTimeArrInfo:
              res.data.data.orderInfo[res.data.data.orderInfo.length - 1]
                .create_time,
          });
        }
      } else {
        message.error(res.data.msg);
        this.setState({
          isBind: false,
        });
      }
      // this.SendUserInfo();
    });
  };
  GetMenus = () => {};
  EditUserInfoBtn = () => {
    this.setState({
      editAge: this.state.userInfo.age,
      editFoundation: this.state.userInfo.foundation,
      startEdit: !this.state.startEdit,
    });
  };
  OpenRecordModal = () => {
    this.setState({
      isModalVisible: true,
    });
  };
  //选择用户意向
  HandleIntention = (value) => {
    this.setState({
      intention: `${value}`,
    });
  };
  HandleOk = () => {
    UserApi.WxFollowRecord({
      uid: this.state.userInfo.uid,
      purpose: this.state.intention,
      remark: this.state.remark,
      qyUserId: this.state.loginUserId
    })
      .then((res) => {
        if (res.code === 1) {
          message.success("操作成功");
          this.GetUserDetail();
          this.setState({
            intention: null,
            remark: "",
            isModalVisible: false,
          });
        } else {
          message.error(res.msg);
        }
      })
      .catch((error) => {
        console.log(error.request.status);
      });
  };
  HandleCancel = () => {
    this.setState({
      isModalVisible: false,
    });
  };
  Callback = (key) => {
    console.log(key);
  };
  //写入备注
  AddRemark = (e) => {
    this.setState({
      remark: e.target.value,
    });
  };
  //绑定手机号
  BindMonile = (e) => {
    this.setState({
      userMobile: e.target.value,
    });
  };
  //输入用户年龄
  EditAge = (e) => {
    this.setState({
      editAge: e.target.value,
    });
  };
  //输入用户学龄
  EditFoundation = (e) => {
    this.setState({
      editFoundation: e.target.value,
    });
  };
  EditUserInfo = () => {
    DetailApi.WxEditUserAgeInfo({
      uid: this.state.userInfo.uid,
      age: this.state.editAge,
      foundation: this.state.editFoundation,
      qyUserId: this.state.loginUserId
    })
      .then((res) => {
        if (res.code === 1) {
          message.success("操作成功");
          this.GetUserDetail();
          this.setState({
            editAge: "",
            editFoundation: "",
            startEdit: !this.state.startEdit,
          });
        } else {
          message.error(res.msg);
        }
      })
      .catch((error) => {
        console.log(error.request.status);
      });
  };
  SendUserInfo = () => {
    console.log("存入");
    BindUserInfo({
      externalUserid: this.state.userId,
      qyUserId: this.state.loginUserId
    }).then(
      (res) => {
        if (res) {
          if (res.code === 1) {
            console.log(res);
          } else {
            message.warning(res.msg);
          }
        }
      },
      (error) => {
        console.log("failed!");
      }
    );
  };
  BindUserId = () => {
    BindUserMobile({
      externalUserid: this.state.userId,
      mobile: this.state.userMobile,
      qyUserId: this.state.loginUserId
    }).then(
      (res) => {
        if (res) {
          if (res.code === 1) {
            console.log(res);
            this.setState({
              isBind: true,
            });
            this.GetUserDetail();
          } else {
            message.warning(res.msg);
          }
        }
      },
      (error) => {
        console.log("failed!");
      }
    );
  };

  render() {
    let optionDataList = this.state.intentionList.map((items, indexs) => {
      return (
        <Option value={`${items.id}`} key={items.id + "e"}>
          {items.title}
        </Option>
      );
    });
    return (
      <div className="user-page">
        {this.state.isBind ? (
          <div className="userInfo">
            <div className="img-style">
              <div className="avatar">
                <img src={this.state.userInfo.wechatAvatar} alt="" />
              </div>
              {this.state.userInfo.wechatNickName}
            </div>
            <div className="text-info">手机： {this.state.userInfo.mobile}</div>
            <div className="text-info">
              趣配音账号： {this.state.userInfo.user_number}
            </div>
            <div className="text-info">
              渠道： {this.state.userInfo.channel_name}
            </div>
            <div className="text-info">
              报名时间： {this.state.creatTimeArrInfo}
            </div>
            <div className="text-info">
              年龄：
              {this.state.startEdit ? (
                <Input
                  value={this.state.editAge}
                  style={{
                    width: "120px",
                  }}
                  onChange={(e) => this.EditAge(e)}
                ></Input>
              ) : (
                this.state.userInfo.age + "岁"
              )}
            </div>
            <div className="text-info">
              学龄：
              {this.state.startEdit ? (
                <Input
                  value={this.state.editFoundation}
                  style={{
                    width: "120px",
                  }}
                  onChange={(e) => this.EditFoundation(e)}
                ></Input>
              ) : (
                `${this.state.userInfo.foundation}年`
              )}
            </div>
          </div>
        ) : (
          <div className="userInfo">
            <div className="text-info">
              绑定手机号：
              <Input
                value={this.state.userMobile}
                style={{
                  width: "120px",
                }}
                onChange={(e) => this.BindMonile(e)}
              ></Input>
            </div>
            <div className="btn-container">
              <Button onClick={this.BindUserId} type="primary">
                绑定
              </Button>
            </div>
          </div>
        )}

        {this.state.startEdit ? (
          <div
            className="btn-container"
            style={{ display: this.state.isBind ? "block" : "none" }}
          >
            <Button onClick={this.EditUserInfo} type="primary">
              保存
            </Button>
            <Button
              onClick={() => {
                this.setState({
                  startEdit: false,
                });
              }}
            >
              取消
            </Button>
          </div>
        ) : (
          <div
            className="btn-container"
            style={{ display: this.state.isBind ? "block" : "none" }}
          >
            <Button onClick={this.EditUserInfoBtn} type="primary">
              编辑
            </Button>
            <Button type="primary" onClick={this.OpenRecordModal}>
              添加跟进记录
            </Button>
          </div>
        )}
        <Tabs
          defaultActiveKey="1"
          style={{ display: this.state.isBind ? "block" : "none" }}
          onChange={this.Callback}
        >
          <TabPane tab="课程信息" key="1">
            {this.state.courseInfo.map((item, index) => {
              return (
                <div className="tab-container" key={`${index}`}>
                  <div className="text-info">课程等级： {item.levelTitle}</div>
                  <div className="text-info"> 班级名称： {item.className} </div>
                  <div className="text-info"> SKU类型： {item.skuType} </div>
                  <div className="text-info"> 开课时间： {item.startTime} </div>
                  <div className="text-info"> 老师： {item.teacher} </div>
                  <div className="text-info">
                    课程进度： {item.courseProgress}/{item.dailyTotal}
                  </div>
                </div>
              );
            })}
          </TabPane>
          <TabPane tab="跟进信息" key="2">
            {this.state.followInfo.map((item, index) => {
              return (
                <div className="tab-container" key={`${index}`}>
                  <div className="text-info">
                    用户意向： {item.purpose_name}
                  </div>
                  <div className="text-info">跟进时间： {item.create_time}</div>
                  <div className="text-info">跟进老师： {item.admin_name}</div>
                  <div className="text-info"> 备注： {item.remark} </div>
                </div>
              );
            })}
          </TabPane>
          <TabPane tab="订单信息" key="3">
            {this.state.orderInfo.map((item, index) => {
              return (
                <div className="tab-container" key={`${index}`}>
                  <div className="text-info"> 订单号： {item.orderNumber} </div>
                  <div className="text-info">下单时间： {item.create_time}</div>
                  <div className="text-info"> 支付金额： {item.amount} </div>
                  <div className="text-info"> 商品类型： {item.goodsName} </div>
                  <div className="text-info">SKU类型： {item.skuTypeName}</div>
                  <div className="text-info"> 等级： L{item.level} </div>
                </div>
              );
            })}
          </TabPane>
          <TabPane tab="物流信息" key="4">
            {this.state.logistics.map((item, index) => {
              return (
                <div className="tab-container" key={`${index}`}>
                  <div className="text-info"> 快递内容： {item.goodsName} </div>
                  <div className="text-info">
                    发货时间： {item.deliver_time}
                  </div>
                  <div className="text-info">
                    快递单号： {item.express_number}
                  </div>
                  <div className="text-info">
                    收货地址： {item.consignee_address}
                  </div>
                </div>
              );
            })}
            {this.state.lessonInfo.map((item, index) => {
              return (
                <div className="tab-container" key={`item-${index}`}>
                  <div className="text-info"> 快递内容： {item.goodsName} </div>
                  <div className="text-info">
                    发货时间： {item.deliver_time}
                  </div>
                  <div className="text-info">
                    快递单号： {item.express_number}
                  </div>
                  <div className="text-info">收货地址： {item.address}</div>
                </div>
              );
            })}
          </TabPane>
        </Tabs>
        <Modal
          title="跟进记录"
          visible={this.state.isModalVisible}
          onOk={this.HandleOk}
          onCancel={this.HandleCancel}
          className="MyModal"
        >
          <div className="text-container">
            <div className="introudce">
              <span> * </span> 用户昵称:
            </div>
            {this.state.userInfo.nick_name}
          </div>
          <div className="text-container">
            <div className="introudce">
              <span> * </span> 用户意向:
            </div>
            <Select
              style={{
                width: 120,
              }}
              value={this.state.intention}
              onChange={this.HandleIntention}
            >
              {optionDataList}
            </Select>
          </div>
          <div className="text-container">
            <div className="introudce">
              <span> &nbsp; </span> 备注:
            </div>
            <TextArea
              value={this.state.remark}
              onChange={(e) => this.AddRemark(e)}
              rows={4}
            />
          </div>
        </Modal>
      </div>
    );
  }
}
export default H5userDetails;
