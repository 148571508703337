import request from '../../request';
/**
 * 菜单获取权限
 */
const UserList = ({
    start,
    rows,
    nickname,
    mobile,
    userNumber,
    ageMin,
    ageMax,
    finishCourseCountMin,
    finishCourseCountMax,
    foundationMin,
    foundationMax,
    courseBeginTimeMin,
    courseBeginTimeMax,
    purpose,
    levelId,
    teacherId,
    time,
    isVip
}) => {
    return request
        .get(`/magicClass/tasteUserList`, {
            params: {
                start,
                rows,
                nickname,
                mobile,
                userNumber: userNumber,
                ageMin,
                ageMax,
                finishCourseCountMin,
                finishCourseCountMax,
                foundationMin,
                foundationMax,
                courseBeginTimeMin,
                courseBeginTimeMax,
                purpose,
                level: levelId,
                teacher: teacherId,
                creat_time: time,
                isVip
            }
        })
        .then(result => {
            return result.data
        })
}

/**
 * VIP用户列表
 */
const VipUserList = ({
    start,
    rows,
    mobile,
    userNumber,
    takeCourseCountMin,
    takeCourseCountMax,
    dailyLeftMin,
    dailyLeftMax,
    aTeacherId,
    bTeacherId,
}) => {
    return request
        .get(`/magicClass/formalClassUser`, {
            params: {
                start,
                rows,
                mobile,
                userNumber: userNumber,
                takeCourseCountMin,
                takeCourseCountMax,
                dailyLeftMin,
                dailyLeftMax,
                aTeacherId,
                bTeacherId,
            }
        })
        .then(result => {
            return result.data
        })
}

const TeacherSearch = ({
    name,
}) => {
    return request
        .get(`teacher/searchTeacherByName`, {
            params: {
                name,
            }
        })
        .then(result => {
            return result.data
        })
}

const CetTeacherList = () => {
    return request.get('teacher/getAllTeacher').then(res => res)
}

/**
 * 添加跟进记录---post请求
 */
function FollowRecord({
    uid,
    purpose,
    remark
}) {
    return request
        .post(`magicClass/followRecordAdd`, {
            uid,
            purpose,
            remark
        })
        .then(response => {
            return response.data
        })
}

/**
 * 企业微信添加跟进记录---post请求
 */
function WxFollowRecord({
    uid,
    purpose,
    remark,
    qyUserId
}) {
    return request
        .post(`qyMagicClass/followRecordAdd`, {
            uid,
            purpose,
            remark,
            qyUserId
        },{
            needSign: true
        })
        .then(response => {
            return response.data
        })
}

// eslint-disable-next-line
export default {
    UserList,
    VipUserList,
    TeacherSearch,
    CetTeacherList,
    FollowRecord,
    WxFollowRecord
}