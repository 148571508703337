import React, { Component } from "react";
import { Button, Tree, Modal, Input, Select, message } from "antd";
import RoleApi from "../../../lib/api/authority/index";
import MenuAuthApi from "../../../lib/api/menus/index";
import {
  FormOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
require("./index.less");
const { Option } = Select;

class Jurisdiction extends Component {
  UNSAFE_componentWillMount() {
    this.GetRoleList();
    this.GetMenusRules();
  }

  componentDidMount() {}

  state = {
    id: null,
    selectGroupIndex: 0,
    haveRuleList: [], //当前角色拥有的权限
    roleGroup: [], //角色组
    allRuleInfo: [],
    dataAuthList: [],
    ruleList: [],
    checkMenuKeyList: [], //编辑权限所选中菜单的id集合
    allRulesList: [],
    editGroupId: "",
    editRoleName: "",
    editDataRang: "0",
    editPid: "0",
    addPid: "0",
    addDataRang: "0",
    addRoleName: "",
    isEditRole: false,
    isAddRole: false,
    treeData: [],
    selectRecord: [],
    isSuperAdmin: 0,
    allRuleList: [
      {
        name: "auth/groupLists",
        title: "权限列表",
        show: false,
      },
      {
        name: "auth/updateAuthGroup",
        title: "提交角色权限更新",
        show: false,
      },
      {
        name: "auth/groupAdd",
        title: "添加角色类型",
        show: false,
      },
      {
        name: "auth/updateAuthGroupRule",
        title: "编辑角色",
        show: false,
      },
      {
        name: "auth/groupStatusMod",
        title: "删除角色",
        show: false,
      },
    ],
  };

  GetRoleList = () => {
    RoleApi.GetGroupLists().then(
      (res) => {
        if (res) {
          if (res.code === 1) {
            let data = res.data;
            this.setState({
              roleGroup: data.group,
              dataAuthList: data.dataAuth,
              ruleList: data.rule.slice(1, data.rule.length),
              allRulesList: [],
            });
            setTimeout(() => {
              this.GetTreeData(data.rule.slice(0, data.rule.length));
            }, 200);
          } else {
            message.warning(res.msg);
          }
        }
      },
      (error) => {
        console.log("failed!");
      }
    );
  };
  GetRuleList = () => {
    let authList = this.state.allRuleInfo;
    let list = authList.map((item, index) => {
      return {
        name: item.name,
        title: item.title,
        id: item.id,
      };
    });
    console.log("ruleList", list);
    this.GetBtnStatus(list);
  };
  GetMenusRules = () => {
    MenuAuthApi.GetMenuList().then(
      (res) => {
        if (res) {
          if (res.code === 1) {
            this.setState({
              allRuleInfo: res.data.auth,
              isSuperAdmin: res.data.isSuperAdmin,
            });
            this.GetRuleList();
            localStorage.setItem("authList", JSON.stringify(res.data.auth));
            this.GetRoleList();
          } else {
            message.warning(res.msg);
          }
        }
      },
      (error) => {
        console.log("failed!");
      }
    );
  };
  //判断页面按钮显示隐藏
  GetBtnStatus = (list) => {
    let allRule = this.state.allRuleList;
    list.forEach((item) => {
      allRule.map((items) => {
        if (item.name === items.name) {
          items.show = true;
        }
        return {
          ...items,
        };
      });
    });
  };

  GetTreeData = (data) => {
    let allRulesList = this.state.allRulesList;
    let List = data.map((item) => {
      allRulesList.push(item.id);
      let children = [];
      if (item.child) {
        children = item.child.map((items) => {
          allRulesList.push(items.id);
          let menuAuth = [];
          if (items.child) {
            menuAuth = items.child.map((itemss) => {
              allRulesList.push(itemss.id);
              return {
                title: itemss.title,
                name: itemss.name,
                key: itemss.id,
                pid: itemss.pid,
              };
            });
          }
          return {
            title: items.title,
            name: items.name,
            key: items.id,
            pid: items.pid,
            children: menuAuth,
          };
        });
      }
      return {
        title: item.title,
        name: item.name,
        key: item.id,
        pid: item.pid,
        children: children,
      };
    });
    this.setState({
      treeData: List,
      editGroupId: List[0].key,
      allRulesList: allRulesList,
    });
    if (this.state.roleGroup[0].rules === "*") {
      this.setState({
        checkMenuKeyList: allRulesList,
      });
    } else {
      this.setState({
        checkMenuKeyList: this.state.roleGroup[0].rules.split(",").map(Number),
      });
    }
    if (this.state.selectGroupIndex !== 0) {
      this.setState({
        checkMenuKeyList: this.state.roleGroup[
          this.state.selectGroupIndex
        ].rules
          .split(",")
          .map(Number),
        editGroupId: this.state.selectRecord.id,
      });
    }
  };

  //选中树节点
  OnCheck = (checkedKeys) => {
    this.setState({
      checkMenuKeyList: checkedKeys.checked,
    });
  };

  //select组件绑定事件
  HandleAddSelect = (value) => {
    this.setState({
      addPid: `${value}`,
    });
  };
  //select组件绑定事件
  HandleEditSelect = (value) => {
    this.setState({
      editPid: `${value}`,
    });
  };
  //select组件绑定事件
  HandleEditDataSelect = (value) => {
    this.setState({
      editDataRang: `${value}`,
    });
  };
  //select组件绑定事件
  HandleAddDataSelect = (value) => {
    this.setState({
      addDataRang: `${value}`,
    });
  };

  //输入框输入绑定
  ChangeName = (e) => {
    this.setState({
      editRoleName: e.target.value,
    });
  };
  //输入框输入绑定
  AddName = (e) => {
    this.setState({
      addRoleName: e.target.value,
    });
  };

  //添加角色对话框开关
  AddRole = () => {
    this.setState({
      isAddRole: true,
    });
  };

  //选中角色类型触发
  CheckRoleBtn = (record, index) => {
    if (record.rules === "*") {
      this.setState({
        checkMenuKeyList: this.state.allRulesList,
      });
    } else {
      this.setState({
        checkMenuKeyList: record.rules.split(",").map(Number),
      });
    }
    this.setState({
      editGroupId: record.id,
      selectGroupIndex: index,
      selectRecord: record,
    });
  };
  //编辑角色按钮
  EditRoleBtn = (record) => {
    this.setState({
      isEditRole: true,
      editGroupId: record.id,
      editRoleName: record.name,
      editDataRang: `${record.data_auth}`,
      editPid: `${record.pid}`,
    });
  };
  //删除角色按钮
  DeleteRole = (record) => {
    this.setState({
      editGroupId: record.id,
    });
    Modal.confirm({
      title: "删除后不可恢复",
      icon: <ExclamationCircleOutlined />,
      content: "删除时，要保证当前权限组下没有员工",
      okText: "确认",
      cancelText: "取消",
      onOk: this.DeleteAddGroup,
    });
  };
  //关闭对话框事件
  HandleCancel = () => {
    this.setState({
      isEditRole: false,
      isAddRole: false,
    });
  };
  //提交对角色的编辑
  HandleEditGroup = () => {
    RoleApi.EditRoleGroup({
      id: this.state.editGroupId,
      pid: this.state.editPid,
      name: this.state.editRoleName,
      dataAuth: this.state.editDataRang,
    }).then((res) => {
      if (res.code === 1) {
        //登录成功之后的处理
        message.success("操作成功");
        this.GetRoleList();
        this.setState({
          isEditRole: false,
        });
      } else {
        message.error(res.msg);
      }
    });
  };
  //提交--添加角色
  HandleAddGroup = () => {
    if (this.state.addRoleName === "") {
      message.warning("请输入角色名");
      return false;
    }
    RoleApi.AddRoleGroup({
      pid: 0,
      name: this.state.addRoleName,
      dataAuth: this.state.addDataRang,
    }).then((res) => {
      if (res.code === 1) {
        message.success("添加成功");
        this.GetRoleList();
        this.setState({
          addRoleName: "",
          isAddRole: false,
        });
      } else {
        message.error(res.msg);
      }
    });
  };
  //删除角色组--提交
  DeleteAddGroup = () => {
    RoleApi.ChangeRoleGroup({
      id: this.state.editGroupId,
      type: -1,
    }).then((res) => {
      if (res.code === 1) {
        message.success("删除成功");
        this.GetRoleList();
      } else {
        message.error(res.msg);
      }
    });
  };
  //提交对角色权限的编辑更改
  ChangeRoleRule = () => {
    RoleApi.ChangeRoleRule({
      id: this.state.editGroupId,
      rules: this.state.checkMenuKeyList.join(","),
    }).then((res) => {
      if (res.code === 1) {
        message.success("操作成功");
        this.GetMenusRules();
      } else {
        message.error(res.msg);
      }
    });
  };

  render() {
    let roleList = this.state.roleGroup.map((item, index) => {
      return (
        <div
          className={`list-box${
            this.state.selectGroupIndex === index ? " active" : ""
          }`}
          onClick={this.CheckRoleBtn.bind(this, item, index)}
          key={index}
        >
          <div className="role-name"> {item.name} </div>
          {(this.state.allRuleList[3].show || this.state.isSuperAdmin===1) ? (
            <Button
              onClick={this.EditRoleBtn.bind(this, item)}
              icon={<FormOutlined />}
            ></Button>
          ) : (
            ""
          )}
          {(this.state.allRuleList[4].show || this.state.isSuperAdmin ===1) ? (
            <Button
              onClick={this.DeleteRole.bind(this, item)}
              icon={<DeleteOutlined />}
            ></Button>
          ) : (
            ""
          )}
        </div>
      );
    });

    let optionDataList = this.state.dataAuthList.map((items, indexs) => {
      return (
        <Option value={`${items.index}`} key={items.title}>
          
          {items.title}
        </Option>
      );
    });
    return (
      <div className="role-page">
        <div className="btn-box">
          
          {(this.state.allRuleList[1].show || this.state.isSuperAdmin ===1) ? (
            <Button type="primary" onClick={this.ChangeRoleRule}>
              提交
            </Button>
          ) : (
            ""
          )}
          {/* <Button>取消</Button> */}
        </div>
        <div className="flex-container">
          <div className="left-container">
            <p> 系统角色组 </p> {roleList}
            <div className="add-box">
              
              {(this.state.allRuleList[2].show || this.state.isSuperAdmin===1) ? (
                <Button
                  onClick={this.AddRole}
                  icon={<PlusCircleOutlined />}
                ></Button>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="right-container">
            
            {this.state.treeData.length > 0 ? (
              <Tree
                checkable
                checkStrictly
                defaultExpandAll={true}
                checkedKeys={this.state.checkMenuKeyList}
                onCheck={this.OnCheck}
                treeData={this.state.treeData}
              />
            ) : (
              ""
            )}
          </div>
        </div>
        <Modal
          title="编辑角色"
          visible={this.state.isEditRole}
          onOk={this.HandleEditGroup}
          onCancel={this.HandleCancel}
        >
          <div className="common-input-style">
            <div className="introudce">
              <span> * </span> 角色名称:
            </div>
            <Input
              value={this.state.editRoleName}
              onChange={(e) => this.ChangeName(e)}
            ></Input>
          </div>
          <div className="common-input-style">
            <div className="introudce">
              <span> * </span> 数据范围:
            </div>
            <Select
              style={{
                width: 120,
              }}
              value={this.state.editDataRang}
              onChange={this.HandleEditDataSelect}
            >
              {optionDataList}
            </Select>
          </div>
        </Modal>
        <Modal
          title="添加角色"
          visible={this.state.isAddRole}
          onOk={this.HandleAddGroup}
          onCancel={this.HandleCancel}
        >
          <div className="common-input-style">
            <div className="introudce">
              <span> * </span> 角色名称:
            </div>
            <Input
              value={this.state.addRoleName}
              onChange={(e) => this.AddName(e)}
            ></Input>
          </div>
          <div className="common-input-style">
            <div className="introudce">
              <span> * </span> 数据范围:
            </div>
            <Select
              defaultValue="0"
              value={this.state.addDataRang}
              style={{
                width: 120,
              }}
              onChange={this.HandleAddDataSelect}
            >
              {optionDataList}
            </Select>
          </div>
        </Modal>
      </div>
    );
  }
}
export default Jurisdiction;
