import React, { Component } from 'react'
import { Table } from 'antd'

class Staff extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      columns: [
        {
            title: '业绩排名',
            dataIndex: 'index',
        },
        {
            title: '销售',
            dataIndex: 'teacherName',
        },
        {
            title: '团队名',
            dataIndex: 'name',
        },
        {
            title: '成交单数',
            dataIndex: 'orderTotal', 
        },
        {
            title: '业绩金额',
            dataIndex: 'saleTotal', 
        },
        {
            title: '报名人数',
            dataIndex: 'applyNum', 
        },
        {
            title: '加好友数',
            dataIndex: 'friend_num', 
        },
        {
            title: '参课人数',
            dataIndex: 'attend_num', 
        },
        {
            title: '完课人数',
            dataIndex: 'finish_num', 
        }
      ]
    };
  }
  render() {
    console.log('props', this.props)
    let tableData = this.props.dataSource.map((item, index) => {
      return {
        ...item,
        key: index,
        index: index + 1
      }
    })
    return (<Table columns={this.state.columns} dataSource={tableData}></Table>)
  }
}

export default Staff;
