import request from '../../request'

/**
 * 
 * 兑换码兑换信息列表
 * @param {*} 下拉列表类型
 * @returns 
 */
const GetUnionVipList = ({
  changeType = 0,
  start = 0,
  rows = 10,
  search = '',
  changStartTime = '',
  changEndTime = ''
}) => {
  return request
  .get(`/user/changeHistory`, {
      params: {
        changeType,
        start,
        rows,
        search,
        changStartTime,
        changEndTime
      }
  })
  .then(result => {
      return result.data
  })
}

/**
 * 
 * 手机号导入
 * @param { Array } 手机号列表，用换行符分隔
 * @returns 
 */
const ImportVipMibile = ({
  mobiles
}) => {
return request
        .post(`/user/importMobiles `, {
            mobiles
        },)
        .then(response => {
            return response.data
        })
}

/**
 * 权益兑换记录(第二期)
 * @param {*} param0 
 * @returns 
 */
const GetChangeHistory = ({
  changeType = '',
  start = 0,
  rows = 10,
  search = '',
  changStartTime,
  changEndTime
}) => {
  return request
  .get(`redeem/List`, {
      params: {
        changeType,
        start,
        rows,
        search,
        changStartTime,
        changEndTime
      }
  })
  .then(result => {
      return result.data
  })
}

/**
 * 
 * 手机号导入(第二期)
 * @param { Array } 手机号列表，用换行符分隔
 * @returns 
 */
 const ImportVipMibileV2 = ({
  mobiles
}) => {
return request
        .post(`/redeem/importOrder`, {
            mobiles
        },)
        .then(response => {
            return response.data
        })
}
export {
  GetUnionVipList,
  ImportVipMibile,

  GetChangeHistory,
  ImportVipMibileV2
}