import React, { Component } from "react";

import {
  Modal,
  Form,
  Space,
  Button,
  Table,
  Input,
  Select,
  Tree,
  message,
} from "antd";

import { EditOutlined } from "@ant-design/icons";

import {
  GroupAdd,
  GroupList,
  GroupMod,
  GroupUserList,
  AddStaff,
  UserAddDetail,
  StaffModSave,
} from "@/lib/api/systemSet/staffManage";

import api from "../../../lib/api/menus/index.js";
const TreeNode = Tree.TreeNode;

const Search = Input.Search;

require("./index.less");

const { Option } = Select;

class StaffManage extends Component {
  addTree = React.createRef();
  UNSAFE_componentWillMount() {
    this.Init()
  }
  Init() {
    this.GetGroupList(1);
    this.GetGroupListCheck(1);
    this.GetUserAddDetail();
    this.GetRootList();
  }
  GetGroupList(data_type) {
    GroupList(data_type).then((res) => {
      data_type === 1
        ? this.setState({
            gData: res.data.data,
          })
        : this.setState({
            selectData: res.data.data,
          });

      // this.GenerateList(this.state.gData);
      let data = this.Loop(this.state.gData);
      this.setState({
        treeData: data,
        dataList: this.ReduceBydeep(JSON.parse(JSON.stringify(res.data.data))),
      });
    });
  }
  GetGroupListCheck(data_type) {
    GroupList(data_type).then((res) => {
      if (data_type === 1) {
        if (res.data.data.length === 0) {
          return false;
        }
        this.setState({
          gData: res.data.data,
          dataList: this.ReduceBydeep(
            JSON.parse(JSON.stringify(res.data.data))
          ),
        });
        this.setState({
          selectId: [res.data.data[0].id.toString()],
        });
        this.GetStaffList(res.data.data[0].id);
        // this.GenerateList(this.state.gData);
      } else {
        this.setState({
          selectData: res.data.data,
        });
      }
    });
  }

  state = {
    datalist: [],
    isSaleGroup: '0',
    editIsSaleGroup: '0',
    value: undefined,
    addStaffModal: false,
    EditStaffModal: false,
    parentSchemaModal: false,
    editParentSchemaModal: false,
    expandedKeys: ["0"],
    searchValue: "",
    autoExpandParent: true,
    gData: [],
    selectData: [],
    treeData: [],
    editId: null,
    dataSource: [],
    selectId: [],
    userDetailList: {
      adminUsers: [],
      authGroup: [],
      userGroup: [],
    },
    rootRequestList: {
      editStaff: {
        name: "user/userModSave",
        isCanUse: false,
      },
      addStaff: {
        name: "user/userAddSave",
        isCanUse: false,
      },
      addTree: {
        name: "user/groupAdd",
        isCanUse: false,
      },
      editTree: {
        name: "user/groupMod",
        isCanUse: false,
      },
      authList: {
        name: "auth/groupLists",
        isCanUse: false,
      },
    },
  };

  ReduceBydeep = (data) => {
    if (data && data.length > 0) {
      return data.map((ele) => {
        if (ele.child) {
          ele.child = this.ReduceBydeep(ele.child);
        }
        return {
          title: ele.name,
          name: ele.name,
          key: ele.id,
          pid: ele.pid,
          children: ele.child ? ele.child : [],
        };
      });
    }
  };
  //打开编辑弹窗
  OpenEditPopup = (e, item) => {
    this.setState({
      editIsSaleGroup: `${item.is_sale_group}`
    })
    GroupList(0).then((res) => {
      if (parseInt(res.data.code) === 1) {
        this.setState({
          selectData: res.data.data,
          editParentSchemaModal: true,
          editId: item.id,
        });
        this.refs.editTree.setFieldsValue({
          pid: item.pid,
          name: item.name,
        });
        this.GetGroupList(0);
      }
    });
  };

  GetStaffList = (data) => {
    GroupUserList(data).then((res) => {
      if (res.data.code === 1) {
        this.setState({
          dataSource: res.data.data.list.map((ele, index) => {
            return {
              ...{
                key: index,
              },
              ...ele,
            };
          }),
        });
      } else {
      }
    });
  };

  //选中
  OnSelect = (selectedKeys, info) => {
    if (selectedKeys.length > 0) {
      this.setState({
        selectId: selectedKeys,
      });
      this.GetStaffList(selectedKeys[0]);
    } else {
    }
  };

  OpenKeysSelect = (data, value) => {
    let keyList = [];
    data.forEach((ele) => {
      if (ele.children.length > 0) {
        keyList = [...keyList, ...this.OpenKeysSelect(ele.children, value)];
      }
      if (ele.title.toLowerCase().indexOf(value.toLowerCase()) !== -1) {
        keyList.push(ele.pid);
      }
    });
    keyList = keyList.map((res) => res.toString());
    return keyList;
  };
  //树结构的搜索事件触发
  OnChange = (e) => {
    const value = e.target.value;
    this.setState({
      searchValue: value,
      autoExpandParent: true,
    });
    if (value.length > 0) {
      this.setState({
        expandedKeys: Array.from(
          new Set(this.OpenKeysSelect(this.state.dataList, value))
        ),
      });
    } else {
      this.setState({
        expandedKeys: ["0"],
      });
    }
  };

  Loop = (data) =>
    data.map((item) => {
      let { searchValue } = this.state;
      const index = item.name.indexOf(searchValue);
      const beforeStr = item.name.substr(0, index);
      const afterStr = item.name.substr(index + searchValue.length);
      const title =
        index > -1 ? (
          <div className="tree--item">
            <div className="tree--label">
              {beforeStr}
              <span style={{ color: "#f50" }}>{searchValue}</span>
              {afterStr}
            </div>
            <Button
              style={
                this.state.rootRequestList.editTree.isCanUse
                  ? { display: "block" }
                  : { display: "none" }
              }
              size="small"
              shape="circle"
              icon={<EditOutlined />}
              onClick={(e) => this.OpenEditPopup(e, item)}
            ></Button>
          </div>
        ) : (
          <span className="tree--label">
            <span style={{ marginRight: "10px" }}>{item.name}</span>
            <EditOutlined />
          </span>
        );

      if (item.child) {
        return (
          <TreeNode key={item.id} title={title} dataRef={item}>
            {this.Loop(item.child)}
          </TreeNode>
        );
      }
      return <TreeNode dataRef={item} key={item.id} title={title} />;
    });

  //提交创建的组织架构信息
  SureAddParent = () => {
    this.refs.addTree
      .validateFields()
      .then((res) => {
        res.is_sale_group = this.state.isSaleGroup
        GroupAdd(res).then((res) => {
          if (res.data.code === 1) {
            this.GetGroupList(1);
            this.setState({
              parentSchemaModal: false,
            });
            message.success("创建成功");
          } else {
            message.error("创建失败");
          }
        });
      })
      .catch((res) => {});
  };
  //提交编辑后的架构信息
  SureEditParent = () => {
    this.refs.editTree
      .validateFields()
      .then((res) => {
        res.id = this.state.editId;
        res.is_sale_group = this.state.editIsSaleGroup

        GroupMod(res).then((res) => {
          if (res.data.code === 1) {
            this.GetGroupList(1);
            this.setState({
              editParentSchemaModal: false,
            });
            message.success("编辑成功");
          } else {
            message.error(res.data.msg);
          }
        });
      })
      .catch((res) => {});
  };
  //关闭对话框
  HandleCancel = () => {
    this.setState({
      addStaffModal: false,
      EditStaffModal: false,
      parentSchemaModal: false,
      editParentSchemaModal: false,
    });
    if (this.refs.addStaff) {
      this.refs.addStaff.resetFields();
    }
  };
  //打开编辑对话框
  ShowEditModal = (data) => {
    this.setState({
      EditStaffModal: true,
    });
    let timmer = setTimeout(() => {
      this.refs.editStaff.setFieldsValue({
        uid: data.uid,
        userGroupId: data.userGroupId,
        authGroupId: data.groupId,
      });
      clearTimeout(timmer);
    });
  };
  //添加员工
  AddStaff = () => {
    this.refs.addStaff.validateFields().then((res) => {
      AddStaff(res).then((res) => {
        if (res.data.code === 1) {
          message.success("添加成功");
          this.GetStaffList(this.state.selectId);
          this.HandleCancel();
          this.refs.addStaff.resetFields();
        } else {
          message.error("添加失败");
        }
      });
    });
    //  AddStaff()res
  };
  //编辑员工
  EditStaff = () => {
    this.refs.editStaff.validateFields().then((res) => {
      StaffModSave(res).then((res) => {
        if (res.data.code === 1) {
          message.success("编辑成功");
          this.GetStaffList(this.state.selectId);
          this.HandleCancel();
          this.refs.editStaff.resetFields();
        } else {
          message.error("编辑失败");
        }
      });
    });
  };
  //展开树结构
  OnExpand = (expandedKeys) => {
    this.setState({
      expandedKeys,
      autoExpandParent: false,
    });
  };
  render() {
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    };
    const rule = [{ required: true }];
    const columns = [
      {
        title: "姓名",

        dataIndex: "name",

        key: "name",

        fixed: "left",
      },

      {
        title: "角色",

        key: "authGroupName",

        dataIndex: "authGroupName",
      },

      {
        title: "操作",

        key: "action",

        render: (text, record) => (
          <Space size="middle">
            <Button
              style={
                this.state.rootRequestList.editStaff.isCanUse
                  ? { display: "block" }
                  : { display: "none" }
              }
              size="small"
              type="primary"
              onClick={() => this.ShowEditModal(record)}
            >
              编辑
            </Button>
          </Space>
        ),
      },
    ];
    const showModal = () => {
      this.setState({
        addStaffModal: true,
      });
      this.GetGroupList(0);
    };
    const { expandedKeys, autoExpandParent, gData } = this.state;

    return (
      <div className="staff-page">
        <div className="tree-container">
          <div style={{ marginBottom: "200px" }}>
            <Search
              style={{ marginBottom: 8, width: 300 }}
              placeholder="Search"
              onChange={this.OnChange}
            />

            <Tree
              onSelect={this.OnSelect}
              onExpand={this.OnExpand}
              expandedKeys={expandedKeys}
              autoExpandParent={autoExpandParent}
              selectedKeys={this.state.selectId}
            >
              <TreeNode key="0" title={<span>根</span>} selectable={false}>
                {this.Loop(gData)}
              </TreeNode>
            </Tree>
          </div>
        </div>

        <div className="table-container">
          <div className="bottom-container">
            <Button
              style={
                this.state.rootRequestList.addStaff.isCanUse
                  ? { display: "block" }
                  : { display: "none" }
              }
              onClick={showModal}
              type="primary"
            >
              添加员工
            </Button>
            <Button
              onClick={() => {
                // this.refs.addTree.setFieldsValue({
                //   is_sale_group:'0',
                // });
                this.setState({
                  parentSchemaModal: true,
                });
                this.GetGroupList(0);
              }}
              type="primary"
              style={
                this.state.rootRequestList.addTree.isCanUse
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              创建架构
            </Button>
            <Button
              type="primary"
              onClick={() => {
                this.props.history.push(`/systemSet/StaffManage/Jurisdiction`);
              }}
              style={
                this.state.rootRequestList.authList.isCanUse
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              角色权限配置
            </Button>
          </div>

          <Table
            columns={columns}
            pagination={false}
            dataSource={this.state.dataSource}
          />
        </div>

        <Modal
          title="添加员工"
          visible={this.state.addStaffModal}
          onOk={this.AddStaff}
          onCancel={this.HandleCancel}
        >
          <Form {...layout} ref="addStaff">
            <Form.Item label="姓名" name="uid" rules={rule}>
              <Select
                showSearch
                style={{ width: 200 }}
                placeholder="请选择员工姓名"
                optionFilterProp="children"
                onChange={(res) => {
                  console.log(res);
                }}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {this.state.userDetailList.adminUsers.map((ele, index) => {
                  return (
                    <Option value={ele.uid} key={index}>
                      {ele.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item label="组织架构" name="userGroupId" rules={rule}>
              <Select
                showSearch
                style={{ width: 200 }}
                placeholder="请选择组织架构"
                optionFilterProp="children"
                onChange={(res) => {
                  console.log(res);
                }}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {this.state.userDetailList.userGroup.map((ele, index) => {
                  return (
                    <Option value={ele.id} key={index}>
                      {ele.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item label="角色" name="authGroupId" rules={rule}>
              <Select
                showSearch
                style={{ width: 200 }}
                placeholder="请选择角色"
                optionFilterProp="children"
                onChange={(res) => {
                  console.log(res);
                }}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {this.state.userDetailList.authGroup.map((ele, index) => {
                  return (
                    <Option value={ele.id} key={index}>
                      {ele.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Form>
        </Modal>

        <Modal
          title="编辑员工"
          visible={this.state.EditStaffModal}
          onOk={this.EditStaff}
          onCancel={this.HandleCancel}
        >
          <Form {...layout} ref="editStaff">
            <Form.Item label="姓名" name="uid" rules={rule}>
              <Select
                showSearch
                disabled
                style={{ width: 200 }}
                placeholder="请选择员工姓名"
                optionFilterProp="children"
                onChange={(res) => {
                  console.log(res);
                }}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {this.state.userDetailList.adminUsers.map((ele, index) => {
                  return (
                    <Option value={ele.uid} key={index}>
                      {ele.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item label="组织架构" name="userGroupId" rules={rule}>
              <Select
                showSearch
                style={{ width: 200 }}
                placeholder="请选择组织架构"
                optionFilterProp="children"
                onChange={(res) => {
                  console.log(res);
                }}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {this.state.userDetailList.userGroup.map((ele, index) => {
                  return (
                    <Option value={ele.id} key={index}>
                      {ele.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item label="角色" name="authGroupId" rules={rule}>
              <Select
                showSearch
                style={{ width: 200 }}
                placeholder="请选择角色"
                optionFilterProp="children"
                onChange={(res) => {
                  console.log(res);
                }}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {this.state.userDetailList.authGroup.map((ele, index) => {
                  return (
                    <Option value={ele.id} key={index}>
                      {ele.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Form>
        </Modal>

        <Modal
          title="创建架构"
          visible={this.state.parentSchemaModal}
          onOk={this.SureAddParent}
          onCancel={this.HandleCancel}
        >
          <Form {...layout} ref="addTree">
            <Form.Item
              label="父级架构"
              name="pid"
              rules={[{ required: true, message: "请选择父级架构名称" }]}
            >
              <Select
                showSearch
                style={{ width: 200 }}
                placeholder="请选择父级架构"
                optionFilterProp="children"
                onChange={(res) => {
                  console.log(res);
                }}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option value="0" key="-1">
                  根
                </Option>
                {this.state.selectData.map((ele, index) => {
                  return (
                    <Option value={ele.id} key={index}>
                      {ele.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            <div className="common-input-style">
              <div className="introudce">
                <span>  </span> 是否销售小组:
              </div>
              <Select
                style={{ width: 160 }}
                placeholder="是否销售小组"
                defaultValue="0"
                value={this.state.isSaleGroup}
                optionFilterProp="children"
                onChange={(res) => {
                  this.setState({
                    isSaleGroup: res,
                  });
                }}
              >
                <Option value="0" key="2">
                  否
                </Option>
                <Option value="1" key="1">
                  是
                </Option>
              </Select>
            </div>

            <Form.Item
              name="name"
              label="架构名称"
              rules={[{ required: true, message: "请输入架构名称" }]}
            >
              <Input placeholder="请输入架构名称" style={{ width: 200 }} />
            </Form.Item>
          </Form>
        </Modal>

        <Modal
          title="编辑架构"
          visible={this.state.editParentSchemaModal}
          onOk={this.SureEditParent}
          onCancel={this.HandleCancel}
        >
          <Form {...layout} ref="editTree">
            <Form.Item
              label="父级架构"
              name="pid"
              rules={[{ required: true, message: "请选择父级架构" }]}
            >
              <Select style={{ width: 200 }}>
                <Option value={0} key="-1">
                  根
                </Option>
                {this.state.selectData.map((ele, index) => {
                  return (
                    <Option value={ele.id} key={index}>
                      {ele.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item
              name="name"
              label="架构名称"
              rules={[{ required: true, message: "请输入架构名称" }]}
            >
              <Input placeholder="请输入架构名" style={{ width: 200 }} />
            </Form.Item>
            <div className="common-input-style">
              <div className="introudce">
                <span>  </span> 是否销售小组:
              </div>
              <Select
                style={{ width: 160 }}
                placeholder="是否销售小组"
                value={this.state.editIsSaleGroup}
                optionFilterProp="children"
                onChange={(res) => {
                  console.log(res);
                  this.setState({
                    editIsSaleGroup: res,
                  });
                }}
              >
                <Option value="0" key="2">
                  否
                </Option>
                <Option value="1" key="1">
                  是
                </Option>
              </Select>
            </div>
          </Form>
        </Modal>
      </div>
    );
  }
  GetRootList = () => {
    api
      .GetMenuList()
      .then((res) => {
        let rootList = JSON.parse(JSON.stringify(res.data.auth)).map((ele) => {
          return ele.name;
        });
        let rootRequestList = this.state.rootRequestList;
        Object.keys(rootRequestList).forEach((ele, index) => {
          if (rootList.indexOf(rootRequestList[ele].name) !== -1) {
            rootRequestList[ele].isCanUse = true;
          }
        });
        this.setState({
          rootRequestList: rootRequestList,
        });
      })
      .catch((res) => {
        console.log("error");
      });
  };
  GetUserAddDetail() {
    UserAddDetail().then((res) => {
      if (parseInt(res.data.code) === 1) {
        this.setState({
          userDetailList: res.data.data,
        });
      }
    });
  }
}

export default StaffManage;
