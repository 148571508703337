import "./App.css";
// eslint-disable-next-line no-unused-vars
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import { main as mainConfig } from "./router/index";
import { RenderRoutes } from "./router/utils";
import { ConfigProvider } from "antd";
import zhCN from "antd/es/locale/zh_CN";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
function App() {
  return (
    <Router>
      <ConfigProvider locale={zhCN}>
        <div className="App">
          <RenderRoutes routes={mainConfig}> </RenderRoutes>
        </div>
      </ConfigProvider>
    </Router>
  );
}

export default App;
