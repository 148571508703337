import request from '../../request';

const GetActionTag = () => {
    return request
        .get(`enterpriseWechat/actionTag`, {
            params: {

            }
        })
        .then(result => {
            return result.data
        })
}

const GetTagList = () => {
    return request
        .get(`enterpriseWechat/tagLists`, {})
        .then(response => {
            return response.data
        })
}

const EditActionTag = ({
    id,
    tagIds
}) => {
    return request
        .post(`enterpriseWechat/actionTagAddOrMod`, {
            id,
            tagIds
        })
        .then(response => {
            return response.data
        })
}

//创建标签或标签组
const AddTagList = ({
    groupId,
    groupName,
    groupOrder,
    tagName,
    tagOrder
}) => {
    return request
        .post(`enterpriseWechat/createTagOrTagGroup`, {
            groupId,
            groupName,
            groupOrder,
            tagName,
            tagOrder
        })
        .then(response => {
            return response.data
        })
}

//编辑标签或标签组
const EditTagList = ({
    id,
    name,
    order
}) => {
    return request
        .post(`enterpriseWechat/updateTagOrTagGroup`, {
            id,
            name,
            order
        })
        .then(response => {
            return response.data
        })
}

//删除标签或标签组
const DeleteTagList = ({
    tagId,
    groupId
}) => {
    return request
        .post(`enterpriseWechat/deleteTagOrTagGroup`, {
            tagId,
            groupId
        })
        .then(response => {
            return response.data
        })
}

// eslint-disable-next-line
export default {
    GetActionTag,
    GetTagList,
    EditActionTag,
    AddTagList,
    EditTagList,
    DeleteTagList
}