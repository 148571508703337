import request from '../../request';

const GetRequestUrl = () => {
  return request
    .get(`enterpriseWechat/getUploadUrl`, {
      params: {

      }
    })
    .then(result => {
      return result.data
    })
}
/**
 * 微信上传
 */
const UploadWxPic = ({
  id,
  tagIds,
  msgContent,
  msgImg
}) => {
  return request
    .post(`enterpriseWechat/addFriendConfig`, {
      id,
      tagIds,
      msgContent,
      msgImg
    }, {
      // needFormData: true,
    })
    .then(response => {
      return response.data
    })
}
/**
 * 企业微信agentConfig
 * @param {*} param0 
 */
export const GetQyUserId = ({
  code,
}) => {
  return request
    .get(`enterpriseWechat/getEnterpriseWechatUserInfo`, {
      params: {
        code,
      },
      needSign: true,
    },)
    .then(result => {
      return result.data
    })
}

/**
 * 企业微信agentConfig
 * @param {*} param0 
 */
export const GetJsapiSign = ({
  url = window.location.href
  // url = 'https://crm-test.qsongxue.com/h5/userDetails',
  // qyUserId,
}) => {
  return request
    .post(`qyEnterpriseWechat/wapJsapiSign`, {
      url: url.split('#')[0],
      // qyUserId
    }, {
      needSign: true
    })
    .then(result => {
      return result.data
    })
}

export const BindUserInfo = ({
  externalUserid,
  qyUserId,
}) => {
  return request
    .get(`qyEnterpriseWechat/userBindInfo`, {
      params: {
        externalUserid,
        qyUserId,
      },
      needSign: true
    })
    .then(result => {
      return result.data
    })
}

export const BindUserMobile = ({
  externalUserid,
  qyUserId,
  mobile,
}) => {
  return request
    .get(`qyEnterpriseWechat/bindUser`, {
      params: {
        externalUserid,
        qyUserId,
        mobile,
      },
      needSign: true
    })
    .then(result => {
      return result.data
    })
}

export const UserContactChangeHistory = ({
  externalUserid,
  qyUserId
}) => {
  return request
    .get(`qyEnterpriseWechat/userContactChangeHistory`, {
      params: {
        externalUserid,
        qyUserId
      },
      needSign: true,
    })
    .then(result => {
      return result.data
    })
}

// eslint-disable-next-line
export default {
  GetRequestUrl,
  UploadWxPic,
  BindUserMobile
}