import crypto from 'crypto'

const Auth = params => {
    var time = Date.now() / 1000
    params.timestamp = parseInt(time)
    params.security_key = '5c42fe7441dg19b11befb1bgc16d83f9'
    var sdic = Object.keys(params).sort()
    var str = ''
    for (var key in sdic) {
        str = str + sdic[key] + params[sdic[key]]
    }
    var md5 = function (str) {
        var crypto_md5 = crypto.createHash('md5')
        crypto_md5.update(str)
        return crypto_md5.digest('hex')
    }

    var md5Str = md5(str)
    params.sign = md5Str
    delete params.security_key
    return params
}

//eslint-disable-next-line
export default {
    Auth
}