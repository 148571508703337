import React, { Component } from "react";
import { Button, Input, message } from "antd";
import LoginApi from "../../../lib/api/user/index";
require("./index.less");
class H5userLogin extends Component {
  state = {
    username: "",
    password: "",
  };
  UNSAFE_componentWillMount() {
    // this.GetMenus();  
console.log("122")
  }
  //输入账号
  Username = (e) => {
    this.setState({
      username: e.target.value,
    });
  };
  //输入密码
  Password = (e) => {
    this.setState({
      password: e.target.value,
    });
  };
  // 登录
  Login = () => {
    if (this.state.username === "") {
      message.warning("账号不能为空");
      return false;
    }
    if (this.state.password === "") {
      message.warning("密码不能为空");
      return false;
    }
    LoginApi.Login({
      email: this.state.username,
      password: this.state.password,
    }).then(
      (res) => {
        console.log("登录返回", res);
        if (res.status === "success") {
          //登录成功之后的处理
          localStorage.setItem("token", res.token);
          this.props.history.push({
            pathname: `/h5/userDetails`,
            state: {},
          });
        } else {
          message.error(res.message);
        }
      },
    ).catch(error => {
      console.log(error.request.status)
      if(error.request.status === 421) {
        message.error("账号或密码错误，请重新输入");
      }
    });
  };
  render() {
    return (
      <div className="login-page">
        <div className="login-info">
          <div className="text-input">
            账号：
            <Input
              value={this.state.username}
              onChange={(e) => this.Username(e)}
              style={{ width: "180px" }}
            ></Input>
          </div>
          <div className="text-input">
            密码：
            <Input
              value={this.state.password}
              onChange={(e) => this.Password(e)}
              style={{ width: "180px" }}
            ></Input>
          </div>
          <div className="text-login">
            <Button onClick={this.Login} type="primary">
              登录
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
export default H5userLogin;
