import request from '../../request';

const TeacherList = (data) => {
    return request.get(`teacher/index`,{
        params:data
    }).then(res => res)
}
const TeacherAdd = (data) => {
    return request.post(`teacher/add`, data).then(res => res)
}
const TeacherUpdate = (data) => {
    return request.post(`teacher/update`, data).then(res => res)
}
const TeacherUpdateAll = (data) => {
    return request.post(`teacher/allude`, data).then(res => res)
}

const BindUser=(data)=>{
    return request.get(`user/userLists`,{params:data}).then(res=>res)
}

const GetAllteacher=(data)=>{
    return request.get(`teacher/bindUserSelect`,{params:data}).then(res=>res)
}

const BindTeacher=(data)=>{
    return request.post(`teacher/bindUser`,data).then(res=>res)
}
export {
    TeacherList,
    TeacherAdd,
    TeacherUpdate,
    TeacherUpdateAll,
    GetAllteacher,
    BindTeacher,
    BindUser
}