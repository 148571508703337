import request from '../../request';

const GetWorkData = () =>{
    return request
        .get(`index/data`, {
            params: {
                
            }
        })
        .then(result => {
            return result.data
        })
}

function EditUserAgeInfo({
    uid,
    age,
    foundation,
}) {
    return request
        .post(`magicClass/userWxBindUpdate`, {
            uid,
            age,
            foundation,
        })
        .then(response => {
            return response.data
        })
}

// eslint-disable-next-line
export default {
    GetWorkData,
    EditUserAgeInfo
}