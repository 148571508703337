import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import NotFound from "../pages/notFound/index";

export const RouteWithSubRoutes = (route) => (
  <Route
    path={route.path}
    exact={route.exact}
    render={(props) => {
      return (
        route &&
        (route.Redirect ? (
          <Redirect to={route.Redirect}></Redirect>
        ) : (
          <route.component {...props} routes={route.routes} />
        ))
      );
    }}
  />
);

export const RenderRoutes = ({ routes }) => {
  return (
    <Switch>
     
      {routes.map((route, i) => (
        <RouteWithSubRoutes key={i} {...route} />
      ))}
        <Route path='/404' component={NotFound}></Route>
        <Redirect to="/404"></Redirect>
    </Switch>
  );
};
