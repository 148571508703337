import React, { Component } from "react";
import { message, Tag, Steps, Tabs, Input, Select } from "antd";
import {
  GetJsapiSign,
  UserContactChangeHistory,
  GetQyUserId
} from "@/lib/api/weixin/index";

require("./index.less");
const { TabPane } = Tabs;
const { Option } = Select;
const { TextArea } = Input;
const { Step } = Steps;

class H5userFriends extends Component {
  state = {
    history: [],
    teacherTag: [],
    //企业微信相关
    appId: "",
    timestamp: "",
    nonceStr: "",
    signature: "",
    userId: "wm8fzvEAAAah0C1xCh4NN1FsAiF_zhsw", //最后获取到的userId
    wxCode: "",
    loginUserId: "wm8fzvEAAAbwb572aRLXTqmTqbynGaZQ",
  };
  UNSAFE_componentWillMount() {
    // this.GetList();
    console.log("无localstorage")
      let url = window.location.href.indexOf("code") !== -1;
      console.log(url);
      if (!url) {
        window.location.href =
          "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wwb60d33c318abcd7e&redirect_uri=https://crm.qsongxue.com/h5/userFriends&response_type=code&scope=snsapi_base&state=wxwork#wechat_redirect";
      } else {
        console.log(
          "重定向后的URL",
          window.location.href,
          // this.getQueryVariable("code")
        );
        this.setState(
          {
            wxCode: this.getQueryVariable("code"),
          },
          function () {
            this.GetUserId();
          }
        );
      }
  }
  // 组件即将销毁
  componentWillUnmount() {
    // localStorage.removeItem("userId");
  }
  getQueryVariable = (variable) => {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      // eslint-disable-next-line eqeqeq
      if (pair[0] == variable) {
        return pair[1];
      }
    }
    return false;
  };
  GetUserId = () => {
    GetQyUserId({
      code: this.state.wxCode,
    }).then((res) => {
      console.log("获取企业ID", res);
      if (res.code === 1) {
        if(res.data.UserId){
          this.setState({
            loginUserId: res.data.UserId
          },function(){
            this.Init()
          })
        }else  {
          message.error("用户非法")
        }
      }
    });
  };
  
  async Init() {
    try {
      await this.GetConfig();
      await this.LoadConfig();
      await this.GetCurUserId();
      // this.hasBind = await this.GetUserInfo()
      // console.log('hasBind', this.hasBind)
    } catch (error) {
      console.log(error);
    }
  }
  async GetConfig() {
    return new Promise((resolve, reject) => {
      GetJsapiSign({
        // qyUserId: this.state.loginUserId
        // url: window.location.href
      }).then((res) => {
        console.log("GetJsapiSign", res);
        this.setState({
          appId: res.data.appId,
          timestamp: res.data.timestamp,
          nonceStr: res.data.nonceStr,
          signature: res.data.signature,
        });
        resolve(1);
      });
    });
  }
  async LoadConfig() {
    return new Promise((resolve, reject) => {
      let that = this
      this.getScript("https://res.wx.qq.com/open/js/jweixin-1.2.0.js", () => {
        this.getScript(
          "https://open.work.weixin.qq.com/wwopen/js/jwxwork-1.0.0.js",
          () => {
            console.log("222", resolve, reject);
            // eslint-disable-next-line no-undef
            wx.agentConfig({
              debug: true,
              corpid: that.state.appId, // 必填，企业微信的corpid，必须与当前登录的企业一致
              agentid: "1000009", // 必填，企业微信的应用id （e.g. 1000247）
              timestamp: that.state.timestamp, // 必填，生成签名的时间戳
              nonceStr: that.state.nonceStr, // 必填，生成签名的随机串
              signature: that.state.signature, // 必填，签名，见附录-JS-SDK使用权限签名算法
              jsApiList: ["getCurExternalContact"], //必填
              success: function (res) {
                // 回调
                resolve(1);
                console.log("agentConfig", res);
              },
              fail: function (res) {
                console.log("agentConfig err", res);
                if (res.errMsg.indexOf("function not exist") > -1) {
                  alert("版本过低请升级");
                }
              },
            });
            // eslint-disable-next-line no-undef
            wx.ready(() => {
              console.log("ready");
            });
            // eslint-disable-next-line no-undef
            wx.error((err) => {
              console.log("error", err);
            });
          }
        );
      });
    });
  }
  async GetCurUserId() {
    let that = this
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line no-undef
      wx.invoke("getCurExternalContact", {}, function (res) {
        console.log("GetCurUserId", res);
        if (res.err_msg === "getCurExternalContact:ok") {
          console.log("得到userID",res.userId)
          resolve(res.userId);
          that.setState({
            userId: res.userId,
          },function(){
            console.log("执行获取记录")
            that.GetList();
          });
        } else {
          //错误处理
          this.$toast(res.err_msg);
          reject(res.err_msg);
        }
      });
    });
  }
  //动态添加js文件
  getScript(url, callback) {
    var head = document.getElementsByTagName("head")[0],
      js = document.createElement("script");

    js.setAttribute("type", "text/javascript");
    js.setAttribute("src", url);
    head.appendChild(js);
    //执行回调
    var callbackFn = function () {
      if (typeof callback === "function") {
        callback();
      }
    };
    if (document.all) {
      //IE
      js.onreadystatechange = function () {
        if (js.readyState === "loaded" || js.readyState === "complete") {
          callbackFn();
        }
      };
    } else {
      js.onload = function () {
        callbackFn();
      };
    }
  }
  GetList= ()=> {
    UserContactChangeHistory({
      externalUserid: this.state.userId,
      qyUserId: this.state.loginUserId
    }).then(
      (res) => {
        if (res.code === 1) {
          this.setState({
            history: res.data.history,
            teacherTag: res.data.teacherTag
          })
          console.log(res,this.state.history);
        } else {
          message.warning(res.msg);
        }
      },
      (error) => {
        console.log("failed!");
      }
    );
  }
  render() {
    let teacherList = this.state.teacherTag.map((item, index) => {
      let tag = item.tags.map((tag,tagIndex)=>{
        return (
          <Tag color="#2db7f5" key={tagIndex}>{tag.tag_name}</Tag>
        )
      })
      return (
        <div className="teacher-list" key={index}>
          <span className="name">{item.teacherName}</span>
          {tag}
        </div>
      );
    });
    let HistoryList = this.state.history.map((item, index) => {
      let text = ''
      if(parseInt(item.type)===1){
        if(item.del_type===0){
          text = `${item.create_time} ${item.teacherName}删除了用户`
        }else if(item.del_type===1) {
          text = `${item.create_time} 用户删除了${item.teacherName}`
        }
      } else if(parseInt(item.type)===3){
        text = `${item.create_time} 添加了${item.teacherName}`
      }
      return (
        <Step title="" key={index} description={text} />
      );
    });
    return (
      <div className="user-page">
        {teacherList}
        <div style={{height: '12px'}}></div>
        <Steps direction="vertical" current={HistoryList.length}>
          {HistoryList}
        </Steps>
      </div>
    );
  }
}
export default H5userFriends;
