import React, { Component } from "react";
import Line from "../../../components/ReactEcharts";
import { Table, message, Popover } from "antd";
import DataApi from "../../../lib/api/data/index";
import { InfoCircleOutlined } from "@ant-design/icons";
require("./index.less");
const content1 = (
  <div>
    <p>本月销售所有订单金额总和</p>
  </div>
);
const content2 = (
  <div>
    <p>本月销售所有订单总量</p>
  </div>
);
const content3 = (
  <div>
    <p>昨日销售所有订单总金额</p>
  </div>
);
class ExClassDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 3,
      current: 0,
      total: 0,
      pageSize: 10,
      pageSizeOption: [5, 10, 15, 20],
      //option可由函数初始化
      userGroupRank: [],
      allDataInfo: {},
      tableList: [],
      saleNumList: [],
      orderNumList: [],
      dayList: [],
    };
  }
  componentWillMount() {
    this.GetDataAll();
    this.GetTableData();
  }

  GetDataAll = () => {
    DataApi.GetAllData({}).then(
      (res) => {
        console.log(res);
        if (res.code === 1) {
          let saleList = [];
          let orderList = [];
          let dayList = [];
          res.data.saleDiagram.forEach((item,index) => {
            if(item.stat_dawn_time){
              dayList.push(new Date(item.stat_dawn_time*1000).format("yyyy-MM-dd"));
            }else {
              dayList.push(new Date().format("yyyy-MM-dd"))
            }
            saleList.push(item.deal_total_amount);
            orderList.push(item.deal_total_num);
          });
          this.setState({
            userGroupRank: res.data.userGroupRank,
            allDataInfo: res.data,
            saleNumList: saleList,
            orderNumList: orderList,
            dayList: dayList
          });
          console.log(this.state.saleNumList, this.state.orderNumList);
        } else {
          message.error(res.msg);
        }
      },
      (error) => {
        console.log("failed!");
      }
    );
  };

  GetTableData = () => {
    DataApi.GetTableData({
      start: this.state.current,
      rows: this.state.pageSize,
    }).then(
      (res) => {
        console.log(res);
        if (res.code === 1) {
          this.setState({
            total: res.data.total,
            tableList: res.data.list.map((item,index)=>{
              if(item.stat_dawn_time){
                item.createTime = new Date(item.stat_dawn_time*1000).format("yyyy-MM-dd")
              }else {
                item.createTime = new Date().format("yyyy-MM-dd")
              }
              return {
                ...item
              }
            }),
          });
        } else {
          message.error(res.msg);
        }
      },
      (error) => {
        console.log("failed!");
      }
    );
  };

  render() {
    const columns = [
      {
        title: "时间",
        dataIndex: "createTime",
        key: "0",
      },
      {
        title: "订单总计",
        dataIndex: "deal_total_num",
        key: "1",
      },
      {
        title: "营收总计",
        dataIndex: "deal_total_amount",
        key: "2",
      },
      {
        title: "社群订单",
        dataIndex: "deal_social_num",
        key: "3",
      },
      {
        title: "社群营收",
        dataIndex: "deal_social_amount",
        key: "4",
      },
      {
        title: "长期班订单",
        dataIndex: "deal_long_num",
        key: "5",
      },
      {
        title: "长期班营收",
        dataIndex: "deal_long_amount",
        key: "6",
      },
    ];
    let rankList = this.state.userGroupRank.map((item, index) => {
      return (
        <div className="rank-list" key={index}>
          <div className={`rank-index${index < 3 ? "" : " active"}`}>
            {index + 1}
          </div>
          <div className="rank-name">{item.groupName}</div>
          <div className="rank-num">{item.saleTotal}</div>
        </div>
      );
    });

    // let { option } = this.state;
    return (
      <div className="data-page">
        <div className="achieve-content">
          <div className="common-achieve">
            <div className="achieve-title">
              本月销售总额 
              <Popover content={content1} title="Title">
              <InfoCircleOutlined
                style={{ color: "rgba(0, 0, 0, 0.45)", lineHeight: "25px" }}
              ></InfoCircleOutlined>
              </Popover>
            </div>
            <div className="achieve-center">
              {this.state.allDataInfo.monthSaleTotal?this.state.allDataInfo.monthSaleTotal:0}
            </div>
            <div className="achieve-bottom">
              人均销售额
              {this.state.allDataInfo.saleUserTotal ? (
                parseInt(this.state.allDataInfo.monthSaleTotal?this.state.allDataInfo.monthSaleTotal:0) /
                parseInt(this.state.allDataInfo.saleUserTotal?this.state.allDataInfo.saleUserTotal:0)
              ).toFixed(2) : ""}
            </div>
          </div>
          <div className="common-achieve">
            <div className="achieve-title">
              本月订单量 
              <Popover content={content2} title="Title">
              <InfoCircleOutlined
                style={{ color: "rgba(0, 0, 0, 0.45)", lineHeight: "25px" }}
              ></InfoCircleOutlined>
              </Popover>
              </div>
            <div className="achieve-center">
              {this.state.allDataInfo.monthOrderTotal?this.state.allDataInfo.monthOrderTotal:0}
            </div>
            <div className="achieve-bottom">
              人均订单量
              {this.state.allDataInfo.saleUserTotal ? (
                parseInt(this.state.allDataInfo.monthOrderTotal?this.state.allDataInfo.monthOrderTotal:0) /
                parseInt(this.state.allDataInfo.saleUserTotal?this.state.allDataInfo.saleUserTotal:0)
              ).toFixed(2) : ""}
            </div>
          </div>
          {/* <div className="common-achieve">
            <div className="achieve-title">本月人效比 </div>
            <div className="achieve-center">126560 </div>
            <div className="achieve-bottom"></div>
          </div> */}
          <div className="common-achieve">
            <div className="achieve-title">
              昨日营收
              <Popover content={content3} title="Title">
              <InfoCircleOutlined
                style={{ color: "rgba(0, 0, 0, 0.45)", lineHeight: "25px" }}
              ></InfoCircleOutlined>
              </Popover> 
            </div>
            <div className="achieve-center">
              {this.state.allDataInfo.yesterdaySaleTotal}
            </div>
            <div className="achieve-bottom"></div>
          </div>
        </div>
        <div className="achieve-echart">
          <div className="left-echart">
            <Line
              saleNumList={this.state.saleNumList}
              orderNumList={this.state.orderNumList}
              dayList={this.state.dayList}
            ></Line>
          </div>
          <div className="right-list">
            <p className="p-title">销售额小组排名</p>
            {rankList}
          </div>
        </div>
        <div className="table-content">
          <Table
            dataSource={this.state.tableList}
            columns={columns}
            pagination={
              this.state.total > 10
                ? {
                    position: ["bottomLeft"],
                    pageSize: this.state.pageSize,
                    total: this.state.total,
                    showQuickJumper: true,
                    pageSizeOptions: this.state.pageSizeOption,
                    onChange: (current, pageSize) => {
                      console.log(current, pageSize, (current - 1) * pageSize);
                      this.setState(
                        {
                          current: (current - 1) * pageSize,
                          pageSize: pageSize,
                        },
                        function () {
                          this.GetTableData();
                        }
                      );
                    },
                    onShowSizeChange: (current, size) => {
                      console.log(current, size);
                      this.setState(
                        {
                          current: current * size,
                          pageSize: size,
                        },
                        function () {
                          this.GetTableData();
                        }
                      );
                    },
                  }
                : false
            }
          />
        </div>
      </div>
    );
  }
}
export default ExClassDetails;
