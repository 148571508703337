import React, { Component } from "react";
import { Link } from "react-router-dom";
import ClassUserApi from "../../../lib/api/class/classUser";
import MenuApi from "../../../lib/api/menus/index";
import {
  Input,
  Select,
  Space,
  Button,
  Badge,
  Table,
  Row,
  Col,
  Modal,
  Breadcrumb,
  message,
  Form,
} from "antd";
import { HomeOutlined } from "@ant-design/icons";
require("./index.less");
const { Option } = Select;

class ExClassDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      userList: [],
      intentionList: [
        { id: 1, title: "即将成交" },
        { id: 2, title: "高意向" },
        { id: 3, title: "中意向" },
        { id: 4, title: "低意向" },
        { id: 5, title: "无效用户" },
      ],
      selectedRowKeys: [],
      userCouponList: [],
      couponAllList: [],
      couponId: "",
      sendUserNumber: "", //被发券者id
      userUid: "",
      userName: "",
      userMobile: "",
      userDubbingId: "",
      userIntention: '', //用户意向
      courseCountMin: "",
      courseCountMax: "",
      couponCountMin: "",
      couponCountMax: "",
      ageMin: '',
      ageMax: '',
      foundationMin: '',
      foundationMax: '',
      goodType: "",
      intention: "",
      total: 0,
      isModalVisible: false,
      loading: false,
      pageSize: 10,
      current: 0,
      pageSizeOption: [5, 10, 15, 20],
      isShowCouponPop: false,
      isShowCouponPopDetail: false,
      isSuperAdmin: 0,
      allRuleList: [
        {
          name: "coupon/giveCoupon",
          title: "发券",
          show: false,
        },
        {
          name: "magicClass/tasteDetail",
          title: "详情",
          show: false,
        },
      ],
    };
  }
  
  UNSAFE_componentWillMount() {
    this.GetUserList();
    this.GetCouponList();
    this.GetMenus();
  }
  GetCouponList = () => {
    ClassUserApi.GetAllCoupon({
      title: "",
    }).then(
      (res) => {
        if (res.code === 1) {
          this.setState({
            couponAllList: res.data.list,
          });
        } else {
          message.error(res.msg);
        }
      },
      (error) => {
        console.log("failed!");
      }
    );
  };
  GetUserList = () => {
    let id = this.props.match.params.classId;
    ClassUserApi.GetClassUserList({
      id: id,
      start: this.state.current,
      rows: this.state.pageSize,
      userName: this.state.userName,
      userMobile: this.state.userMobile,
      userDubbingId: this.state.userDubbingId,
      courseCountMin: this.state.courseCountMin,
      courseCountMax: this.state.courseCountMax,
      couponCountMin: this.state.couponCountMin,
      couponCountMax: this.state.couponCountMax,
      ageMin: this.state.ageMin,
      ageMax: this.state.ageMax,
      foundationMin: this.state.foundationMin,
      foundationMax: this.state.foundationMax,
      // goodType: this.state.goodType,
      level: this.state.goodType,
      intention: this.state.intention,
      isVipUser: this.state.isVipUser,
    }).then(
      (res) => {
        if (res.code === 1) {
          this.setState({
            total: res.data.total,
            userList: res.data.list.map((item, index) => {
              if (item.purpose === 1) {
                item.purposeText = "即将成交";
              }
              if (item.purpose === 2) {
                item.purposeText = "高意向";
              }
              if (item.purpose === 3) {
                item.purposeText = "中意向";
              }
              if (item.purpose === 4) {
                item.purposeText = "低意向";
              }
              if (item.purpose === 5) {
                item.purposeText = "无效用户";
              }
              if (!item.purpose) {
                item.purposeText = "-";
              }
              if (!item.age) {
                item.age = "-";
              }
              if (!item.foundation) {
                item.foundation = "-";
              }
              if (item.appUser === 1) {
                item.appUserText = "是";
              } else {
                item.appUserText = "否";
              }
              if (item.qy_userid && item.qy_external_userid){
                item.isAddWx = "是"
                item.showWx = true
                item.nickname = item.nick_name
                item.avatar = item.avatar_url
              }else {
                item.isAddWx = "否"
                item.showWx = false
              }
              let arr = item.tasteFinshRate.split("/");
              if (arr[0] === arr[1]) {
                if (arr[0] !== "0" && arr[1] !== "0") {
                  item.studyReport = true;
                } else {
                  item.studyReport = false;
                }
              } else {
                item.studyReport = false;
              }
              if (item.day1) {
                let arr1 = item.day1.split("/");
                if (arr1[0] === arr1[1]) {
                  item.studyReport1 = true;
                } else {
                  item.studyReport1 = false;
                }
              }
              if(item.day2) {
                let arr2 = item.day2.split("/");
                if (arr2[0] === arr2[1]) {
                  item.studyReport2 = true;
                } else {
                  item.studyReport2 = false;
                }
              }
              if(item.day3) {
                let arr3 = item.day3.split("/");
                if (arr3[0] === arr3[1]) {
                  item.studyReport3 = true;
                } else {
                  item.studyReport3 = false;
                }
              }
              if(item.day4) {
                let arr4 = item.day4.split("/");
                if (arr4[0] === arr4[1]) {
                  item.studyReport4 = true;
                } else {
                  item.studyReport4 = false;
                }
              }
              if(item.day5) {
                let arr5 = item.day5.split("/");
                if (arr5[0] === arr5[1]) {
                  item.studyReport5 = true;
                } else {
                  item.studyReport5 = false;
                }
              }
              res.data.skuType.forEach((items) => {
                if (parseInt(item.good_sku_type) === items.id) {
                  item.good_sku_type_text = items.title;
                }
              });
              return {
                ...item,
                levelText: `L${item.level_id}`,
              };
            }),
          });
        } else {
          message.warning(res.msg);
        }
      },
      (error) => {
        console.log("failed!");
      }
    );
  };
  
  componentDidMount() {
    this.InitData();
  }
  InitData = () => {};
  HandleOk = () => {
    this.setState({
      isModalVisible: false,
    });
  };
  HandleCancel = () => {
    this.setState({
      isModalVisible: false,
    });
  };
  HandleCancelCoupon = () => {
    this.setState({
      isShowCouponPop: false,
    });
  };
  //确认发券
  HandleOkCoupon = () => {
    ClassUserApi.SendCoupon({
      userNumbers: this.state.sendUserNumber,
      couponId: this.state.couponId,
    }).then((res) => {
      if (res.code === 1) {
        //登录成功之后的处理
        message.info(res.msg);
        this.setState(
          {
            isShowCouponPop: false,
            sendUserNumber: "",
          },
          function () {
            this.GetUserList();
          }
        );
      } else {
        message.error(res.msg);
      }
    });
  };
  //重置
  ResetForm = () => {
    this.refs.search.resetFields();
    this.setState(
      {
        id: this.props.match.params.classId,
        start: this.state.current,
        rows: this.state.pageSize,
        userName: "",
        userMobile: "",
        userDubbingId: "",
        courseCountMin: "",
        courseCountMax: "",
        couponCountMin: "",
        couponCountMax: "",
        ageMin: '',
        ageMax: '',
        foundationMin: '',
        foundationMax: '',
        goodType: "",
        intention: "",
        isVipUser: 1,
      },
      function () {
        this.GetUserList();
      }
    );
  };
  GetMenus = () => {
    MenuApi.GetMenuList().then(
      (res) => {
        if (res) {
          if (res.code === 1) {
            this.setState({
              allRuleInfo: res.data.auth,
              isSuperAdmin: res.data.isSuperAdmin,
            });
            this.GetRuleList();
          } else {
            message.warning(res.msg);
          }
        }
      },
      (error) => {
        console.log("failed!");
      }
    );
  };
  GetRuleList = () => {
    let authList = this.state.allRuleInfo;
    let list = authList.map((item, index) => {
      return {
        name: item.name,
        title: item.title,
        id: item.id,
      };
    });
    this.GetBtnStatus(list);
  };

  //获取按钮显示隐藏
  GetBtnStatus = (list) => {
    let allRule = this.state.allRuleList;
    list.forEach((item) => {
      allRule.map((items) => {
        if (item.name === items.name) {
          items.show = true;
        }
        return {
          ...items,
        };
      });
    });
    console.log("this.state.allRuleList", this.state.allRuleList);
  };
  //输入相关
  AddUserName = (e) => {
    this.setState({
      userName: e.target.value,
    });
  };
  AddMobile = (e) => {
    this.setState({
      userMobile: e.target.value,
    });
  };
  AddDubbing = (e) => {
    this.setState({
      userDubbingId: e.target.value,
    });
  };
  AddCourseMin = (e) => {
    this.setState({
      courseCountMin: e.target.value,
    });
  };
  AddCourseMax = (e) => {
    this.setState({
      courseCountMax: e.target.value,
    });
  };
  AddCouponMin = (e) => {
    this.setState({
      couponCountMin: e.target.value,
    });
  };
  AddCouponMax = (e) => {
    this.setState({
      couponCountMax: e.target.value,
    });
  };
  AddAgeMin = (e) => {
    this.setState({
      ageMin: e.target.value,
    });
  };
  AddAgeMax = (e) => {
    this.setState({
      ageMax: e.target.value,
    });
  };
  AddFoundationMin = (e) => {
    this.setState({
      foundationMin: e.target.value,
    });
  }
  AddFoundationMax = (e) => {
    this.setState({
      foundationMax: e.target.value,
    });
  }
  //下拉选择
  HandleGoodType = (value) => {
    this.setState({
      goodType: `${value}`,
    });
  };
  HandleIsVip = (value) => {
    this.setState({
      isVipUser: `${value}`,
    });
  };
  HandleIntention = (value) => {
    this.setState({
      intention: `${value}`,
    });
  };
  HandleCouponId = (value) => {
    this.setState({
      couponId: `${value}`,
    });
  };
  GoStuduReport = (record, type, day) => {
    ClassUserApi.GetStudyReport({
      uid: record.uid,
      type: type,
      levelId: record.level_id,
      dumbvld: record.dumbv_id,
      day: day,
    }).then(
      (res) => {
        if (res.code === 1) {
          window.open(`${res.data.studyReportUrl}`);
        } else {
          message.error(res.msg);
        }
      },
      (error) => {
        console.log("failed!");
      }
    );
  };
  CopyPhone = (mobile) => {
    var number = document.createElement("input");
    document.body.appendChild(number);
    number.value = `${mobile}`;
    number.select();
    document.execCommand("copy");
    document.body.removeChild(number);
    message.success(`复制成功-->${mobile}`);
  };
  //获取用户优惠券
  GetUserCoupon = (id) => {
    ClassUserApi.GetUserCoupoon({
      uid: id,
    }).then(
      (res) => {
        if (res.code === 1) {
          this.setState({
            userCouponList: res.data.list.map((item) => {
              if (item.used === 1) {
                item.userText = "已使用";
              }
              if (item.used === 0) {
                item.userText = "未使用";
              }
              if (item.end_time - Date.parse(new Date()) / 1000 < 0) {
                item.userText = "已过期";
              }
              return {
                ...item,
              };
            }),
          });
        } else {
          message.error(res.msg);
        }
      },
      (error) => {
        console.log("failed!");
      }
    );
  };

  render() {
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
      labelAlign: "left",
    };
    const columns = [
      {
        title: "",
        width: 80,
        // dataIndex: "avatar",
        key: "a",
        render: (record) => (
          // console.log("recoreavatar",record)
          <Badge count={"wx"} offset={[0, 40]} style={{ display: record.showWx ? 'block' : 'none', backgroundColor: '#52c41a' }}>
          <img
            alt=""
            src={`${record.showWx ? record.avatar_url : record.avatar}`}
            className="avatar"
            style={{ width: "40px", height: "40px", background: "#000" }}
          />
          </Badge>
        ),
      },
      {
        title: "用户名",
        width: 120,
        // dataIndex: "nickname",
        key: "0",
        render: (record) => (
          <Space size="middle">
            <Link to={`/user/Details/${record.uid}`} className="link">
              {record.showWx ? record.nick_name : record.nickname}
            </Link>
          </Space>
        ),
      },
      {
        title: "手机",
        width: 150,
        dataIndex: "mobile",
        key: "b",
        render: (res) => {
          if (res) {
            let phone = res.split("");
            phone.splice(3, 5, "*****");
            return (
              <abbr title={res} style={{ textDecoration: "none" }}>
                {phone}
                <Button onClick={() => this.CopyPhone(res)}>复制</Button>
              </abbr>
            );
          } else {
            return (
              <abbr title={res} style={{ textDecoration: "none" }}>
                {res}
              </abbr>
            );
          }
        },
      },
      {
        title: "趣配音账号",
        dataIndex: "user_number",
        key: "1",
        width: 150,
      },
      {
        title: "年龄",
        dataIndex: "age",
        key: "2",
        width: 150,
      },
      {
        title: "学龄",
        dataIndex: "foundation",
        key: "3",
        width: 150,
      },
      {
        title: "用户意向",
        dataIndex: "purposeText",
        key: "4",
        width: 150,
      },
      {
        title: "是否加好友",
        dataIndex: "isAddWx",
        key: "56",
        width: 150,
      },
      {
        title: "装机",
        dataIndex: "appUserText",
        key: "7",
        width: 150,
      },
      {
        title: "课程等级",
        dataIndex: "levelText",
        key: "8",
        width: 150,
      },
      {
        title: "热身课",
        // dataIndex: "tasteFinshRate",
        key: "9",
        width: 150,
        render: (record) => (
          <Space size="middle">
            {record.studyReport ? (
              <Button
                size="small"
                type="primary"
                onClick={() => this.GoStuduReport(record, 2, "")}
              >
                学习报告
              </Button>
            ) : (
              record.tasteFinshRate
            )}
          </Space>
        ),
      },
      {
        title: "D1",
        // dataIndex: "day1",
        key: "10",
        width: 150,
        render: (record) => (
          <Space size="middle">
            {record.studyReport1 ? (
              <Button
                size="small"
                type="primary"
                onClick={() => this.GoStuduReport(record, 0, 1)}
              >
                学习报告
              </Button>
            ) : (
              record.day1
            )}
          </Space>
        ),
      },
      {
        title: "D2",
        // dataIndex: "day2",
        key: "11",
        width: 150,
        render: (record) => (
          <Space size="middle">
            {record.studyReport2 ? (
              <Button
                size="small"
                type="primary"
                onClick={() => this.GoStuduReport(record, 0, 2)}
              >
                学习报告
              </Button>
            ) : (
              record.day2
            )}
          </Space>
        ),
      },
      {
        title: "D3",
        // dataIndex: "day3",
        key: "12",
        width: 150,
        render: (record) => (
          <Space size="middle">
            {record.studyReport3 ? (
              <Button
                size="small"
                type="primary"
                onClick={() => this.GoStuduReport(record, 0, 3)}
              >
                学习报告
              </Button>
            ) : (
              record.day3
            )}
          </Space>
        ),
      },
      {
        title: "D4",
        // dataIndex: "day4",
        key: "13",
        width: 150,
        render: (record) => (
          <Space size="middle">
            {record.studyReport4 ? (
              <Button
                size="small"
                type="primary"
                onClick={() => this.GoStuduReport(record, 0, 4)}
              >
                学习报告
              </Button>
            ) : (
              record.day4
            )}
          </Space>
        ),
      },
      {
        title: "D5",
        // dataIndex: "day5",
        key: "14",
        width: 150,
        render: (record) => (
          <Space size="middle">
            {record.studyReport5 ? (
              <Button
                size="small"
                type="primary"
                onClick={() => this.GoStuduReport(record, 0, 5)}
              >
                学习报告
              </Button>
            ) : (
              record.day5
            )}
          </Space>
        ),
      },
      {
        title: "优惠券",
        // dataIndex: "couponCount",
        key: "operation",
        width: 150,
        render: (record) => (
          <Link
            to="#"
            onClick={() => {
              this.GetUserCoupon(record.uid);
              this.setState({
                userUid: record.uid,
                isShowCouponPopDetail: true,
              });
            }}
          >
            {record.couponCount}
          </Link>
        ),
      },
      {
        title: "操作",
        key: "operation",
        fixed: "right",
        width: 130,
        render: (record) => (
          <Space size="middle">
            <Link
              to={`/user/Details/${record.uid}`}
              onClick={() => {
                this.setState({
                  isModalVisible: true,
                });
              }}
              className="link"
            >
              详情
            </Link>
            <Link
              to="#"
              className="link"
              onClick={() => {
                this.setState({
                  sendUserNumber: record.user_number,
                  isShowCouponPop: true,
                });
              }}
            >
              发券
            </Link>
          </Space>
        ),
      },
    ];
    const couponColumn = [
      {
        title: "优惠券名称",
        width: 80,
        dataIndex: "title",
        key: 0,
      },
      {
        title: "SKU类型",
        width: 80,
        dataIndex: "tyIdText",
        key: 1,
      },
      {
        title: "描述",
        width: 80,
        dataIndex: "description",
        key: 2,
      },
      {
        title: "金额",
        width: 80,
        dataIndex: "amount",
        key: 3,
      },
      {
        title: "状态",
        width: 80,
        dataIndex: "userText",
        key: 10,
      },
    ];
    let optionDataList = this.state.intentionList.map((items, indexs) => {
      return (
        <Option value={`${items.id}`} key={items.id}>
          {items.title}
        </Option>
      );
    });
    let couponAllList = this.state.couponAllList.map((items, indexs) => {
      return (
        <Option value={`${items.id}`} key={items.id}>
          {items.title}
        </Option>
      );
    });
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        if (selectedRowKeys.length === 1) {
          this.setState({
            sendUserNumber: selectedRowKeys[0],
          });
        } else {
          this.setState({
            sendUserNumber: selectedRowKeys.join(","),
          });
        }
      },
      getCheckboxProps: (record) => ({
        disabled: record.name === "Disabled User",
        // Column configuration not to be checked
        name: record.name,
      }),
    };
    return (
      <div className="main-page exDetail">
        <div className="bread">
          <Breadcrumb>
            <Breadcrumb.Item href="/classManage/class">
              <HomeOutlined></HomeOutlined>
            </Breadcrumb.Item>
            <Breadcrumb.Item>体验班级详情</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="search-box">
          <Form {...layout} ref="search">
            <Row
              align={"middle"}
              gutter={{ xs: 4, sm: 8, md: 12, lg: 10, xl: 10 }}
            >
              {/* <Col sm={12} md={8} lg={8} xl={6}>
                <Form.Item label="用户名" name="userName">
                  <Input
                    value={this.state.userName}
                    onChange={(e) => this.AddUserName(e)}
                  ></Input>
                </Form.Item>
              </Col> */}
              <Col sm={12} md={8} lg={8} xl={6}>
                <Form.Item label="手机" name="mobile">
                  <Input
                    value={this.state.userMobile}
                    type="number"
                    onChange={(e) => this.AddMobile(e)}
                  ></Input>
                </Form.Item>
              </Col>
              <Col sm={12} md={8} lg={8} xl={6}>
                <Form.Item label="趣配音账号" name="qpyAccount">
                  <Input
                    value={this.state.userDubbingId}
                    type="number"
                    onChange={(e) => this.AddDubbing(e)}
                  ></Input>
                </Form.Item>
              </Col>
              <Col sm={12} md={8} lg={8} xl={6}>
                <Form.Item label="用户意向" name="userIntention">
                  <Select
                    value={this.state.intention}
                    onChange={this.HandleIntention}
                    style={{ width: 150 }}
                  >
                    {optionDataList}
                  </Select>
                </Form.Item>
              </Col>
              <Col sm={12} md={8} lg={8} xl={6}>
                <Form.Item label="是否装机" name="isInstall">
                  <Select
                    value={this.state.isVipUser}
                    onChange={this.HandleIsVip}
                    style={{ width: 150 }}
                  >
                    <Option value="1">是</Option>
                    <Option value="0">否</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col sm={12} md={8} lg={8} xl={6}>
                <Form.Item label="课程等级" name="lesson">
                  <Select
                    value={this.state.goodType}
                    onChange={this.HandleGoodType}
                    style={{ width: 150 }}
                  >
                    <Option value="1">L1</Option>
                    <Option value="2">L2</Option>
                    <Option value="3">L3</Option>
                    <Option value="4">L4</Option>
                    <Option value="5">L5</Option>
                    <Option value="6">L6</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col sm={12} md={8} lg={8} xl={6}>
                <Form.Item label="完课数" name="complete">
                  <Input.Group compact>
                    <Input
                      value={this.state.courseCountMin}
                      type="number"
                      onChange={(e) => this.AddCourseMin(e)}
                      style={{ width: "75px" }}
                      defaultValue="0"
                    />
                    <Input
                      value={this.state.courseCountMax}
                      type="number"
                      onChange={(e) => this.AddCourseMax(e)}
                      style={{ width: "75px" }}
                      defaultValue="0"
                    />
                  </Input.Group>
                </Form.Item>
              </Col>
              <Col sm={12} md={8} lg={8} xl={6}>
                <Form.Item label="优惠券" name="coupon">
                  <Input.Group compact>
                    <Input
                      value={this.state.couponCountMin}
                      type="number"
                      onChange={(e) => this.AddCouponMin(e)}
                      style={{ width: "75px" }}
                      defaultValue="0"
                    />
                    <Input
                      value={this.state.couponCountMax}
                      type="number"
                      onChange={(e) => this.AddCouponMax(e)}
                      style={{ width: "75px" }}
                      defaultValue="0"
                    />
                  </Input.Group>
                </Form.Item>
              </Col>
              <Col sm={12} md={8} lg={8} xl={6}>
                <Form.Item label="年龄" name="coupon">
                  <Input.Group compact>
                    <Input
                      value={this.state.ageMin}
                      type="number"
                      onChange={(e) => this.AddAgeMin(e)}
                      style={{ width: "75px" }}
                      defaultValue="0"
                    />
                    <Input
                      value={this.state.ageMax}
                      type="number"
                      onChange={(e) => this.AddAgeMax(e)}
                      style={{ width: "75px" }}
                      defaultValue="0"
                    />
                  </Input.Group>
                </Form.Item>
              </Col>
              <Col sm={12} md={8} lg={8} xl={6}>
                <Form.Item label="学龄" name="coupon">
                  <Input.Group compact>
                    <Input
                      value={this.state.foundationMin}
                      type="number"
                      onChange={(e) => this.AddFoundationMin(e)}
                      style={{ width: "75px" }}
                      defaultValue="0"
                    />
                    <Input
                      value={this.state.foundationMax}
                      type="number"
                      onChange={(e) => this.AddFoundationMax(e)}
                      style={{ width: "75px" }}
                      defaultValue="0"
                    />
                  </Input.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row justify={"space-between"}>
              <Col>
                <Button
                  type="primary"
                  onClick={() => {
                    this.setState({
                      isShowCouponPop: true,
                    });
                  }}
                  size={"middle"}
                  className="btn-search"
                >
                  批量发放优惠券
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  size={"middle"}
                  onClick={this.GetUserList}
                  className="btn-search"
                >
                  查询
                </Button>
                <Button size={"middle"} onClick={this.ResetForm}>
                  重置
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
        <div className="right-container">
          <Table
            rowSelection={{
              type: "checkbox",
              ...rowSelection,
            }}
            rowKey={(record) => record.user_number}
            scroll={{
              x: 1500,
              y: 600,
            }}
            columns={columns}
            dataSource={this.state.userList}
            pagination={
              this.state.total > 10
                ? {
                    position: ["bottomLeft"],
                    pageSize: this.state.pageSize,
                    total: this.state.total,
                    showQuickJumper: true,
                    pageSizeOptions: this.state.pageSizeOption,
                    onChange: (current, pageSize) => {
                      this.setState(
                        {
                          current: (current - 1) * pageSize,
                          pageSize: pageSize,
                        },
                        function () {
                          this.GetUserList();
                        }
                      );
                    },
                    onShowSizeChange: (current, size) => {
                      this.setState(
                        {
                          current: current * size,
                          pageSize: size,
                        },
                        function () {
                          this.GetUserList();
                        }
                      );
                    },
                  }
                : false
            }
          />
        </div>
        <Modal
          title="详情"
          visible={this.state.isModalVisible}
          onCancel={this.HandleCancel}
          onOk={this.HandleOk}
        >
          <p>ddd-ddd</p>
        </Modal>
        <Modal
          title="发券"
          visible={this.state.isShowCouponPop}
          onCancel={this.HandleCancelCoupon}
          onOk={this.HandleOkCoupon}
        >
          <Row align="middle" style={{ marginBottom: "10px" }}>
            <Col span={12}>趣配音ID: {this.state.sendUserNumber}</Col>
            <Col span={10}></Col>
          </Row>
          <Row align="middle" style={{ marginBottom: "10px" }}>
            <Col span={4}>优惠券</Col>
            <Col span={10}>
              <Select
                showSearch
                style={{ width: 200 }}
                placeholder="Search to Select"
                optionFilterProp="children"
                onChange={this.HandleCouponId}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
              >
                {couponAllList}
              </Select>
            </Col>
          </Row>
        </Modal>
        <Modal
          title="优惠券"
          visible={this.state.isShowCouponPopDetail}
          onCancel={() => {
            this.setState({
              isShowCouponPopDetail: false,
              userCouponList: [],
            });
          }}
          footer={null}
        >
          <Table
            columns={couponColumn}
            scroll={{
              x: 500,
              y: 360,
            }}
            dataSource={this.state.userCouponList}
          ></Table>
        </Modal>
      </div>
    );
  }
}
export default ExClassDetails;
