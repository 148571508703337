import React, { Component } from "react";
import { Layout, Button, message } from "antd";
import { RenderRoutes } from "../../router/utils";
import {
  PieChartOutlined,
  TeamOutlined,
  UsergroupAddOutlined,
  UnorderedListOutlined,
  ExportOutlined,
  AreaChartOutlined
} from "@ant-design/icons";
import { Menu } from "antd";
import LoginApi from "../../lib/api/user/index";
import MenuApi from "../../lib/api/index/index";
import Menus from "../../components/menus";
require("./index.less");
// const { SubMenu } = Menu;

const { Header, Content, Sider } = Layout;

class Index extends Component {
  state = {
    collapsed: false,
    username: "",
    theme: "dark",
    path: "",
    workBench: {},
    menusList: [],
    iconList: [
      <TeamOutlined />,
      <UsergroupAddOutlined />,
      <PieChartOutlined />,
      <UnorderedListOutlined />,
      <AreaChartOutlined />
    ],
  };
  UNSAFE_componentWillMount() {
    this.GetMenus();
    let token = localStorage.getItem("token");
    if (!token) {
      this.props.history.push({
        pathname: `/login`,
        state: {},
      });
    }
  }
  componentDidMount() {
    let that = this;
    var orignalSetItem = localStorage.setItem;
    //监听缓存变化来控制是否需要刷新左侧菜单
    localStorage.setItem = function (key, newValue) {
      var setItemEvent = new Event("setItemEvent");
      setItemEvent.key = key;
      setItemEvent.newValue = newValue;
      window.dispatchEvent(setItemEvent);
      orignalSetItem.apply(this, arguments);
    };
    window.addEventListener("setItemEvent", function (e) {
      if (e.key === "refresh") {
        var _this = localStorage.getItem("demo");
        if (_this !== e.newValue) {
          // alert('key值为demo的值发生改变,之前是'+_this+'当前为'+e.newValue)
          if (e.newValue === "1") {
            that.GetMenus();
          }
        } else {
          // alert('key值为demo值'+e.newValue);
        }
      }
    });
  }

  //左侧菜单收起-展开
  OnCollapse = (collapsed) => {
    this.setState({
      collapsed,
    });
  };
  //退出登录
  LoginOut = () => {
    let token = localStorage.getItem("token");
    LoginApi.LoginOut({
      token,
    })
      .then((res) => {
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        this.props.history.push({
          pathname: `/login`
        });
      })
      .catch((error) => {
        console.log(error.request.status);
      });
  };
  //获取左侧菜单
  GetMenus = () => {
    MenuApi.GetUserInfo().then(
      (res) => {
        if (res) {
          if (res.code === 1) {
            let data = res.data.menu;
            this.setState({
              workBench: data[0],
              menusList: data.slice(1, data.length),
              username: res.data.user.name
            });
          } else {
            message.warning(res.msg);
          }
        }
      },
      (error) => {
        console.log("failed!");
      }
    );
  };
  render() {
    let pathKey = this.props.history.location.pathname;
    let openKey = "";
    // eslint-disable-next-line
    if (
      pathKey === "/user/list/experience" ||
      pathKey === "/user/list/vip"
    ) {
      openKey = "sub2";
    }
    if(window.location.href.indexOf('user/Details')!==-1){
      openKey = "sub2"
    }
    if (pathKey === "/classManage/class") {
      openKey = "sub3";
    }
    if(window.location.href.indexOf('classManage')!==-1){
      openKey = "sub3"
    }
    // eslint-disable-next-line
    if (pathKey == "/dataBoard/achievement") {
      openKey = "sub4";
    }
    // eslint-disable-next-line
    if (
      pathKey === "/systemSet/StaffManage" ||
      pathKey === "/systemSet/teacherManage"
    ) {
      openKey = "sub5";
    }
    
    // let refresh = localStorage.getItem("refresh");
    // if (refresh === "1") {
    //   this.GetMenus();
    // }
    const { collapsed } = this.state;
    let { routes } = this.props;
    return (
      <Layout
        style={{
          height: "100vh",
        }}
      >
        <Sider
          theme={this.state.theme}
          collapsible
          collapsed={collapsed}
          onCollapse={this.OnCollapse}
        >
          <Menu
            theme="dark"
            defaultSelectedKeys={this.state.pathKey}
            mode="inline"
          >
            <Menus
              menusList={this.state.menusList}
              iconList={this.state.iconList}
              pathKey={pathKey}
              openKey={openKey}
            ></Menus>
          </Menu>
        </Sider>
        <Layout className="site-layout">
          <Header
            className="ant-layout-header"
            style={{
              background: "rgba(29, 29, 31, 0.72)",
              backdropFilter: "saturate(180%) blur(20px)",
              color:" #fff",
              fontWeight: "600",
              fintSize: "22px",
            }}
          >
            <div> </div>
            <div className="userInfo">
              {/* <SearchOutlined />
              <BellOutlined /> */}
              <img
                src="https://wapcdn.qupeiyin.cn/app/fundInitiation/5.2.0/logo.png"
                alt=""
              ></img>
              {this.state.username}
              <Button onClick={this.LoginOut} icon={<ExportOutlined />}>
                退出
              </Button>
            </div>
          </Header>
          <Content
            style={{
              margin: "16px 16px",
              minWidth: "960px",
            }}
          >
            <RenderRoutes routes={routes}> </RenderRoutes>
          </Content>
        </Layout>
      </Layout>
    );
  }
}
export default Index;
