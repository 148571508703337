/**
 * 折线图
 */
import React ,{Component} from 'react';
import {Card} from 'antd'
import ReactEcharts from 'echarts-for-react';
class Line extends Component{
    constructor(props){
        super(props)
        this.state = {
            sales:[5, 20, 36, 10, 10, 20],
            stores:[15, 120, 6, 40, 110, 20]
        }
    }
    /**
     * 折线图的配置对象
     */
    getOption = (sales,stores,day) =>{
        // console.log("参数",sales,stores,day)
        return {
            title: {
                text: '销售额趋势'
            },
            tooltip: {
                trigger: 'axis'
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            legend: {
                data:['营销额','订单数']
            },
            xAxis: {
                data: day,
            },
            yAxis: [
                // {
                //     name: '销售额(元)',
                //     type: 'value',
                //     axisLine: {
                //         lineStyle: {
                //             color: '#d14a61'
                //         }
                //     },
                // },
                {
                    type: 'value',
                    name: '销售额(元)',
                    position: 'left',
                    axisLine: {
                        lineStyle: {
                            color: "#d14a61",
                        }
                    },
                    axisLabel: {
                        formatter: '{value} 元'
                    }
                },
                {
                    type: 'value',
                    name: '订单数',
                    position: 'right',
                    axisLine: {
                        lineStyle: {
                            color: '#5793f3'
                        }
                    },
                    axisLabel: {
                        formatter: '{value}单'
                    }
                },
            ],
            series: [{
                name: '营销额',
                type: 'line',
                // stack: '总量',
                data: sales
            },
            {
                name: '订单数',
                type: 'line',
                yAxisIndex: 1,
                // stack: '总量',
                data: stores
            }]
        };
    }
    render(){
        // console.log("组件参数",this.props)
        // const {sales,stores} = this.state;
        return(
            <div>
                <Card>
                    <ReactEcharts option={this.getOption(this.props.saleNumList,this.props.orderNumList,this.props.dayList)} />
                </Card>
            </div>
        )
    }
}
export default Line;