import Login from "../pages/login/login";
import Index from "../pages/index/index";
import WorkBench from "../pages/workBench/index";
import UserList from "../pages/userManage/user/list";
import UserDetails from "../pages/userManage/details/index";
import ExClass from "../pages/classManage/exClass/index";
import ExClassDetails from "../pages/classManage/exDetails/index";
import DataMarket from "../pages/dataBoard/achievement/index";
import Overview from "../pages/dataBoard/overview/index";
import Board from "../pages/dataBoard/board/index";
import StaffManage from "../pages/systemSet/staff/index";
import Jurisdiction from "../pages/systemSet/setRight/index";
import TeacherManage from "../pages/systemSet/teacher/index";
import MenusManage from "../pages/systemSet/menus/index";
import TagManage from "../pages/systemSet/tag/index";
import H5userLogin from "../pages/h5/login/index";
import H5userDetails from "../pages/h5/userDetail/index";
import H5userFriends from "../pages/h5/friends/index";
// 联合会员
import UnionVip from "../pages/activity/unionVip/index";
import UnionVipV2 from "../pages/activity/unionVipV2/index";
import NotFound from "../pages/notFound";

// 菜单相关路由
export const menus = [
  {
    path: "/user/WorkBench",
    exact: true,
    name: "工作台",
    component: WorkBench,
  },
  {
    path: "/user/list/:type",
    exact: true,
    name: "体验用户管理",
    component: UserList,
  },
  {
    path: "/user/Details/:id",
    exact: true,
    name: "体验用户详情",
    component: UserDetails,
  },
  {
    path: "/classManage/class",
    exact: true,
    name: "体验班级管理",
    component: ExClass,
  },
  {
    path: "/classManage/details/:classId",
    exact: true,
    name: "体验班级详情",
    component: ExClassDetails,
  },
  {
    path: "/dataBoard/achievement",
    exact: true,
    name: "业绩大盘",
    component: DataMarket,
  },
  {
    path: "/dataBoard/overview",
    exact: true,
    name: "业绩概览",
    component: Overview,
  },
  { path: "/dataBoard/board", exact: true, name: "业绩概览", component: Board },
  {
    path: "/systemSet/StaffManage",
    exact: true,
    name: "员工管理",
    component: StaffManage,
  },
  {
    path: "/systemSet/StaffManage/Jurisdiction",
    name: "角色权限",
    component: Jurisdiction,
  },
  {
    path: "/systemSet/teacherManage",
    exact: true,
    name: "老师管理",
    component: TeacherManage,
  },
  {
    path: "/systemSet/menusManage",
    exact: true,
    name: "菜单管理",
    component: MenusManage,
  },
  {
    path: "/systemSet/tagManage",
    exact: true,
    name: "自动标签",
    component: TagManage,
  },

  {
    path: "/activity/unionVip",
    exact: true,
    name: "联合会员",
    component: UnionVip,
  },
  {
    path: "/activity/unionVipV2",
    exact: true,
    name: "联合会员-2期",
    component: UnionVipV2,
  },
];

//登录、首页、404路由
export const main = [
  { path: "/login", exact: true, name: "登录", component: NotFound },
  {
    path: "/h5/userLogin",
    exact: true,
    name: "H5用户登录",
    component: H5userLogin,
  },
  {
    path: "/h5/userDetails",
    exact: true,
    name: "H5用户详情",
    component: H5userDetails,
  },
  {
    path: "/h5/userFriends",
    exact: true,
    name: "H5加好友历史",
    component: H5userFriends,
  },
  {path: "/", exact: true , Redirect: "/login"},
  { path: "/404", exact: true, name: "404", component: NotFound },
  { path: "/", name: "首页", component: Index, routes: menus }
];

export const routerConfig = {
  main,
  menus,
};
