import request from '../../request';

const GetAllData = () => {
    return request
        .get(`performance/performanceGrail`, {
            params: {

            }
        })
        .then(result => {
            return result.data
        })
}

//业绩概览
const GetOverViewData = () => {
    return request
        .get(`performance/userPerformance`, {
            params: {

            }
        })
        .then(result => {
            return result.data
        })
}

const GetTableData = ({
    start,
    rows
}) => {
    return request
        .get(`performance/performanceDiagram`, {
            params: {
                start,
                rows
            }
        })
        .then(result => {
            return result.data
        })
}

function EditUserAgeInfo({
    uid,
    age,
    foundation,
}) {
    return request
        .post(`magicClass/userWxBindUpdate`, {
            uid,
            age,
            foundation,
        })
        .then(response => {
            return response.data
        })
}

/**
 * 数据看板
 * http://doc.qupeiyin.net/index.php?s=/13&page_id=4973
 */
const GetDataBoard = ({
    groupIds,
    uids,
    type,
    startTime,
    endTime
}) => {
    return request
    .get(`performance/dataBoard`, {
        params: {
            groupIds,
            uids,
            type,
            startTime,
            endTime
        }
    })
    .then(result => {
        return result.data
    })
}

/**
 * 数据看板 - 下拉筛选接口
 */
const GetDataBoardSelectPage = () => {
    return request
    .get(`performance/getDataBoardSelectPage`, {
        params: {}
    })
    .then(result => {
        return result.data
    })
}

// eslint-disable-next-line
export default {
    GetAllData,
    EditUserAgeInfo,
    GetDataBoard,
    GetDataBoardSelectPage,
    GetOverViewData,
    GetTableData
}