import request from '../../request';
// const HOST = 'http://parrottest.qupeiyin.cn'
const GroupList = (format) => {
    return request.get(`/user/groupList?format=${format}`).then(res => res)
}

const GroupListSale = () => {
    return request.get(`/teacher/getSaleGroup`).then(res => res)
}

const GroupAdd = (data) => {
    return request.post(`user/groupAdd`,data).then(res => res)
}

const GroupMod= (data) => {
    return request.post(`user/groupMod`,data).then(res => res)
}

const GroupUserList = (groupId) => {
    return request.get(`user/groupUserList?groupId=${groupId}`).then(res => res)
}

const AddStaff=(data)=>{
    return request.post(`user/userAddSave`,data).then(res=>res)
}

const UserAddDetail=(data)=>{
    return request.get(`user/userAddDetail`).then(res=>res)
}

const StaffModSave=(data)=>{
    return request.post(`user/userModSave`,data).then(res=>res)
}

export {
    GroupList,
    GroupAdd,
    GroupMod,
    GroupUserList,
    AddStaff,
    UserAddDetail,
    GroupListSale,
    StaffModSave
}