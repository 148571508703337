import React, { Component, Fragment } from "react";
import HeaderSearch from "@/components/headerSearch";
import { Table, Button, message, Modal, Select, Input,Badge } from "antd";
import UserApi from "../../../../lib/api/userManage/user";
import MenuApi from "../../../../lib/api/menus/index";
import { Link } from "react-router-dom";
const { Option } = Select;
const { TextArea } = Input;

class UserGuideList extends Component {
  constructor(props) {
    super(props);
    console.log("体验用户", props);
    this.state = {
      isModalVisible: false,
      searchKey: "",
      intention: null,
      userRecord: {},
      intentionList: [],
      remark: "",
      total: 0,
      userList: [],
      pageSize: 10,
      pageSizeOption: [5, 10, 15, 20],
      current: 0,
      nickname: "",
      mobile: "",
      userNumber: "",
      purpose: "",
      levelId: "",
      teacherId: "",
      ageMin: "",
      ageMax: "",
      finishCourseCountMin: "",
      finishCourseCountMax: "",
      foundationMin: "",
      foundationMax: "",
      courseBeginTimeMin: "",
      courseBeginTimeMax: "",
      isVip: 0,
      formData: [
        // {
        //   name: "name",
        //   label: "用户名",
        //   required: false,
        // },
        {
          name: "mobile",
          label: "手机",
          type: "inputNum",
          required: false,
        },
        {
          name: "id",
          label: "趣配音账号",
          type: "inputNum",
          required: false,
        },
        {
          name: "level_list",
          label: "课程等级",
          required: false,
          type: "option",
          list: [
            {
              text: "请选择",
              value: 0,
            },
            {
              text: "L1",
              value: 1,
            },
            {
              text: "L2",
              value: 2,
            },
            {
              text: "L3",
              value: 3,
            },
            {
              text: "L4",
              value: 4,
            },
            {
              text: "L5",
              value: 5,
            },
            {
              text: "L6",
              value: 6,
            },
          ],
        },
        {
          name: "teacher_list",
          label: "绑定老师",
          required: false,
          type: "canSerchSelect",
          list: [
            {
              text: "请选择",
              value: 0,
            },
            {
              text: "是",
              value: 1,
            },
            {
              text: "否",
              value: 2,
            },
          ],
        },
        {
          name: "purpose",
          label: "用户意向",
          required: false,
          type: "option",
          list: [
            {
              text: "即将成交",
              value: 1,
            },
            {
              text: "高意向",
              value: 2,
            },
            {
              text: "中意向",
              value: 3,
            },
            {
              text: "低意向",
              value: 4,
            },
            {
              text: "无效用户",
              value: 5,
            },
          ],
        },
        {
          name: ["age_start", "age_end"],
          label: "年龄",
          type: "inputNum",
          required: false,
        },
        {
          name: ["stu_start", "stu_end"],
          label: "学龄",
          type: "inputNum",
          required: false,
        },
        {
          name: ["complete_course_start", "complete_course_end"],
          label: "完课数",
          type: "inputNum",
          required: false,
        },
        // {
        //   name: ["age_start", "age_end"],
        //   label: "年龄",
        //   type: "inputNum",
        //   required: false,
        // },
        // {
        //   name: ["stu_start", "stu_end"],
        //   label: "学龄",
        //   type: "inputNum",
        //   required: false,
        // },
        // {
        //   name: "isBind",
        //   label: "是否关联微信",
        //   required: false,
        //   type: "option",
        //   list: [
        //     {
        //       text: "请选择",
        //       value: 0,
        //     },
        //     {
        //       text: "是",
        //       value: 1,
        //     },
        //     {
        //       text: "否",
        //       value: 2,
        //     },
        //   ],
        // },
        {
          name: ["guide_time_start", "guide_time_end"],
          label: "报名时间",
          required: false,
          type: "date",
        },
      ],
      allRuleInfo: [],
      isSuperAdmin: 0,
      allRuleList: [
        {
          name: "magicClass/tasteUserList",
          title: "获取菜单列表",
          show: false,
        },
        {
          name: "magicClass/tasteDetail",
          title: "详情",
          show: false,
        },
        {
          name: "magicClass/followRecordAdd",
          title: "跟进",
          show: false,
        },
      ],
    };
    this.onSearch = this.onSearch.bind(this);
    this.onResetData = this.onResetData.bind(this);
    this.GoDetail = this.GoDetail.bind(this);
    this.searchTeacher = this.searchTeacher.bind(this);
  }
  UNSAFE_componentWillMount() {
    this.GetUserList();
    this.GetTeacherList();
    this.GetMenus();
  }
  GetMenus = () => {
    MenuApi.GetMenuList().then(
      (res) => {
        if (res) {
          if (res.code === 1) {
            this.setState({
              allRuleInfo: res.data.auth,
              isSuperAdmin: res.data.isSuperAdmin,
            });
            this.GetRuleList();
          } else {
            message.warning(res.msg);
          }
        }
      },
      (error) => {
        console.log("failed!");
      }
    );
  };
  GetRuleList = () => {
    let authList = this.state.allRuleInfo;
    let list = authList.map((item, index) => {
      return {
        name: item.name,
        title: item.title,
        id: item.id,
      };
    });
    console.log("ruleList", list);
    this.GetBtnStatus(list);
  };

  //获取按钮显示隐藏
  GetBtnStatus = (list) => {
    let allRule = this.state.allRuleList;
    list.forEach((item) => {
      allRule.map((items) => {
        if (item.name === items.name) {
          items.show = true;
        }
        return {
          ...items,
        };
      });
    });
    console.log("this.state.allRuleList", this.state.allRuleList);
  };

  GetUserList = () => {
    UserApi.UserList({
      start: this.state.current,
      rows: this.state.pageSize,
      nickname: this.state.nickname,
      mobile: this.state.mobile,
      userNumber: this.state.userNumber,
      purpose: this.state.purpose,
      levelId: this.state.levelId,
      teacherId: this.state.teacherId,
      ageMin: this.state.ageMin,
      ageMax: this.state.ageMax,
      finishCourseCountMin: this.state.finishCourseCountMin,
      finishCourseCountMax: this.state.finishCourseCountMax,
      foundationMin: this.state.foundationMin,
      foundationMax: this.state.foundationMax,
      courseBeginTimeMin: this.state.courseBeginTimeMin,
      courseBeginTimeMax: this.state.courseBeginTimeMax,
      isVip: this.props.activeIndex,
    }).then(
      (res) => {
        console.log("用户列表", res);
        if (res.code === 1) {
          const purposeText = {};
          res.data.userPurpose &&
            res.data.userPurpose.forEach((item) => {
              purposeText[item.value] = item.title;
            });
          this.setState({
            total: res.data.total,
            intentionList: res.data.userPurpose,
            userList: res.data.list.map((item, index) => {
              let userPurposeText = "-";
              if (purposeText[item.purpose]) {
                userPurposeText = purposeText[item.purpose];
              }
              if(item.qy_userid){
                item.nickname = item.nick_name
                item.avatar = item.avatar_url
              }else {
                console.log("未绑定")
              }
              item.levelText = `L${item.level_id}`;
              return {
                ...item,
                key: item.uid,
                userPurposeText,
                // createTime: new Date(item.create_time).format("yyyy-MM-dd"),
                createTime: new Date(parseInt(item.create_time) * 1000)
                  .toLocaleString()
                  .replace(/:\d{1,2}$/, " "),
              };
            }),
          });
          this.state.formData.forEach((ele) => {
            if (ele.name === "status") {
              ele.list = res.data.userPurpose.map((item) => {
                return {
                  value: item.levelId,
                  text: item.title,
                };
              });
            }
          });
          this.state.formData.forEach((ele) => {
            if (ele.name === "level_id") {
              ele.list = res.data.levelLists.map((item) => {
                return {
                  value: item.value,
                  text: item.title,
                };
              });
            }
          });
          console.log("UserList ", this.state.userList);
        } else {
          message.error(res.msg);
        }
      },
      (error) => {
        console.log("failed!");
      }
    );
  };
  GetTeacherList = () => {
    UserApi.CetTeacherList({ name: this.state.searchKey }).then((res) => {
      console.log("this is dddddd-aaaaa", res);
      if (parseInt(res.data.code) === 1) {
        this.state.formData.forEach((ele) => {
          if (ele.name === "teacher_list") {
            ele.list = res.data.data.list.map((res) => {
              return {
                value: res.id,
                text: res.name,
              };
            });
          }
        });
        this.setState({
          formData: this.state.formData,
        });
        console.log(this.state.formData);
      }
    });
  };

  HandleCancel = () => {
    this.setState({
      isModalVisible: false,
    });
  };

  HandleIntention = (value) => {
    console.log("选择", value);
    this.setState({
      intention: `${value}`,
    });
  };

  HandleOk = () => {
    UserApi.FollowRecord({
      uid: this.state.userRecord.uid,
      purpose: this.state.intention,
      remark: this.state.remark,
    })
      .then((res) => {
        if (res.code === 1) {
          message.success("操作成功");
          this.setState({
            intention: null,
            remark: "",
            isModalVisible: false,
          });
          this.GetUserList();
        } else {
          message.error(res.msg);
        }
      })
      .catch((error) => {
        console.log(error.request.status);
      });
  };

  EditUser = (cord) => {
    console.log("记录", cord);
    this.setState({
      userRecord: cord,
      isModalVisible: true,
    });
  };
  AddRemark = (e) => {
    this.setState({
      remark: e.target.value,
    });
  };
  CopyPhone = (mobile) => {
    console.log("手机号", mobile);
    var number = document.createElement("input");
    document.body.appendChild(number);
    number.value = `${mobile}`;
    number.select();
    document.execCommand("copy");
    document.body.removeChild(number);
    message.success(`复制成功-->${mobile}`);
  };
  render() {
    const columns = [
      {
        title: "",
        width: 80,
        // dataIndex: "avatar",
        key: "a",
        render: (record) => (
          <Badge count={"wx"} offset={[0, 40]} style={{ display: record.qy_userid ? 'block' : 'none', backgroundColor: '#52c41a' }}>
          <img
            alt=""
            src={`${record.avatar}`}
            className="avatar"
            style={{ width: "40px", height: "40px", background: "#000" }}
          />
          </Badge>
        ),
      },
      {
        title: "用户名",
        width: 150,
        dataIndex: "nickname",
        key: "0",
      },
      {
        title: "手机",
        width: 150,
        dataIndex: "mobile",
        key: "123",
        render: (res) => {
          if (res) {
            let phone = res.split("");
            phone.splice(3, 5, "*****");
            return (
              <abbr title={res} style={{ textDecoration: "none" }}>
                {phone}
                <Button onClick={() => this.CopyPhone(res)}>复制</Button>
              </abbr>
            );
          } else {
            return (
              <abbr
                title={res}
                style={{ textDecoration: "none", textAlign: "center" }}
              >
                {"-"}
              </abbr>
            );
          }
        },
      },
      {
        title: "趣配音账号",
        dataIndex: "user_number",
        key: "2",
      },
      {
        title: "年龄",
        dataIndex: "age",
        key: "3",
      },
      {
        title: "学龄",
        dataIndex: "foundation",
        key: "4",
      },
      {
        title: "用户意向",
        dataIndex: "userPurposeText",
        key: "5",
      },
      {
        title: "课程等级",
        dataIndex: "levelText",
        key: "6",
      },
      {
        title: "完课数",
        dataIndex: "finish_course_count",
        key: "7",
      },
      {
        title: "老师",
        // dataIndex: "teacher",
        key: "8",
        render: (res) => {
          if (res.teacher !== "") {
            return (
              <abbr title={res} style={{ textDecoration: "none" }}>
                {res.teacher}
              </abbr>
            );
          } else {
            return (
              <abbr
                title={res}
                style={{ textDecoration: "none", textAlign: "center" }}
              >
                {"-"}
              </abbr>
            );
          }
        },
      },
      {
        title: "报名时间",
        dataIndex: "createTime",
        key: "9",
      },
      {
        title: "操作",
        dataIndex: "op",
        key: "10",
        render: (text, record) => (
          <Fragment>
            <Link to={`/user/Details/${record.uid}`}>
              {(this.state.allRuleList[1].show || this.state.isSuperAdmin ===1) ? (
                <Button
                  style={{ marginRight: "12px" }}
                  size="small"
                  type="primary"
                  onClick={this.GoDetail}
                >
                  详情
                </Button>
              ) : (
                <Button
                  style={{ marginRight: "12px" }}
                  size="small"
                  type="primary"
                  disabled
                  onClick={this.GoDetail}
                >
                  详情
                </Button>
              )}
            </Link>
            {(this.state.allRuleList[2].show || this.state.isSuperAdmin ===1) ? (
              <Button
                style={{ marginRight: "12px" }}
                size="small"
                type="primary"
                onClick={() => this.EditUser(record)}
              >
                跟进
              </Button>
            ) : (
              <Button
                style={{ marginRight: "12px" }}
                size="small"
                type="primary"
                disabled
                onClick={() => this.EditUser(record)}
              >
                跟进
              </Button>
            )}
            {/* <Button size="small" type="primary" onClick={this.EditUser}>绑定微信</Button> */}
          </Fragment>
        ),
      },
    ];
    let optionDataList = this.state.intentionList.map((items, indexs) => {
      return (
        <Option value={`${items.value}`} key={items.title}>
          {items.title}
        </Option>
      );
    });
    return (
      <div className="list-container">
        <HeaderSearch
          fromData={this.state.formData}
          onSearch={this.onSearch}
          onResetData={this.onResetData}
        ></HeaderSearch>
        <div className="box"></div>
        <Table
          dataSource={this.state.userList}
          columns={columns}
          pagination={
            this.state.total > 10
              ? {
                  position: ["bottomLeft"],
                  pageSize: this.state.pageSize,
                  total: this.state.total,
                  showQuickJumper: true,
                  pageSizeOptions: this.state.pageSizeOption,
                  onChange: (current, pageSize) => {
                    console.log(current, pageSize, (current - 1) * pageSize);
                    this.setState(
                      {
                        current: (current - 1) * pageSize,
                        pageSize: pageSize,
                      },
                      function () {
                        this.GetUserList();
                      }
                    );
                  },
                  onShowSizeChange: (current, size) => {
                    console.log(current, size);
                    this.setState(
                      {
                        current: current * size,
                        pageSize: size,
                      },
                      function () {
                        this.GetUserList();
                      }
                    );
                  },
                }
              : false
          }
        />

        <Modal
          title="添加跟进记录"
          visible={this.state.isModalVisible}
          onOk={this.HandleOk}
          onCancel={this.HandleCancel}
        >
          <div className="common-input-style">
            <div className="introudce">
              <span> * </span> 用户昵称:
            </div>
            {this.state.userRecord.nickname}
          </div>
          <div className="common-input-style">
            <div className="introudce">
              <span> * </span> 用户意向:
            </div>
            <Select
              style={{
                width: 120,
              }}
              value={this.state.intention}
              onChange={this.HandleIntention}
            >
              {optionDataList}
            </Select>
          </div>
          <div className="common-input-style">
            <div className="introudce">
              <span> </span> 备注:
            </div>
            <TextArea
              value={this.state.remark}
              onChange={(e) => this.AddRemark(e)}
              rows={4}
            />
          </div>
        </Modal>
      </div>
    );
  }
  onSearch(data) {
    console.log("d", data);
    // todo 搜索请求
    let mobile = "";
    let userNumber = "";
    let stu1 = "";
    let stu2 = "";
    let age1 = "";
    let age2 = "";
    let course1 = "";
    let course2 = "";
    let time1 = "";
    let time2 = "";
    let purpose = "";
    let levelId = "";
    let teacherId = "";
    if (data.mobile) {
      mobile = data.mobile;
    }
    if (data.id) {
      userNumber = data.id;
    }
    if (data.purpose) {
      console.log(data.purpose)
      if(data.purpose.length>1){
        let sid = data.purpose.slice(15,16)
        // console.log("sid",sid)
        purpose = sid;
      }else {
        purpose = data.purpose;
      }
    }
    if (data.level_list) {
      levelId = data.level_list;
    }
    if (data.teacher_list) {
      teacherId = data.teacher_list;
    }
    if (data.age_start) {
      age1 = data.age_start;
    }
    if (data.age_end) {
      age2 = data.age_end;
    }
    if (data.complete_course_start) {
      course1 = data.complete_course_start;
    }
    if (data.complete_course_end) {
      course2 = data.complete_course_end;
    }
    if (data.stu_start) {
      stu1 = data.stu_start;
    }
    if (data.stu_end) {
      stu2 = data.stu_end;
    }
    if (data.guide_time_start) {
      time1 = Math.round(data.guide_time_start.valueOf() / 1000);
    }
    if (data.guide_time_end) {
      time2 = Math.round(data.guide_time_end.valueOf() / 1000);
    }
    console.log(time1, time2);
    this.setState(
      {
        mobile: mobile,
        userNumber: userNumber,
        ageMin: age1,
        ageMax: age2,
        finishCourseCountMin: course1,
        finishCourseCountMax: course2,
        foundationMin: stu1,
        foundationMax: stu2,
        courseBeginTimeMin: time1,
        courseBeginTimeMax: time2,
        purpose: purpose,
        levelId: levelId,
        teacherId: teacherId,
      },
      function () {
        console.log(this.state.teacherId);
        this.GetUserList();
      }
    );
  }
  onResetData() {
    this.setState(
      {
        start: 0,
        rows: 10,
        nickname: "",
        mobile: "",
        userNumber: "",
        purpose: "",
        levelId: "",
        teacherId: "",
        ageMin: "",
        ageMax: "",
        finishCourseCountMin: "",
        finishCourseCountMax: "",
        foundationMin: "",
        foundationMax: "",
        courseBeginTimeMin: "",
        courseBeginTimeMax: "",
        isVip: 0,
      },
      function () {
        this.GetUserList();
      }
    );
  }
  GoDetail() {}
  searchTeacher() {
    console.log("输入中");
  }
}

export default UserGuideList;
