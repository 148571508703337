import React, { Component } from "react";
import { Tabs, message } from "antd";
import { Guide, Vip } from "./component/index";
import MenuApi from "../../../lib/api/menus/index";
const { TabPane } = Tabs;

require("./list.less");
class UserList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: this.props.match.params.type === "vip" ? "1" : "0",
      allRuleInfo: [],
      show1: false,
      show2: false,
      isSuperAdmin: 0,
      pageRuleList: [
        {
          name: "magicClass/tasteUserList",
          title: "体验用户",
          show: false,
        },
        {
          name: "magicClass/formalClassUser",
          title: "vip用户",
          show: false,
        },
      ],
    };
    this.TabsChange = this.TabsChange.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let activeIndex = this.props.match.params.type === "vip" ? "1" : "0";
    if (activeIndex !== prevState.activeIndex) {
      this.setState({
        activeIndex,
      });
    }
  }
  componentWillMount() {
    this.GetMenus();
  }
  GetMenus = () => {
    MenuApi.GetMenuList().then(
      (res) => {
        if (res) {
          if (res.code === 1) {
            this.setState({
              allRuleInfo: res.data.auth,
              isSuperAdmin: res.data.isSuperAdmin,
            });
            this.GetRuleList();
          } else {
            message.warning(res.msg);
          }
        }
      },
      (error) => {
        console.log("failed!");
      }
    );
  };
  GetRuleList = () => {
    let authList = this.state.allRuleInfo;
    let list = authList.map((item, index) => {
      return {
        name: item.name,
        title: item.title,
        id: item.id,
      };
    });
    this.GetBtnStatus(list);
  };

  //获取按钮显示隐藏
  GetBtnStatus = (list) => {
    let allRule = this.state.pageRuleList;
    list.forEach((item) => {
      allRule.map((items) => {
        if (item.name === items.name) {
          items.show = true;
        }
        return {
          ...items,
        };
      });
    this.setState({
      show1: allRule[0].show,
      show2: allRule[1].show
    })
    // let show1 = allRule[0].show;
    // let show2 = allRule[1].show;
    });
    // console.log("this.state.pageRuleList", this.state.pageRuleList);
  };
  //tab切换
  TabsChange(index) {
    this.setState({
      activeIndex: index,
    });
    if (index === "0") {
      this.props.history.push({
        pathname: `/user/list/experience`,
      });
    } else {
      this.props.history.push({
        pathname: `/user/list/vip`,
      });
    }
  }
  render() {
    console.log(this.state.pageRuleList);
    console.log("体验",this.state.show1,"vip",this.state.show2)
    let show1 = this.state.show1;
    let show2 = this.state.show2;
    let divContent = "";
    if (show1 && !show2) {
      divContent = (
        <Tabs activeKey={this.state.activeIndex} onChange={this.TabsChange}>
          <TabPane tab="体验用户" key="0">
            <Guide activeIndex={this.state.activeIndex} />
          </TabPane>
        </Tabs>
      );
    }
    if(!show1 && show2) {
      divContent = (
        <Tabs activeKey={this.state.activeIndex} onChange={this.TabsChange}>
          <TabPane tab="vip用户" key="1">
            <Vip activeIndex={this.state.activeIndex} />
          </TabPane>
        </Tabs>
      );
    }
    if(show1&&show2){
      divContent = (
        <Tabs activeKey={this.state.activeIndex} onChange={this.TabsChange}>
          <TabPane tab="体验用户" key="0">
            <Guide activeIndex={this.state.activeIndex} />
          </TabPane>
          <TabPane tab="vip用户" key="1">
            <Vip activeIndex={this.state.activeIndex} />
          </TabPane>
        </Tabs>
      );
    }
    return (
      <div className="main-page">
        {/* <Tabs activeKey={this.state.activeIndex} onChange={this.TabsChange}>
          <TabPane tab="体验用户" key="0">
            <Guide activeIndex={this.state.activeIndex} />
          </TabPane>
          <TabPane tab="vip用户" key="1">
            <Vip activeIndex={this.state.activeIndex} />
          </TabPane>
        </Tabs> */}
        {divContent}
      </div>
    );
  }
}
export default UserList;
