import request from '../../request';


/**
 * 获取权限组列表---Get请求
 */
const GetGroupLists = () => {
    return request
        .get(`/auth/groupLists`, {
            params: {}
        })
        .then(result => {
            return result.data
        })
}

/**
 * 编辑权限---post请求
 */
const EditRoleGroup = ({
    id,
    pid,
    name,
    dataAuth
}) => {
    return request
        .post(`/auth/updateAuthGroup`, {
            id,
            pid,
            name,
            dataAuth
        })
        .then(response => {
            return response.data
        })
}

/**
 * 添加权限角色---post请求
 */
const AddRoleGroup = ({
    pid,
    name,
    dataAuth
}) => {
    return request
        .post(`/auth/groupAdd`, {
            pid,
            name,
            dataAuth
        },)
        .then(response => {
            return response.data
        })
}

/**
 * 改变权限角色状态---post请求
 */
const ChangeRoleGroup = ({
    id,
    type,
}) => {
    return request
        .post(`/auth/groupStatusMod`, {
            id,
            type,
        },)
        .then(response => {
            return response.data
        })
}

/**
 * 改变权限角色--菜单权限---post请求
 */
const ChangeRoleRule = ({
    id,
    rules,
}) => {
    return request
        .post(`/auth/updateAuthGroupRule`, {
            id,
            rules,
        },)
        .then(response => {
            return response.data
        })
}

// eslint-disable-next-line
export default {
    GetGroupLists,
    EditRoleGroup,
    AddRoleGroup,
    ChangeRoleGroup,
    ChangeRoleRule
}