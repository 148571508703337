import React, { Component } from "react";
import { Table, Tag, message } from "antd";
import DataApi from "../../lib/api/workBench/index";
require("./index.less");
class WorkBench extends Component {
  componentWillMount () {
    this.GetData();
  }
  state = {
    role: 'B',
    userInfo: {},
    rankList: [],
    allInfo: {
      monthTarget: 0
    },
    unGoClassNum: null, //未上课人数
  };
  GetData =()=> {
    DataApi.GetWorkData().then(
      (res) => {
        console.log(res);
        if (res.code === 1) {
          let data = res.data
          this.setState({
            role: data.user.type,
            userInfo: data.user,
            rankList: data.rankList.map((item,index)=>{
              return {
                ...item,
                index: index+1
              }
            }),
            allInfo: data,
            unGoClassNum: (data.applyUserNum-data.applyUserLearnNum)
          })
          
          console.log((this.state.allInfo.applyUserNum) - (this.state.allInfo.applyUserLearnNum))
        } else {
          message.error(res.msg);
        }
      },
      (error) => {
        console.log("failed!");
      }
    );
  }
  render() {
    //售前
    const BeforeColumns = [
      {
        title: "排名",
        dataIndex: "index",
        key: "1",
      },
      {
        title: "花名",
        dataIndex: "name",
        key: "2",
      },
      {
        title: "订单数",
        dataIndex: "total_num",
        key: "3",
      },
      {
        title: "业绩",
        dataIndex: "total_amount",
        key: "4",
      },
    ];
    //售后
    const AfterColumns = [
      {
        title: "排名",
        dataIndex: "index",
        key: "5",
      },
      {
        title: "花名",
        dataIndex: "name",
        key: "6",
      },
      // {
      //   title: "人效",
      //   dataIndex: "7",
      //   key: "age",
      // },
      {
        title: "单数",
        dataIndex: "total_num",
        key: "8",
      },
      {
        title: "业绩",
        dataIndex: "total_amount",
        key: "9",
      },
    ];
    // const LeftTable = (
    //   <div className="class-table">
    //     <div className="title-container">
    //       <div>近期班级</div>
    //       <div>全部班级</div>
    //     </div>
    //     <Table columns={columns} pagination={false} dataSource={data} />
    //   </div>
    // );
    const BeforeSale = (
      <div className="add-wx-flex">
        <div className="common-box">
          <p className="p1">近七天报名</p>
          <p className="p2">
            {this.state.allInfo.applyUserNum} <span>人</span>
          </p>
          <p className="p3">
            已上课 <span>{this.state.allInfo.applyUserLearnNum}</span>|未上课 <span>{this.state.unGoClassNum}</span>
          </p>
        </div>
      </div>
    );
    const AfterSale = (
      <div className="after-sale">
        <div className="common-box">
          <p className="p1">最近上课未达到10节</p>
          <p className="p2">{this.state.allInfo.subUserNum}人</p>
        </div>
        <div className="common-box">
          <p className="p1">剩余课节不足30节</p>
          <p className="p2">{this.state.allInfo.noFinishUserNum}人</p>
        </div>
      </div>
    );
    return (
      <div className="home-page">
        <div className="userInfo-top">
          <div className="userInfo">
            <p className="p1">{this.state.userInfo.name}</p>
            <p className="p2">{this.state.userInfo.department_name}</p>
          </div>
          <div className="line"></div>
          <div className="userInfo">
            <p className="p2">本月成交排名</p>
            <p className="p1">
              {this.state.allInfo.userRank} <span>/{this.state.allInfo.totalRankNum}</span>
            </p>
          </div>
          <div className="line"></div>
          <div className="userInfo">
            <p className="p2">销售目标完成率</p>
            <p className="p1">{this.state.allInfo.monthTarget === 0 ? "0" : (this.state.allInfo.monthTotalAmount)*100/(this.state.allInfo.monthTarget)}%</p>
            {/* <p className="p1">{(95)*100/(100)}%</p> */}
          </div>
          <div className="line"></div>
          <div className="userInfo">
            <p className="p2">本月成交金额</p>
            <p className="p1">
              {this.state.allInfo.monthTotalAmount} <span>元</span>
            </p>
          </div>
          <div className="line"></div>
          <div className="userInfo">
            <p className="p2">本月成交单数</p>
            <p className="p1">
              {this.state.allInfo.monthTotalNum} <span>单</span>
            </p>
          </div>
        </div>
        <div className="flex-container">
          <div className="left-container">
            {(this.state.role === "B" || this.state.role === "N") ? BeforeSale : AfterSale}
            {/* {this.state.role === 1 ? LeftTable : ""} */}
          </div>
          <div style={{ width: "2%" }}></div>
          <div className="right-container">
            <div className="title-container">
              <div>排行榜</div>
            </div>
            {(this.state.role === "B" || this.state.role === "N") ? (
              <Table
                columns={BeforeColumns}
                size="small"
                scroll={{ y: 520 }}
                pagination={false}
                dataSource={this.state.rankList}
              />
            ) : (
              <Table
                columns={AfterColumns}
                size="small"
                pagination={false}
                dataSource={this.state.rankList}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default WorkBench;
