import React, { Component, useEffect, useState } from "react";
import {
  Menu,
  Card,
  Row,
  Col,
  Statistic,
  DatePicker,
  Select,
  Button,
  Popover,
  Table,
} from "antd";
import moment from "moment";
import { InfoCircleOutlined } from "@ant-design/icons";
import dataApi from "../../../lib/api/data/index.js";
import GroupBoard from "./component/group";
import StaffBoard from "./component/staff";
const { Option } = Select;
const { RangePicker } = DatePicker;
const content1 = (
    <div>
      <p>本月销售所有订单金额总和</p>
    </div>
  );
  const content2 = (
    <div>
      <p>本月销售所有订单总量</p>
    </div>
  );
  const content3 = (
    <div>
      <p>昨日销售所有订单总金额</p>
    </div>
  );
require("./index.less");

let t = moment().startOf("month");
console.log(t, new Date(t).getTime());

class Board extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startTime: moment().startOf("month"),
      endTime: moment().local("month"),
      groupId: "",
      uid: "",
      monthSaleTotal: "",
      monthOrderTotal: "",
      yesterdaySaleTotal: "",
      saleUserTotal: 0,
      saleGroupLists: [],
      teacherLists: [],
      selectedSaleGroupList: [],
      selectedTeacherList: [],
      list: [],
      size: "default",
      current: "0",
    };
  }
  UNSAFE_componentWillMount() {
    this.GetData();
  }
  GetData = () => {
    this.setState({
      list: [],
    });
    // 获取表格数据
    let startTime = "";
    let endTime = "";

    if ("Invalid Date" !== new Date(this.state.startTime)) {
      startTime = parseInt(Math.ceil(new Date(this.state.startTime).getTime() / 1000));
    }
    if ("Invalid Date" !== new Date(this.state.endTime)) {
      endTime = parseInt(Math.ceil(new Date(this.state.endTime).getTime() / 1000));
    }

    dataApi
      .GetDataBoard({
        startTime: startTime,
        endTime: endTime,
        type: this.state.current,
        groupIds: this.state.selectedSaleGroupList.join(","),
        uids: this.state.selectedTeacherList.join(","),
      })
      .then((res) => {
        console.log(res);
        if (res.code === 1) {
          this.setState({
            monthOrderTotal: res.data.monthOrderTotal?res.data.monthOrderTotal:0,
            monthSaleTotal: res.data.monthSaleTotal?res.data.monthSaleTotal:0,
            yesterdaySaleTotal: res.data.yesterdaySaleTotal,
            saleUserTotal: res.data.saleUserTotal,
            list: res.data.saleRank ? res.data.saleRank : [],
          });
        }
      });

    // 获取下拉数据
    dataApi.GetDataBoardSelectPage().then((res) => {
      console.log("GetDataBoardSelectPage", res);
      if (res.code === 1) {
        this.setState({
          saleGroupLists: res.data.saleGroupLists,
          teacherLists: res.data.teacherLists,
        });
      }
    });
  };
  HandleChangeSaleGroupList = (res) => {
    this.setState({
      selectedSaleGroupList: res,
    });
  };
  HandleChangeTeacherList = (res) => {
    this.setState({
      selectedTeacherList: res,
    });
  };
  DatePickerChange = (dates, dateStrings) => {
    console.log(dates, dateStrings);
    this.setState({
      startTime: dateStrings[0],
      endTime: dateStrings[1],
    });
  };
  HandleClick = (res) => {
    console.log(res.key, this.state.current);
    if (res.key !== this.state.current) {
      this.setState(
        {
          current: res.key,
        },
        () => {
          this.GetData();
        }
      );
    }
  };
  render = () => (
    <div className="data-page board">
      <Row gutter={30}>
        <Col span={6}>
          <Card className="middle">
            <div className="header flex justfiy-content-betweent">
              <Statistic
                title="本月总销售额"
                value={this.state.monthSaleTotal}
              ></Statistic>
              <Popover content={content1} title="Title">
              <InfoCircleOutlined
                style={{ color: "rgba(0, 0, 0, 0.45)", lineHeight: "25px" }}
              ></InfoCircleOutlined>
              </Popover>
            </div>
            <div className="content">
              <p>
                人均销售额
                {this.state.saleUserTotal !== 0
                  ? (
                      this.state.monthSaleTotal / this.state.saleUserTotal
                    ).toFixed(2)
                  : ""}
              </p>
            </div>
          </Card>
        </Col>
        <Col span={6}>
          <Card className="middle">
            <div className="header flex justfiy-content-betweent">
              <Statistic
                title="本月订单量"
                value={this.state.monthOrderTotal}
              ></Statistic>
              <Popover content={content2} title="Title">
              <InfoCircleOutlined
                style={{ color: "rgba(0, 0, 0, 0.45)", lineHeight: "25px" }}
              ></InfoCircleOutlined>
              </Popover>
            </div>
            <div className="content">
              <p>
                人均订单数额
                {this.state.saleUserTotal !== 0
                  ? (
                      this.state.monthOrderTotal / this.state.saleUserTotal
                    ).toFixed(2)
                  : ""}
              </p>
            </div>
          </Card>
        </Col>
        {/* <Col span={6}>
                <Card className="middle">
                    <div className="header flex justfiy-content-betweent">
                        <Statistic title="本月人效比" value={112893}></Statistic>
                        <InfoCircleOutlined style={{ color: 'rgba(0, 0, 0, 0.45)', lineHeight: '25px' }}></InfoCircleOutlined>

                    </div>
                    <div className="content opacity-0">
                        <p>人均销售额  12000</p>
                    </div>
                </Card>
            </Col> */}
        <Col span={6}>
          <Card className="middle">
            <div className="header flex justfiy-content-betweent">
              <Statistic
                title="昨日营收"
                value={this.state.yesterdaySaleTotal}
              ></Statistic>
              <Popover content={content3} title="Title">
              <InfoCircleOutlined
                style={{ color: "rgba(0, 0, 0, 0.45)", lineHeight: "25px" }}
              ></InfoCircleOutlined>
              </Popover>
            </div>
            <div className="content opacity-0">
              <p> &nbsp; </p>
            </div>
          </Card>
        </Col>
      </Row>
      <div className="content-box">
        <Row>
          <Col>
            <div className="flex item">
              <label>查询时间</label>
              <RangePicker
                onChange={this.DatePickerChange}
                size="small"
                defaultValue={[
                  moment().startOf("month"),
                  moment().local("month"),
                ]}
                ranges={{
                  今天: [moment(), moment()],
                  本月: [moment().startOf("month"), moment().endOf("month")],
                }}
              ></RangePicker>
            </div>
          </Col>
          <Col
            style={{ display: this.state.current === "1" ? "block" : "none" }}
          >
            <div className="flex item">
              <label>销售小组</label>
              <Select
                mode="multiple"
                placeholder="请选择小组"
                // value={this.state.selectedSaleGroupList}
                defaultValue={[]}
                onChange={this.HandleChangeSaleGroupList}
                style={{ width: "100%" }}
              >
                {this.state.saleGroupLists.map((item, index) => {
                  return (
                    <Option value={item.id} key={item.id + "" + index}>
                      {item.groupName}
                    </Option>
                  );
                })}
              </Select>
            </div>
          </Col>
          <Col
            style={{ display: this.state.current === "0" ? "block" : "none" }}
          >
            <div className="flex item">
              <label>销售</label>
              <Select
                mode="multiple"
                placeholder="请选择销售"
                // value={this.state.selectedTeacherList}
                defaultValue={[]}
                onChange={this.HandleChangeTeacherList}
                maxTagCount={3}
                style={{ width: "100%" }}
              >
                {this.state.teacherLists.map((item, index) => {
                  return (
                    <Option value={item.uid} key={item.uid + "" + index}>
                      {item.teacherName}
                    </Option>
                  );
                })}
              </Select>
            </div>
          </Col>

          <Col>
            <Button type={"primary"} onClick={this.GetData}>
              搜索
            </Button>
          </Col>
        </Row>
        <Menu
          onClick={this.HandleClick}
          selectedKeys={this.state.current}
          mode="horizontal"
          style={{ marginTop: "10px" }}
        >
          <Menu.Item key="0">员工看板</Menu.Item>
          <Menu.Item key="1">小组看板</Menu.Item>
        </Menu>
        {this.state.current === "0" ? (
          <StaffBoard dataSource={this.state.list}></StaffBoard>
        ) : (
          <GroupBoard dataSource={this.state.list}></GroupBoard>
        )}
      </div>
    </div>
  );
}
export default Board;
