import request from '../../request';


/**
 * 获取菜单列表---Get请求
 */
const GetMenuList = () => {
    return request
        .get(`/user/userAuth`, {
            params: {}
        })
        .then(result => {
            return result.data
        })
}

/**
 * 新增菜单---post请求
 */
const AddMenus = ({
    name,
    title,
    pid,
    isMenu,
    status,
    weigh
}) => {
    return request
        .post(`/auth/ruleAdd`, {
            name,
            title,
            pid,
            isMenu,
            status,
            weigh
        })
        .then(response => {
            return response.data
        })
}


/**
 * 新增菜单---post请求
 */
const UpdataMenus = ({
    id,
    type,
}) => {
    return request
        .post(`/auth/ruleStatusMod`, {
            id,
            type,
        })
        .then(response => {
            return response.data
        })
}

/**
 * 新增权限---get请求
 */
const GetMenusRule = ({
    id,
}) => {
    return request
        .get(`/auth/menuRule`, {
            params: {
                menuId: id,
            }
        })
        .then(result => {
            return result.data
        })
}



// eslint-disable-next-line
export default {
    GetMenuList,
    AddMenus,
    UpdataMenus,
    GetMenusRule
}