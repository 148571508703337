import request from '../../request';
const HOST = window.location.href.indexOf("https://crm.qsongxue.com") !== -1 ?
    "https://parrot.qupeiyin.cn" :
    "https://parrottest.qupeiyin.cn";


/**
 * 登录账号---post请求
 */
function Login({
    email,
    password
}) {
    return request
        .post(`${HOST}/api/login`, {
            email,
            password
        })
        .then(response => {
            return response.data
        })
}

/**
 * 登出账号---post请求
 */
function LoginOut({
    token,
}) {
    return request
        .post(`${HOST}/api/logout`, {
            token,
        })
        .then(response => {
            return response.data
        })
}

// eslint-disable-next-line
export default {
    Login,
    LoginOut
}