import request from '../../request';
/**
 * 体验班级列表
 */
const GetClassList = ({
    className,
    classStatus,
    courseStatus,
    studyStartTimeMin,
    studyStartTimeMax,
    saleTimeMin,
    saleTimeMax,
    start,
    rows,
}) => {
    return request
        .get(`/magicClass/tasteClass`, {
            params: {
                className,
                classStatus,
                courseStatus,
                studyStartTimeMin,
                studyStartTimeMax,
                saleTimeMin,
                saleTimeMax,
                start,
                rows,
            }
        })
        .then(result => {
            return result.data
        })
}

/**
 * 体验列表
 */
const GetClassTeacherList = () => {
    return request
        .get(`enterpriseWechat/bindTeacherAndChatSelect`, {
            params: {}
        })
        .then(result => {
            return result.data
        })
}

/**
 * 绑定---post请求
 */
const BindTeacherClass = ({
    classId,
    chatId,
    qyUserId
}) => {
    return request
        .post(`enterpriseWechat/bindTeacherAndChat`, {
            classId,
            chatId,
            qyUserId
        })
        .then(response => {
            return response.data
        })
}

// eslint-disable-next-line
export default {
    GetClassList,
    GetClassTeacherList,
    BindTeacherClass
}