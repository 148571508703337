import React, { Component } from "react";
import {
  message,
  Button,
  Space,
  Input,
  Upload,
  Table,
  Tree,
  Modal,
  Form,
  Select,
} from "antd";
import {
  LoadingOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  TeacherList,
  TeacherAdd,
  TeacherUpdate,
  TeacherUpdateAll,
  BindUser,
} from "@/lib/api/systemSet/teacherManage.js";
import api from "../../../lib/api/menus/index.js";
import ClassApi from "../../../lib/api/class/exClass";
import { GroupListSale } from "@/lib/api/systemSet/staffManage.js";
import {
  GetAllteacher,
  BindTeacher,
} from "@/lib/api/systemSet/teacherManage.js";
import utilsTool from "../../../lib/qiniu";
import TagApi from "../../../lib/api/tag/index";
import WeixinUpload from "../../../lib/api/weixin";
const TreeNode = Tree.TreeNode;
const Search = Input.Search;
const { TextArea } = Input;
const { Option } = Select;
require("./index.less");
const FILETYPELIST = ["image/jpeg", "image/png", "image/jpg"];
const RULESNUM = [
  {
    validator: (rule, value, callback) => {
      if (!/^[0-9]\d*(\.\d+)?$/.test(value)) {
        callback("填写内容必须是大于等于0的数字");
      } else {
        callback();
      }
    },
  },
];
const RULESURL = [
  {
    validator: (rules, value, callback) => {
      if (!/(http|https)\S*/.test(value)) {
        callback("url地址必须以https或者http开头");
      } else {
        callback();
      }
    },
  },
];

class TeacherManage extends Component {
  UNSAFE_componentWillMount() {
    this.GetTeaacherClassList();
    this.GetGroupList(1);
    this.GetRootList();
    this.GetAllTeacherList();
    // this.GetRequestUrl();
    this.GetActionTagInfo();
  }

  GetActionTagInfo = () => {
    TagApi.GetActionTag()
      .then((res) => {
        this.setState(
          {
            allTagLists: res.data.tagLists,
          },
          function () {
            console.log("action", this.state.allTagLists);
          }
        );
      })
      .catch((res) => {
        console.log("error");
      });
  };
  GetRequestUrl = () => {
    WeixinUpload.GetRequestUrl()
      .then((res) => {
        console.log("上传url", res);
        this.setState({
          uploadUrl: res.data.url,
        });
      })
      .catch((res) => {
        console.log("error");
      });
  };
  //获取组织架构
  GetGroupList = (data_type) => {
    GroupListSale(data_type).then((res) => {
      if (parseInt(res.data.code) === 1) {
        if (data_type === 1) {
          this.setState({
            gData: res.data.data,
            selectId: ["0"],
            dataList: this.ReduceBydeep(
              JSON.parse(JSON.stringify(res.data.data))
            ),
          });
          this.GetTeacherList({ group_id: "" });
        } else {
          this.setState({
            selectData: res.data.data,
          });
        }
      }
    });
  };
  GetTeaacherClassList = () => {
    ClassApi.GetClassTeacherList({}).then(
      (res) => {
        if (res.code === 1) {
          console.log("裂开", res);
          this.setState({
            teacherLists: res.data.teacherLists,
          });
        } else {
          message.error(res.msg);
        }
      },
      (error) => {
        console.log("failed!");
      }
    );
  };

  GetAllTeacherList = () => {
    GetAllteacher().then((res) => {
      if (parseInt(res.data.code) === 1) {
        this.setState({
          teacherUserList: res.data.data.qyUserLists,
        });
      }
    });
  };

  ReduceBydeep = (data) => {
    if (data && data.length > 0) {
      return data.map((ele) => {
        if (ele.child) {
          ele.child = this.ReduceBydeep(ele.child);
        }
        return {
          title: ele.name,
          name: ele.name,
          key: ele.id,
          pid: ele.pid,
          children: ele.child ? ele.child : [],
        };
      });
    }
  };
  state = {
    uploadUrl: "", //上传图片请求的URL
    qrcodeLink: "", //二维码链接
    addUserSetModal: false, //加好友设置对话框
    loading: false,
    bindUserId: "",
    bindTeacherUid: "",
    bindTeacherModal: false,
    teacherUserList: [],
    editStaffModal: false,
    editMoreModal: false, //批量修改目标
    dataList: [],
    expandedKeys: ["0"],
    searchValue: "",
    autoExpandParent: true,
    gData: [],
    addStaffModal: false,
    dataSource: [],
    rowId: {
      uid: null,
      id: null,
    },
    children: [],
    selectId: null,
    defaultSelect: null,
    staffList: [],
    rootRequestList: {
      editTeacher: {
        name: "teacher/update",
        isCanUse: false,
      },
      addTeacher: {
        name: "teacher/add",
        isCanUse: false,
      },
      editAll: {
        name: "teacher/allude",
        isCanUse: false,
      },
      setWords: {
        name: "enterpriseWechat/addFriendConfig",
        isCanUse: false,
      },
      uploadWeChatImg: {
        name: "enterpriseWechat/uploadWeChatImg",
        isCanUse: false,
      },
    },
    //加好友设置
    allTagLists: [
      { id: 0, name: "标签一" },
      { id: 1, name: "标签二" },
      { id: 2, name: "标签三" },
    ],
    teacherLists: [], //企业微信老师列表
    selectedActTagList: [],
    addSetMsg: "",
    token: "wwsadawfafss", //微信上传token
    seleceSetData: {}, //加好友设置选中的
    wxPicUrl: "",
  };

  OnSelect = (selectedKeys, info, extra) => {
    /*用于打开该节点的详细信息*/
    if (selectedKeys.length > 0) {
      this.setState(
        {
          selectId: selectedKeys,
        },
        function () {
          console.log(
            "selectedKeys",
            selectedKeys,
            "selectId",
            this.state.selectId
          );
          this.GetTeacherList({ group_id: selectedKeys[0] });
        }
      );
    } else {
    }
  };

  OnExpand = (expandedKeys) => {
    this.setState({
      expandedKeys,
      autoExpandParent: false,
    });
  };
  //编辑老师 图片上传
  EditUpload = (file) => {
    let that = this;
    if (file.size <= 2048000 && FILETYPELIST.indexOf(file.type) !== -1) {
      utilsTool.UploadPic({
        file,
        name: `${new Date().getTime()}-${Math.floor(
          Math.random() * (1000 - 0) + 0
        )}.jpg`,
        success: (res) => {
          console.log("上传", res);
          that.setState({
            imageUrl: res.url,
          });
          console.log(that.refs.editForm);
          that.refs.editForm.setFieldsValue({
            wx_pic: res.fileName,
          });
        },
        fail: (err) => {
          console.log(err);
        },
      });
    } else {
      that.setState({
        imageUrl: "",
      });
      message.error("文件大小不得超过2M且文件格式且必须为png,jpg,jpeg");
    }
  };
  OpenKeysSelect = (data, value) => {
    let keyList = [];
    data.forEach((ele) => {
      if (ele.children.length > 0) {
        keyList = [...keyList, ...this.OpenKeysSelect(ele.children, value)];
      }
      if (ele.title.toLowerCase().indexOf(value.toLowerCase()) !== -1) {
        keyList.push(ele.pid);
      }
    });
    keyList = keyList.map((res) => res.toString());
    return keyList;
  };
  //树搜索框输入数据时查询并展开节点
  OnChange = (e) => {
    const value = e.target.value;
    this.setState({
      searchValue: value,
      autoExpandParent: true,
    });
    if (value.length > 0) {
      this.setState({
        expandedKeys: Array.from(
          new Set(this.OpenKeysSelect(this.state.dataList, value))
        ),
      });
    } else {
      this.setState({
        expandedKeys: ["0"],
      });
    }
  };

  Loop = (data) =>
    data.map((item) => {
      let { searchValue } = this.state;
      const index = item.name.indexOf(searchValue);
      const beforeStr = item.name.substr(0, index);
      const afterStr = item.name.substr(index + searchValue.length);
      const title =
        index > -1 ? (
          <div className="tree--label">
            {beforeStr}
            <span style={{ color: "#f50" }}>{searchValue}</span>
            {afterStr}
          </div>
        ) : (
          <span className="tree--label">{item.name}</span>
        );

      if (item.child) {
        return (
          <TreeNode key={item.id} title={title} dataRef={item}>
            {this.Loop(item.child)}
          </TreeNode>
        );
      }
      return <TreeNode dataRef={item} key={item.id} title={title} />;
    });
  //获取老师列表
  GetTeacherList(data) {
    console.log("groupId", data);
    TeacherList(data).then((res) => {
      if (parseInt(res.data.code) === 1) {
        res.data.data = res.data.data.map((ele, index) => {
          ele.wxName = "";
          if (ele.qy_userid) {
            // eslint-disable-next-line array-callback-return
            this.state.teacherLists.map((items) => {
              if (ele.qy_userid === items.userid) {
                ele.wxName = items.name;
              }
            });
          } else {
            ele.wxName = "-";
          }
          return {
            ...ele,
            ...{
              key: index,
            },
          };
        });
        this.setState(
          {
            dataSource: res.data.data,
          },
          function () {
            console.log("dataSource", this.state.dataSource);
          }
        );
      }
    });
  }
  //编辑老师
  EditorTeacher = () => {
    this.refs.editForm
      .validateFields()
      .then((res) => {
        TeacherUpdate({ ...res, ...this.state.rowId }).then((res) => {
          if (parseInt(res.data.code) === 1) {
            message.success("修改成功");
            this.setState({
              editStaffModal: false,
            });
            this.GetTeacherList({
              group_id: this.state.selectId[0],
            });
          } else {
            message.error(res.data.msg);
          }
        });
      })
      .catch((res) => {
        console.log(res);
      });
  };
  //添加老师
  AddTeacher = () => {
    this.refs.addForm.validateFields().then((res) => {
      let obj = JSON.parse(JSON.stringify(res));
      this.state.staffList.forEach((ele) => {
        if (ele.uid === obj.uid) {
          obj.name = ele.name;
        }
      });
      obj.groupId = this.state.selectId[0];
      TeacherAdd(obj).then((res) => {
        if (res.data.code === 1) {
          message.success(res.data.msg);
          this.setState({
            addStaffModal: false,
          });
          this.GetTeacherList({
            group_id: this.state.selectId,
          });
          this.refs.addForm.resetFields();
          this.setState({
            imageUrl: "",
          });
        } else {
          message.error(res.data.msg);
        }
      });
    });
  };
  //批量让修改
  TeacherUpdateAll = () => {
    this.refs.editAll.validateFields().then((res) => {
      let data = JSON.parse(JSON.stringify(res));
      data.id = data.id.join(",");
      TeacherUpdateAll(data).then((res) => {
        if (res.data.code === 1) {
          message.success("修改成功");
          this.setState({
            editMoreModal: false,
          });
          this.GetTeacherList({
            group_id: this.state.selectId,
          });
        } else {
          message.error(res.data.msg);
        }
      });
    });
  };

  HandleAddSet = () => {
    console.log(this.state.seleceSetData.id);
    WeixinUpload.UploadWxPic({
      id: this.state.seleceSetData.id,
      tagIds: this.state.selectedActTagList.join(","),
      msgContent: this.state.addSetMsg,
      msgImg: this.state.wxPicUrl,
    }).then((res) => {
      if (res.code === 1) {
        message.success("操作成功");
        this.setState({
          addSetMsg: "",
          selectedActTagList: [],
          wxPicUrl: "",
          addUserSetModal: false,
        });
        this.GetTeacherList({
          group_id: "0",
        });
      } else {
        message.error(res.msg);
      }
    });
  };
  //关闭弹窗，重置表单
  HandleCancel = () => {
    this.setState({
      editStaffModal: false,
      editMoreModal: false,
      addStaffModal: false,
      bindTeacherModal: false,
      addUserSetModal: false,
    });
    if (this.refs.editForm) {
      this.refs.editForm.resetFields();
    }
    if (this.refs.addForm) {
      this.refs.addForm.resetFields();
    }
    if (this.refs.editAll) {
      this.refs.editAll.resetFields();
    }
    this.setState({
      imageUrl: "",
    });
  };
  //打开编辑对话框
  ShowEditor = (data) => {
    console.log("被编辑的信息", data);
    // eslint-disable-next-line
    this.state.rowId.id = data.id;
    // eslint-disable-next-line
    this.state.rowId.uid = data.uid;
    this.setState({
      editStaffModal: true,
      rowId: this.state.rowId,
    });
    let timmer = setTimeout(() => {
      this.refs.editForm.resetFields();
      this.refs.editForm.setFieldsValue(data);
      this.setState({
        imageUrl: `https://peiyinimg.qupeiyin.cn/${data.wx_pic}`,
      });
      clearTimeout(timmer);
    }, 10);
  };
  //加好友设置对话框
  ShowAddSet = (data) => {
    console.log(data);
    let tagList = [];
    if (data.tag === "") {
      tagList = [];
    } else {
      tagList = data.tag.split(",");
    }
    this.setState({
      seleceSetData: data,
      selectedActTagList: tagList,
      addSetMsg: data.add_friend_msg_content,
      wxPicUrl: data.add_friend_img,
      addUserSetModal: true,
    });
  };
  HandleChangeTagList = (res) => {
    this.setState(
      {
        selectedActTagList: res,
      },
      function () {
        console.log(this.state.selectedActTagList);
      }
    );
  };
  //输入框输入绑定
  ChangeName = (e) => {
    this.setState({
      addSetMsg: e.target.value,
    });
  };
  //微信上传
  WxUpload = (file) => {
    console.log("文件", file.type);
    if (file.size <= 2048000 && FILETYPELIST.indexOf(file.type) !== -1) {
      var reader = new FileReader();
      reader.onload = function (e) {
        // console.log(reader.result); //或者 e.target.result都是一样的，都是base64码
      };
      reader.readAsDataURL(file);
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("token")}`
      );
      var formdata = new FormData();
      formdata.append("file", file, `${file.name}`);
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };
      fetch(
        `https://dumb-crm.qsongxue.com/enterpriseWechat/uploadWeChatImg`,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          let data = JSON.parse(result);
          if (data.code === 1) {
            console.log("提交成功", data.data.url);
            this.setState({
              wxPicUrl: data.data.url,
            });
          } else {
            message.error(result.msg);
          }
        })
        .catch((error) => console.log("error", error));
    } else {
      message.error("文件大小不得超过2M且文件格式且必须为png,jpg,jpeg");
    }
  };

  HandleImageChange = (e) => {
    //处理图片
    const file = e.target.files[0];
    console.log(e, file);
  };

  //打开对话框
  ShowBindTeacher = (data) => {
    this.setState({
      bindTeacherModal: true,
      bindUserId: data.id,
    });
  };
  //select组件绑定用户uid
  HandleUserUid = (value) => {
    this.setState({
      bindTeacherUid: `${value}`,
    });
  };

  BindTeacherUid = () => {
    BindTeacher({
      id: this.state.bindUserId,
      uid: this.state.bindTeacherUid,
    }).then((res) => {
      if (res.data.code === 1) {
        message.success("操作成功");
        this.setState({
          bindTeacherModal: false,
          bindUserId: "",
          bindTeacherUid: "",
        });
      } else {
        message.error(res.msg);
      }
    });
  };
  // 添加老师 图片上传
  AddUpload = (file) => {
    let that = this;
    if (file.size <= 2048000 && FILETYPELIST.indexOf(file.type) !== -1) {
      utilsTool.UploadPic({
        file,
        name: `${new Date().getTime()}-${Math.floor(
          Math.random() * (1000 - 0) + 0
        )}.jpg`,
        success: (res) => {
          that.setState({
            imageUrl: res.url,
          });
          let timmer = setTimeout(() => {
            that.refs.addForm.setFieldsValue({
              wx_pic: res.fileName,
            });
            clearTimeout(timmer);
          }, 100);
        },
        fail: (err) => {
          console.log(err);
          message.error("图片上传失败");
        },
      });
    } else {
      that.setState({
        imageUrl: "",
      });
      message.error("文件大小不得超过2M且文件格式且必须为png,jpg,jpeg");
    }
  };
  
  UpdataAll = () => {
    if (this.state.children.length > 0) {
      this.setState({
        editMoreModal: true,
      });
      let timmer = setTimeout(() => {
        let array = this.state.children.map((ele) => ele.id);
        this.refs.editAll.setFieldsValue({
          id: array,
        });
        clearTimeout(timmer);
      }, 100);
    } else {
      message.error("当前没有选中任何老师");
    }
  };
  SearchStaff = (e) => {};

  GetStaffList() {
    BindUser({
      // user_group_id: this.state.selectId[0],
    }).then((res) => {
      if (res.data.code === 1) {
        this.setState({
          staffList: res.data.data.lists,
        });
      } else {
      }
    });
  }
  GetRootList = () => {
    api
      .GetMenuList()
      .then((res) => {
        let rootList = JSON.parse(JSON.stringify(res.data.auth)).map((ele) => {
          return ele.name;
        });
        let rootRequestList = { ...this.state.rootRequestList, ...{} };
        Object.keys(rootRequestList).forEach((ele, index) => {
          if (rootList.indexOf(rootRequestList[ele].name) !== -1) {
            rootRequestList[ele].isCanUse = true;
          }
        });
        this.setState({
          rootRequestList: rootRequestList,
        });
      })
      .catch((res) => {
        console.log("error");
      });
  };
  render() {
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    };
    const rule = [{ required: true }];
    const { loading, imageUrl } = this.state;
    const { expandedKeys, autoExpandParent, gData } = this.state;
    const columns = [
      {
        title: "姓名",
        dataIndex: "name",
        width: 135,
        key: "name",
        fixed: "left",
      },
      {
        title: "花名",
        key: "nickname",
        dataIndex: "nickname",
      },
      {
        title: "企业微信",
        key: "qy_userid",
        dataIndex: "wxName",
      },
      {
        title: "二维码",
        key: "mp_url",
        dataIndex: "wx_pic",
        render: (res) => {
          // eslint-disable-next-line
          return (
            <img
              style={{ width: 30, height: "auto" }}
              src={`https://peiyinimg.qupeiyin.cn/${res}`}
              alt=""
            ></img>
          );
        },
      },
      {
        title: "二维码链接",
        key: "mp_url",
        dataIndex: "mp_url",
      },
      {
        title: "本月目标",
        key: "month",
        dataIndex: "month",
      },
      {
        title: "老师类型",
        key: "type",
        dataIndex: "type",
        render: (res) => {
          let typeText = "";
          switch (res) {
            case "A":
              typeText = "售后";
              break;
            case "B":
              typeText = "售前";
              break;
            case "N":
              typeText = "非销售";
              break;
            default:
              break;
          }
          return <span>{typeText}</span>;
        },
      },
      {
        title: "操作",
        key: "action",
        render: (text, record) => (
          <Space size="middle">
            <Button
              size="small"
              type="primary"
              onClick={() => this.ShowEditor(record)}
              style={
                this.state.rootRequestList.editTeacher.isCanUse
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              编辑
            </Button>
            {record.uid === 0
              ? // <Button
                //   size="small"
                //   type="primary"
                //   onClick={() => this.ShowBindTeacher(record)}
                // >
                //   绑定老师
                // </Button>
                ""
              : // <Button
                //   size="small"
                //   type="primary"
                //   onClick={() => this.ShowBindTeacher(record)}
                // >
                //   绑定老师
                // </Button>
                ""}
            <Button
              size="small"
              type="primary"
              onClick={() => this.ShowAddSet(record)}
              style={
                this.state.rootRequestList.setWords.isCanUse
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              加好友设置
            </Button>
          </Space>
        ),
      },
    ];
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({
          children: selectedRows,
        });
      },
      getCheckboxProps: (record) => ({
        disabled: record.name === "Disabled User", // Column configuration not to be checked
        name: record.name,
      }),
    };
    const uploadButton = (
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div
          style={{
            marginTop: 8,
          }}
        >
          Upload
        </div>
      </div>
    );
    return (
      <div className="teacher-page">
        <div className="btn-box">
          <Button
            style={
              this.state.rootRequestList.addTeacher.isCanUse
                ? { display: "block" }
                : { display: "none" }
            }
            type="primary"
            onClick={() => {
              this.setState({
                addStaffModal: true,
              });
              this.GetStaffList();
            }}
          >
            新建老师
          </Button>
          <Button
            type="primary"
            onClick={this.UpdataAll}
            style={
              this.state.rootRequestList.editAll.isCanUse
                ? { display: "block" }
                : { display: "none" }
            }
          >
            批量修改目标
          </Button>
        </div>
        <div className="flex-container">
          <div className="left-container">
            <div
              style={{
                marginBottom: "200px",
              }}
            >
              <Search
                style={{
                  marginBottom: 8,
                }}
                placeholder="Search"
                onChange={this.OnChange}
              />
              <Tree
                onSelect={this.OnSelect}
                onExpand={this.OnExpand}
                expandedKeys={expandedKeys}
                autoExpandParent={autoExpandParent}
                selectedKeys={this.state.selectId}
              >
                <TreeNode selectable={true} key="0" title={<span>根</span>}>
                  {this.Loop(gData)}
                </TreeNode>
              </Tree>
            </div>
          </div>
          <div className="right-container">
            <Table
              rowSelection={{
                type: "checkbox",
                ...rowSelection,
              }}
              scroll={{
                x: 1500,
                y: 600,
              }}
              columns={columns}
              pagination={false}
              dataSource={this.state.dataSource}
            />
          </div>
        </div>
        <Modal
          title="编辑老师"
          visible={this.state.editStaffModal}
          onOk={this.EditorTeacher}
          onCancel={this.HandleCancel}
        >
          <Form {...layout} ref="editForm">
            <Form.Item label="姓名" name="name">
              <Input className="t-input"></Input>
            </Form.Item>
            <Form.Item label="花名" name="nickname" rules={rule}>
              <Input className="t-input" placeholder="请输入花名"></Input>
            </Form.Item>
            <Form.Item name="qy_userid" label="企业微信">
              <Select
                showSearch
                className="t-input"
                style={{ width: 200 }}
                optionFilterProp="children"
                onSearch={this.SearchStaff}
                placeholder="请选择员工"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {this.state.teacherUserList.map((ele, index) => {
                  return (
                    <Option value={ele.userid} key={index}>
                      {ele.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              label="二维码"
              name="wx_pic"
              valuePropName="wx_pic"
              rules={rule}
              getValueFromEvent={(value) => {
                if (value.event) {
                  console.log(value.event.currentTarget);
                }
                return "";
              }}
            >
              <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                beforeUpload={this.EditUpload}
                onChange={(res) => {}}
              >
                {imageUrl ? (
                  <img
                    src={imageUrl}
                    alt="avatar"
                    style={{
                      width: "100%",
                    }}
                  />
                ) : (
                  uploadButton
                )}
              </Upload>
            </Form.Item>
            <Form.Item label="二维码链接" name="mp_url" rules={RULESURL}>
              <Input
                className="t-input"
                type="url"
                placeholder="请输入二维码链接"
              ></Input>
            </Form.Item>
            <Form.Item
              label="本月目标"
              placeholder="请输入目标"
              name="month"
              rules={RULESNUM}
            >
              <Input className="t-input"></Input>
            </Form.Item>
            <Form.Item
              label="老师类型"
              placeholder="请输入老师类型"
              name="type"
              rules={rule}
            >
              <Select style={{ width: 200 }}>
                <Option value="B">售前</Option>
                <Option value="A">售后</Option>
                <Option value="N">非销售</Option>
              </Select>
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title="添加老师"
          visible={this.state.addStaffModal}
          onOk={this.AddTeacher}
          onCancel={this.HandleCancel}
        >
          <Form {...layout} ref="addForm">
            <Form.Item name="uid" label="员工">
              <Select
                showSearch
                className="t-input"
                style={{ width: 200 }}
                optionFilterProp="children"
                onSearch={this.SearchStaff}
                placeholder="请选择员工"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {this.state.staffList.map((ele, index) => {
                  return (
                    <Option value={ele.uid} key={index}>
                      {ele.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item name="nickname" label="花名" rules={rule}>
              <Input className="t-input" placeholder="请输入花名"></Input>
            </Form.Item>
            <Form.Item name="userId" label="企业微信">
              <Select
                showSearch
                style={{ width: 200 }}
                optionFilterProp="children"
                onSearch={this.SearchStaff}
                placeholder="请选择员工"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {this.state.teacherUserList.map((ele, index) => {
                  return (
                    <Option value={ele.userid} key={index}>
                      {ele.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              name="wx_pic"
              label="二维码"
              valuePropName="wx_pic"
              rules={rule}
              getValueFromEvent={(value) => {
                if (value.event) {
                  console.log(value.event.currentTarget);
                }
                return "";
              }}
            >
              <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                beforeUpload={this.AddUpload}
              >
                {imageUrl ? (
                  <img
                    src={imageUrl}
                    alt="avatar"
                    style={{
                      width: "100%",
                    }}
                  />
                ) : (
                  uploadButton
                )}
              </Upload>
            </Form.Item>
            <Form.Item name="mp_url" label="二维码链接" rules={RULESURL}>
              <Input
                style={{ width: "200px" }}
                placeholder="请输入二维码"
              ></Input>
            </Form.Item>
            <Form.Item name="month" label="本月目标" rules={RULESNUM}>
              <Input
                style={{ width: "200px" }}
                placeholder="请输入本月目标"
              ></Input>
            </Form.Item>
            <Form.Item name="type" placeholder="" label="老师类型" rules={rule}>
              <Select placeholder="请选择老师类型" style={{ width: 200 }}>
                <Option value="B">售前</Option>
                <Option value="A">售后</Option>
                <Option value="N">非销售</Option>
              </Select>
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title="绑定老师"
          visible={this.state.bindTeacherModal}
          onOk={this.BindTeacherUid}
          onCancel={this.HandleCancel}
        >
          <Select
            showSearch
            placeholder="请选择姓名"
            style={{ width: "200px" }}
            onChange={this.HandleUserUid}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {this.state.teacherUserList.map((item, idx) => {
              return (
                <Option key={idx} value={item.userid}>
                  {item.name}
                </Option>
              );
            })}
          </Select>
        </Modal>
        <Modal
          title="批量修改目标"
          visible={this.state.editMoreModal}
          onOk={this.TeacherUpdateAll}
          onCancel={this.HandleCancel}
        >
          <Form ref="editAll" {...layout}>
            <Form.Item label="姓名" name="id">
              <Select
                placeholder="请选择姓名"
                mode="multiple"
                style={{ width: "200px" }}
                disabled
              >
                {this.state.children.map((item, idx) => {
                  return (
                    <Option key={idx} value={item.id}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item label="本月目标" name="month" rules={RULESNUM}>
              <Input
                style={{ width: "200px" }}
                placeholder="请输入本月目标"
              ></Input>
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title="加好友设置"
          visible={this.state.addUserSetModal}
          onOk={this.HandleAddSet}
          onCancel={this.HandleCancel}
        >
          <div style={{ display: "flex", marginBottom: "12px" }}>
            <div style={{ width: "100px", lineHeight: "32px" }}>标签：</div>
            <Select
              mode="multiple"
              placeholder="请选择"
              value={this.state.selectedActTagList}
              onChange={this.HandleChangeTagList}
              maxTagCount={3}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              style={{ width: "240px" }}
            >
              {this.state.allTagLists.map((item, index) => {
                return (
                  <Option value={item.id} key={item.id + "" + index}>
                    {item.name}
                  </Option>
                );
              })}
            </Select>
          </div>
          <div style={{ display: "flex", marginBottom: "12px" }}>
            <div style={{ width: "100px", lineHeight: "32px" }}>欢迎语：</div>
            <TextArea
              value={this.state.addSetMsg}
              onChange={(e) => this.ChangeName(e)}
              style={{ width: "240px" }}
              rows={4}
            />
          </div>
          <div style={{ display: "flex", marginBottom: "12px" }}>
            <div style={{ width: "100px", lineHeight: "32px" }}>上传图片：</div>
            <Upload
              style={
                this.state.rootRequestList.uploadWeChatImg.isCanUse
                  ? { display: "block" }
                  : { display: "none" }
              }
              showUploadList={false}
              beforeUpload={this.WxUpload}
            >
              <Button icon={<UploadOutlined />}>点击上传</Button>
            </Upload>
            <img
              style={{ width: "36px", height: "36px" }}
              src={this.state.wxPicUrl}
              alt=""
            />
            {/* <input
              id="file"
              ref={(el) => {
                this.input = el;
              }}
              name="file"
              type="file"
              accept="image/*"
              onChange={this.HandleImageChange}
            ></input> */}
          </div>
        </Modal>
      </div>
    );
  }
}
export default TeacherManage;
