import React, { Component } from "react";
import { Link } from "react-router-dom";
import ClassApi from "../../../lib/api/class/exClass";
import MenuApi from "../../../lib/api/menus/index";
import {
  Input,
  Select,
  DatePicker,
  Space,
  Button,
  Table,
  Row,
  Col,
  Modal,
  message,
  Form,
} from "antd";
// import {} from ""
require("./index.less");
const { Option } = Select;
const { RangePicker } = DatePicker;
class ExClassDetails extends Component {
  constructor(props) {
    super(props);
    this.HandleOk = this.HandleOk.bind(this);
    this.HandleCancel = this.HandleCancel.bind(this);
    this.state = {
      isModalVisible: false,
      classList: [],
      pageSize: 10,
      isBindVisible: false,
      total: 0,
      pageSizeOption: [5, 10, 15, 20],
      chatLists: [],
      teacherLists: [],
      bindClassId: "",
      bindTeacherId: "",
      current: 0,
      className: "",
      classStatus: "",
      studyStartTimeMin: "",
      studyStartTimeMax: "",
      upTimeMin: "",
      upTimeMax: "",
      allRuleInfo: [],
      allRuleList: [
        {
          name: "enterpriseWechat/bindTeacherAndChat",
          title: "绑定老师群组",
          show: false,
        },
      ],
    };
  }

  UNSAFE_componentWillMount() {
    this.GetClassList();
    this.GetTeaacherClassList();
    this.GetMenus()
  }
  GetMenus = () => {
    MenuApi.GetMenuList().then(
      (res) => {
        if (res) {
          if (res.code === 1) {
            this.setState({
              allRuleInfo: res.data.auth,
              // isSuperAdmin: res.data.isSuperAdmin,
            });
            this.GetRuleList();
          } else {
            message.warning(res.msg);
          }
        }
      },
      (error) => {
        console.log("failed!");
      }
    );
  };
  GetRuleList = () => {
    let authList = this.state.allRuleInfo;
    let list = authList.map((item, index) => {
      return {
        name: item.name,
        title: item.title,
        id: item.id,
      };
    });
    this.GetBtnStatus(list);
  };

  //获取按钮显示隐藏
  GetBtnStatus = (list) => {
    let allRule = this.state.allRuleList;
    list.forEach((item) => {
      allRule.map((items) => {
        if (item.name === items.name) {
          items.show = true;
        }
        return {
          ...items,
        };
      });
    });
    console.log("this.state.allRuleList", this.state.allRuleList);
  };

  //主接口-->获取班级列表
  GetClassList = () => {
    ClassApi.GetClassList({
      start: this.state.current,
      rows: this.state.pageSize,
      className: this.state.className,
      courseStatus: this.state.classStatus,
      studyStartTimeMin: this.state.studyStartTimeMin,
      studyStartTimeMax: this.state.studyStartTimeMax,
      saleTimeMin: this.state.upTimeMin,
      saleTimeMax: this.state.upTimeMax,
    }).then(
      (res) => {
        if (res.code === 1) {
          this.setState({
            total: res.data.total,
            classList: res.data.list.map((item, index) => {
              let studyStatus = "";
              let startTime = this.Timestamp(item.study_start_time);
              let saleTime = this.Timestamp(item.sale_time);
              let unsaleTime = this.Timestamp(item.unsale_time);
              let endTime = item.study_start_time + 60 * 60 * 24 * 7;
              if (item.study_start_time - Date.parse(new Date()) / 1000 > 0) {
                studyStatus = "等待开班";
              } else {
                studyStatus = "已开班";
              }
              if (endTime - Date.parse(new Date()) / 1000 < 0) {
                studyStatus = "已结课";
              }
              return {
                ...item,
                key: item.id,
                studyStatus: studyStatus,
                studyStartTime: startTime,
                saleTime: saleTime,
                unsaleTime: unsaleTime,
              };
            }),
          });
        } else {
          message.error(res.msg);
        }
      },
      (error) => {
        console.log("failed!");
      }
    );
  };
  GetTeaacherClassList = () => {
    ClassApi.GetClassTeacherList({}).then(
      (res) => {
        if (res.code === 1) {
          console.log("裂开", res);
          this.setState({
            chatLists: res.data.chatLists,
            teacherLists: res.data.teacherLists,
          });
        } else {
          message.error(res.msg);
        }
      },
      (error) => {
        console.log("failed!");
      }
    );
  };

  HandleOk() {
    Modal.success({
      title: "绑定微信成功",
    });
    this.setState({
      isModalVisible: false,
    });
  }
  HandleCancel() {
    this.setState({
      isModalVisible: false,
      isBindVisible: false,
    });
  }
  //重置搜索信息
  ResetForm = () => {
    this.refs.search.resetFields();
    this.setState(
      {
        start: 0,
        rows: 10,
        className: "",
        classStatus: "",
        studyStartTimeMin: "",
        studyStartTimeMax: "",
        upTimeMin: "",
        upTimeMax: "",
      },
      function () {
        this.GetClassList();
      }
    );
  };

  //时间戳转换
  Timestamp = (timestamp) => {
    var d = new Date(timestamp * 1000); //根据时间戳生成的时间对象
    var year = d.getFullYear();
    var month = d.getMonth() + 1;
    var day = d.getDate();
    var hour = d.getHours();
    var minute = d.getMinutes();
    var second = d.getSeconds();
    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }
    if (hour < 10) {
      hour = "0" + hour;
    }
    if (minute < 10) {
      minute = "0" + minute;
    }
    if (second < 10) {
      second = "0" + second;
    }
    let html =
      year +
      "-" +
      month +
      "-" +
      day +
      "  " +
      hour +
      ":" +
      minute +
      ":" +
      second;
    return html;
  };
  GetTime = (e) => {
    this.setState({
      studyStartTimeMin: Math.round(e[0].valueOf() / 1000),
      studyStartTimeMax: Math.round(e[1].valueOf() / 1000),
    });
  };
  GetUpTime = (e) => {
    this.setState({
      upTimeMin: Math.round(e[0].valueOf() / 1000),
      upTimeMax: Math.round(e[1].valueOf() / 1000),
    });
  };
  HandleClassStatus = (value) => {
    this.setState({
      classStatus: `${value}`,
    });
  };

  //输入相关
  AddClassName = (e) => {
    this.setState({
      className: e.target.value,
    });
  };
  //
  HandleClassId = (value) => {
    this.setState({
      bindClassId: `${value}`,
    });
  };
  //
  HandleTeacherId = (value) => {
    this.setState({
      bindTeacherId: `${value}`,
    });
  };
  //
  HandBindOk = () => {
    ClassApi.BindTeacherClass({
      classId: this.state.BindClassId,
      chatId: this.state.bindClassId,
      qyUserId: this.state.bindTeacherId,
    }).then((res) => {
      if (res.code === 1) {
        //成功之后的处理
        message.info("操作成功");
        this.setState({
          isBindVisible: false,
        });
      } else {
        message.error(res.msg);
      }
    });
  };

  render() {
    this.columns = [
      {
        title: "班级id",
        width: 100,
        dataIndex: "id",
        key: "id",
        render: (res) => {
          return (
            <Space size="middle">
              <Link to={`/classManage/details/${res}`} className="link">
                {res}
              </Link>
            </Space>
          );
        },
      },
      {
        title: "班级名称",
        dataIndex: "title",
        key: "45",
        width: 150,
      },
      {
        title: "班级开课状态",
        dataIndex: "studyStatus",
        key: "3",
        width: 150,
      },
      {
        title: "渠道组",
        dataIndex: "channelGroupTitle",
        key: "33",
        width: 150,
      },
      {
        title: "报名人数",
        dataIndex: "sv",
        key: "45",
        width: 150,
      },
      {
        title: "到课人数",
        dataIndex: "learned_count",
        key: "4",
        width: 150,
      },
      {
        title: "完课人数",
        dataIndex: "learned_finish_count",
        key: "5",
        width: 150,
      },
      {
        title: "付费人数",
        dataIndex: "pay_count",
        key: "65",
        width: 150,
      },
      {
        title: "付费金额",
        dataIndex: "pay_total",
        key: "6",
        width: 150,
      },
      {
        title: "开班时间",
        dataIndex: "studyStartTime",
        key: "7",
        width: 150,
      },
      {
        title: "上架时间",
        dataIndex: "saleTime",
        key: "8",
        width: 150,
      },
      {
        title: "下架时间",
        dataIndex: "unsaleTime",
        key: "9",
        width: 150,
      },
      {
        title: "操作",
        key: "operation",
        fixed: "right",
        width: 260,
        render: (text, record, index) => {
          return (
            <Space size="middle">
              <Link to={`/classManage/details/${record.id}`} className="link">
                详情
              </Link>
              {/* <Link
                to="#"
                onClick={() => {
                  this.setState({
                    isModalVisible: true,
                  });
                }}
                className="link"
              >
                编辑
              </Link> */}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={record.topUrl}
                className="link"
              >
                排行榜
              </a>
              <Button
              style={{display: this.state.allRuleList[0].show?"block": "none"}}
                onClick={() => {
                  this.setState({
                    isBindVisible: true,
                    BindClassId: record.id,
                  });
                }}
              >
                绑定老师&群
              </Button>
            </Space>
          );
        },
      },
    ];
    const layout = {
      labelCol: { span: 5 },
      wrapperCol: { span: 8 },
      labelAlign: "left",
    };
    let wxList = this.state.chatLists.map((items, indexs) => {
      return (
        <Option value={`${items.chat_id}`} key={items.chat_id}>
          {items.name}
        </Option>
      );
    });
    let teacherList = this.state.teacherLists.map((items, indexs) => {
      return (
        <Option value={`${items.userid}`} key={items.userid}>
          {items.name}
        </Option>
      );
    });
    return (
      <div className="main-page exClass">
        <div className="search-box">
          <Form {...layout} ref="search">
            <Row gutter={24} align={"middle"}>
              <Col span={8}>
                <Form.Item label="班级名称" name="className">
                  <Input
                    value={this.state.className}
                    onChange={(e) => this.AddClassName(e)}
                    style={{ width: 150 }}
                  ></Input>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="班级状态" name="classStatus">
                  <Select
                    value={this.state.classStatus}
                    onChange={this.HandleClassStatus}
                    style={{ width: 150 }}
                  >
                    <Option value="1">等待开班</Option>
                    <Option value="2">已开班</Option>
                    <Option value="3">已结课</Option>
                  </Select>
                </Form.Item>
              </Col>
              {/* <Col sm={12} md={8} lg={8} xl={6}>
                <Form.Item label="关联老师" name="relatedTeacher">
                  <Select style={{ width: 150 }}>
                    <Option value="lucy">Lucy</Option>
                  </Select>
                </Form.Item>
              </Col> */}
              <Col span={8}>
                <Form.Item label="开班时间" name="startTime">
                  <RangePicker
                    allowClear={false}
                    style={{ width: 240 }}
                    onChange={this.GetTime}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="上架时间" name="appearedTime">
                  <RangePicker allowClear={false} onChange={this.GetUpTime} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Button
                  type="primary"
                  size={"middle"}
                  onClick={this.GetClassList}
                  className="btn-search"
                >
                  查询
                </Button>
                <Button size={"middle"} onClick={this.ResetForm}>
                  重置
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
        <Table
          columns={this.columns}
          dataSource={this.state.classList}
          scroll={{ x: 1500 }}
          pagination={{
            position: ["bottomLeft"],
            pageSize: this.state.pageSize,
            total: this.state.total,
            pageSizeOptions: this.state.pageSizeOption,
            showQuickJumper: true,
            onChange: (current, pageSize) => {
              console.log(current, pageSize);
              this.setState(
                {
                  current: (current - 1) * pageSize,
                  pageSize: pageSize,
                },
                function () {
                  this.GetClassList();
                }
              );
            },
            onShowSizeChange: (current, size) => {
              console.log(current, size);
              this.setState(
                {
                  current: current * size,
                  pageSize: size,
                },
                function () {
                  this.GetClassList();
                }
              );
            },
          }}
        />
        <Modal
          title="编辑"
          visible={this.state.isBindVisible}
          onOk={this.HandBindOk}
          onCancel={this.HandleCancel}
        >
          <Select
            showSearch
            style={{ width: 200, marginRight: "20px" }}
            placeholder="请选择班级"
            optionFilterProp="children"
            onChange={this.HandleClassId}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
          >
            {wxList}
          </Select>
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder="请选择老师"
            optionFilterProp="children"
            onChange={this.HandleTeacherId}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
          >
            {teacherList}
          </Select>
        </Modal>
        <Modal
          title="编辑"
          visible={this.state.isModalVisible}
          onOk={this.HandleOk}
          onCancel={this.HandleCancel}
        >
          <Row align="middle" style={{ marginBottom: "10px" }}>
            <Col span={4}>班级名称</Col>
            <Col span={10}>118班</Col>
          </Row>
          <Row align="middle" style={{ marginBottom: "10px" }}>
            <Col span={4}>微信群</Col>
            <Col span={10}>
              <Select
                showSearch
                style={{ width: 200 }}
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
              >
                <Option value="1">Not Identified</Option>
                <Option value="2">Closed</Option>
                <Option value="3">Communicated</Option>
                <Option value="4">Identified</Option>
                <Option value="5">Resolved</Option>
                <Option value="6">Cancelled</Option>
              </Select>
            </Col>
          </Row>
          <Row align="middle" style={{ marginBottom: "10px" }}>
            <Col span={4}>老师</Col>
            <Col span={10}>
              <Select
                showSearch
                style={{ width: 200 }}
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
              >
                <Option value="1">Not Identified</Option>
                <Option value="2">Closed</Option>
                <Option value="3">Communicated</Option>
                <Option value="4">Identified</Option>
                <Option value="5">Resolved</Option>
                <Option value="6">Cancelled</Option>
              </Select>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
}
export default ExClassDetails;
