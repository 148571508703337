import React, { Component } from 'react';
import { Button, Input, Table, Form, Row, Col, Modal ,message, DatePicker, Select, Space } from 'antd'
import { GetChangeHistory, ImportVipMibileV2 } from '@/lib/api/activity/unionVip.js'
const { Option } = Select;
const { RangePicker } = DatePicker;
const { TextArea } = Input;

class UnionVip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: [],
      total: 0,
      current: 1,
      pageSize: 10,
      pageSizeOption: [5, 10, 15, 20],

      startTime: '',
      endTime: '',
      searcText: '',
      searchType: '0',
      ticketType: [],

      importModalVisible: false,
      importMobileText: ''
    }
  }
  UNSAFE_componentWillMount() {
    this.GetData()
  }
  GetData = () => {
    GetChangeHistory({
      changeType: this.state.searchType,
      start: (this.state.current - 1) * this.state.pageSize,
      rows: this.state.pageSize,
      search: this.state.searcText,
      changStartTime: this.state.startTime,
      changEndTime: this.state.endTime

    }).then(res => {
      console.log(res)
      if(res.code === 1) {
        let typeList = {}
        res.data.config.map(item => {
          typeList[String(item.value)] = item.desc
          return item
        })
        this.setState({
          ticketType: typeList,
          dataSource: res.data.lists,
          total: res.data.total
        })
      }
      
    })
  }
  OnSearch = () => {
    this.setState({
      current: 1,
      total: 0
    }, () => {
      this.GetData()
    })
  }
  GetTime = (e) => {
    this.setState({
      startTime: parseInt(new Date(e[0]).getTime()/1000),
      endTime: parseInt(new Date(e[1]).getTime()/1000)
    })
  }
  ChangeSelect = (e) => {
    console.log(e)
    this.setState({
      searchType: e
    })
  }
  // 导入操作
  HandleImportMobile = () => {
    if(!this.CheckMobile()) {
      message.error('请检查手机号是否正确！')
      return
    }
    ImportVipMibileV2({
      mobiles: this.state.importMobileText
    }).then(res => {
      console.log(res)
      if(res.code === 1) {
        message.info('操作成功')
        this.setState({
          importMobileText: '',
          importModalVisible: false
        })
        this.GetData()
      }else {
        message.error(res.msg)
      }
    })
  }
  CheckMobile = () => {
    const mobileList = this.state.importMobileText.split('\n')
    console.log(mobileList)
    let flag = true
    const reg = /^(1[3456789]\d{9})$/
    for (let i = 1; i<mobileList.length; i++) {
      if (reg.test(mobileList[i])) {
        flag = true
      }else {
        flag =  false
        break
      }
    }
    console.log(flag)
    return flag
    
  }
  HandleCancel = () => {
    this.setState({
      importModalVisible: false
    })
  }
  render() { 
    const layout = {
      labelCol: { span: 5 },
      wrapperCol: { span: 8 },
      labelAlign: "left",
    };
    const columns = [
      {
        title: '主键',
        dataIndex: 'id',
        key: '_id',
        width: 50,
      },
      {
        title: '类型',
        key: 'type',
        width: 70,
        render: (text, record, index) => {
          return (
            <Space>
              {this.state.ticketType[record.type]}
            </Space>
          )
        }
      },
      {
        title: '导入时间',
        key: 'create_time',
        width: 70,
        render: (text, record, index) => {
          return (
            <Space>
              {record.create_time}
            </Space>
          )
        }
      },
      {
        title: '兑换码',
        dataIndex: 'code',
        key: 'code',
        width: 70,
      },
      {
        title: '兑换手机号',
        dataIndex: 'mobile',
        key: 'mobile',
        width: 70,
      },
      {
        title: '状态',
        key: 'codeStatus',
        width: 70,
        render: (text, record, index) => {
          return (
            <Space>
              {record.mobile > 0 ? '已领取' : '未领取'}
            </Space>
          )
        }
      },
      {
        title: '领取时间',
        dataIndex: 'update_time',
        key: 'update_time',
        width: 70,
      }
    ]
    return ( 
      <div className="vip-container">
        <Form {...layout}> 
          <Button
            type='primary'
            size='middle'
            onClick={() => {this.setState({importModalVisible: true})}}
            style={{marginBottom: 20}}
          >
            导入手机号
          </Button>
          <Row gutter={24} align={"middle"}>
            <Col span={8}>
              <Form.Item
                label='领取时间'
              >
                <RangePicker
                  allowClear={false}
                  onChange={this.GetTime}
                  style={{width: 200}}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label='搜索内容'
              >
                <Input
                  placeholder='输入手机号/兑换码'
                  value={this.state.searcText}
                  onChange={(e) => {this.setState({searcText: e.target.value})}}
                  style={{width: 200}}
                ></Input>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label='会员类型'
              >
                <Select
                  value={this.state.searchType}
                  onChange={this.ChangeSelect}
                  style={{ width: 200 }}
                >
                  <Option value="0">全部类型</Option>
                  <Option value="1">趣配音会员年卡</Option>
                  <Option value="2">京东plus会员年卡</Option>
                  <Option value="3">百度云会员半年卡</Option>
                  <Option value="4">美团买菜抵扣券</Option>
                  <Option value="5">全棉时代贴心礼</Option>
                  <Option value="6">樊登读书14天会员</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Button
                type="primary"
                size={"middle"}
                onClick={this.OnSearch}
                className="btn-search"
              >
                查询
              </Button>
            </Col>
          </Row>  
        </Form>
      
        <Table
          rowKey="id"
          columns={columns}
          dataSource={this.state.dataSource}
          pagination={{
            current: this.state.current,
            position: ["bottomLeft"],
            pageSize: this.state.pageSize,
            total: this.state.total,
            pageSizeOptions: this.state.pageSizeOption,
            showQuickJumper: true,
            onChange: (current, pageSize) => {
              console.log(current, pageSize);
              this.setState(
                {
                  current: current,
                  pageSize: pageSize,
                },
                function () {
                  this.GetData();
                }
              );
            },
            onShowSizeChange: (current, size) => {
              console.log(current, size);
              this.setState(
                {
                  current: current,
                  pageSize: size,
                },
                function () {
                  this.GetData();
                }
              );
            },
          }}
        />

        <Modal
          title="导入"
          visible={this.state.importModalVisible}
          onOk={this.HandleImportMobile}
          onCancel={this.HandleCancel}
        >
          <TextArea 
            value={this.state.importMobileText}
            onChange={(e) => {this.setState({importMobileText: e.target.value})}}
            placeholder="请输入导入手机号，多个手机号用换行符隔开" 
            autoSize={{ minRows: 4, maxRows: 10 }} 
          />
        </Modal>
      </div>
    );
  }
}
 
export default UnionVip;