import React, { Component } from "react";
import { Input, Tooltip, Button, message } from "antd";
import { InfoCircleOutlined, UserOutlined } from "@ant-design/icons";
import LoginApi from "../../lib/api/user/index";
require("./login.less");
class Login extends Component {
  state = {
    username: "",
    password: "",
  };
  // eslint-disable-next-line react/no-typos
  componentWillmount() {
    
  }

  componentDidMount() {
    let width = document.body.clientWidth
    console.log("宽",width)
    if(width<666){
      this.props.history.push({
        pathname: `/h5/userlogin`,
        state: {},
      });
    }
    let token = localStorage.getItem("token");
    if (!token) {
      this.props.history.push({
        pathname: `/login`,
        state: {},
      });
    } else {
      this.props.history.push({
        pathname: `/user/WorkBench`,
        state: {},
      });
    }
  }

  Login = () => {
    if (this.state.username === "") {
      message.warning("账号不能为空");
      return false;
    }
    if (this.state.password === "") {
      message.warning("密码不能为空");
      return false;
    }
    LoginApi.Login({
      email: this.state.username,
      password: this.state.password,
    }).then(
      (res) => {
        console.log("登录返回", res);
        if (res.status === "success") {
          //登录成功之后的处理
          localStorage.setItem("token", res.token);
          localStorage.setItem("refresh", '0');
          this.props.history.push({
            pathname: `/user/WorkBench`,
            state: {},
          });
        } else {
          message.error(res.message);
        }
      },
    ).catch(error => {
      console.log(error.request.status)
      if(error.request.status === 421) {
        message.error("账号或密码错误，请重新输入");
      }
    });
  };

  PasswordChanged = (e) => {
    this.setState({
      password: e.target.value,
    });
  };

  UsernameChanged = (e) => {
    this.setState({
      username: e.target.value,
    });
  };
  render() {
    return (
      <div className="login">
        <div className="login-container">
          <div className="avatar-box">
            <img
              className="avatar"
              src="https://wapcdn.qupeiyin.cn/app/fundInitiation/5.2.0/logo.png"
              alt=""
            />
          </div>
          <div className="input-box">
            <Input
              placeholder="Enter your username"
              prefix={<UserOutlined className="site-form-item-icon" />}
              value={this.state.username}
              onChange={(e) => this.UsernameChanged(e)}
              suffix={
                <Tooltip title="Extra information">
                  <InfoCircleOutlined
                    style={{
                      color: "rgba(0,0,0,.45)",
                    }}
                  />
                </Tooltip>
              }
            />
          </div>
          <div className="input-box">
            <Input.Password
              value={this.state.password}
              onChange={(e) => this.PasswordChanged(e)}
              placeholder="input password"
            />
          </div>
          <div className="input-box">
            <Button type="primary" onClick={this.Login} className="login-btn">
              登录
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
export default Login;
