import utilsApi from '../api/common'
let qiniu = require('qiniu-js')

/*
 * 生成唯一的uuid
 */
const uuid = () => {
    let s4 = () => (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
    return [s4(), s4(), s4(), s4()].join('')
}

// 上传图片
const UploadPic = ({
    file,
    name,
    success,
    fail
}) => {
    let observer = {
        next(res) {},
        error(err) {
            if (fail && typeof fail === 'function') {
                fail(err)
            }
        },
        complete(res) {
            if (success && typeof success === 'function') {
                success({
                    fileName: res.key,
                    url: `https://peiyinimg.qupeiyin.cn/${res.key}`,
                })
            }
        },
    }
    let ext = file.name.substr(file.name.lastIndexOf('.'))
    let key = name ? name : uuid() + ext
    utilsApi.GetQiniuToken().then((result) => {
        let picToken = result.data.pic
        qiniu.upload(file, key, picToken).subscribe(observer)
    })
}

//eslint-disable-next-line
export default {
    UploadPic,
}