import request from '../../request';
const GetUserDetail=(id)=>{
   return request.get('magicClass/tasteDetail',{
       params:{
           uid:id
       }
   })  
}

const GetWxUserDetail=({id,uid})=>{
    return request.get('qyMagicClass/tasteDetail',{
        params:{
            externalUserid:id,
            qyUserId: uid,
        },
        needSign: true,
    })  
 }
export{
    GetUserDetail,
    GetWxUserDetail
     
}