import React, { Component } from "react";
import MenuApi from "../../../lib/api/menus/index";
import { message, Button, Tree, Input, Modal, Space, Table } from "antd";
import {
  PlusCircleOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
require("./index.less");

const TreeNode = Tree.TreeNode;

const Search = Input.Search;

class MenusManage extends Component {
  state = {
    treeData: [],
    dataList: [],
    allRuleInfo: [],
    searchValue: "",
    deleteId: "",
    expandedKeys: ["1"],
    autoExpandParent: true,
    addMenu: false,
    showAddRule: true,
    pageName: "",
    pagePath: "",
    addPid: "",
    addMenuMain: false,
    pageNameMain: "",
    pagePathMain: "",
    tableList: [],
    addRules: false,
    ruleName: "",
    rulePath: "",
    rulePid: "",
    rulePname: "",
    canAddRule: false,
    isSuperAdmin: 0,
    allRuleList: [
      {
        name: "user/userAuth",
        title: "获取菜单列表",
        show: false,
      },
      {
        name: "auth/ruleAdd",
        title: "新增菜单",
        show: false,
      },
      {
        name: "auth/ruleStatusMod",
        title: "删除菜单",
        show: false,
      },
      {
        name: "auth/menuRule",
        title: "新增权限功能点",
        show: false,
      },
    ],
  };
  UNSAFE_componentWillMount() {
    this.GetMenus();
  }
  GetMenus = () => {
    MenuApi.GetMenuList().then(
      (res) => {
        if (res) {
          if (res.code === 1) {
            this.setState({
              treeData: res.data.menu,
              allRuleInfo: res.data.auth,
              isSuperAdmin: res.data.isSuperAdmin
            });
            this.GetRuleList();
            this.GenerateList(this.state.treeData);
          } else {
            message.warning(res.msg);
          }
        }
      },
      (error) => {
        console.log("failed!");
      }
    );
  };

  GetRuleList = () => {
    let authList = this.state.allRuleInfo
    let list = authList.map((item, index) => {
      return {
        name: item.name,
        title: item.title,
        id: item.id,
      };
    });
    console.log("ruleList", list);
    this.GetBtnStatus(list);
  };

  //获取按钮显示隐藏
  GetBtnStatus = (list) => {
    let allRule = this.state.allRuleList;
    list.forEach((item) => {
      allRule.map((items) => {
        if (item.name === items.name) {
          items.show = true;
        }
        return {
          ...items,
        };
      });
    });
    console.log("this.state.allRuleList", this.state.allRuleList);
  };

  Loop = (data) =>
    data.map((item) => {
      let { searchValue } = this.state;
      let index = item.title.indexOf(searchValue);
      let beforeStr = item.title.substr(0, index);
      let afterStr = item.title.substr(index + searchValue.length);
      let title = "";
      if (item.pid === 0) {
        title =
          index > -1 ? (
            <span>
              {beforeStr}
              <span style={{ color: "#f50" }}>{searchValue}</span>
              {afterStr}
              {(this.state.allRuleList[1].show || this.state.isSuperAdmin ===1) ? (
                <Button
                  size="small"
                  shape="circle"
                  icon={<PlusCircleOutlined />}
                  onClick={(e) => this.AddMenuPopup(e, item)}
                ></Button>
              ) : (
                ""
              )}
              {(this.state.allRuleList[2].show || this.state.isSuperAdmin ===1) ? (
                <Button
                  size="small"
                  shape="circle"
                  icon={<DeleteOutlined />}
                  onClick={(e) => this.DeleteMenuPopup(e, item)}
                ></Button>
              ) : (
                ""
              )}
            </span>
          ) : (
            <span>
              {item.title}
              {(this.state.allRuleList[1].show || this.state.isSuperAdmin === 1) ? (
                <Button
                  size="small"
                  shape="circle"
                  icon={<PlusCircleOutlined />}
                  onClick={(e) => this.AddMenuPopup(e, item)}
                ></Button>
              ) : (
                ""
              )}
              {(this.state.allRuleList[2].show || this.state.isSuperAdmin === 1) ? (
                <Button
                  size="small"
                  shape="circle"
                  icon={<DeleteOutlined />}
                  onClick={(e) => this.DeleteMenuPopup(e, item)}
                ></Button>
              ) : (
                ""
              )}
            </span>
          );
      } else {
        title =
          index > -1 ? (
            <span>
              {beforeStr}
              <span style={{ color: "#f50" }}>{searchValue}</span>
              {afterStr}
              {(this.state.allRuleList[2].show || this.state.isSuperAdmin ===1) ? (
                <Button
                  size="small"
                  shape="circle"
                  icon={<DeleteOutlined />}
                  onClick={(e) => this.DeleteMenuPopup(e, item)}
                ></Button>
              ) : (
                ""
              )}
            </span>
          ) : (
            <span>
              {item.title}
              {(this.state.allRuleList[2].show || this.state.isSuperAdmin===1) ? (
                <Button
                  size="small"
                  shape="circle"
                  icon={<DeleteOutlined />}
                  onClick={(e) => this.DeleteMenuPopup(e, item)}
                ></Button>
              ) : (
                ""
              )}
            </span>
          );
      }

      if (item.child) {
        return (
          <TreeNode key={item.id} title={title} dataRef={item}>
            {this.Loop(item.child)}
          </TreeNode>
        );
      }
      return <TreeNode dataRef={item} key={item.id} title={title} />;
    });

  GenerateList = (data) => {
    let List = data.map((item, index) => {
      let children = [];
      if (item.child) {
        children = item.child.map((items, indexs) => {
          return {
            title: items.title,
            name: items.name,
            key: items.id,
            pid: items.pid,
          };
        });
      }
      return {
        title: item.title,
        name: item.name,
        key: item.id,
        pid: item.pid,
        children: children,
      };
    });
    console.log("List", List);
    this.setState({
      dataList: List,
    });
  };

  OnSelect = (selectedKeys, info) => {
    if (info.node.dataRef.pid !== 0) {
      this.setState({
        canAddRule: true,
      });
    } else {
      this.setState({
        canAddRule: false,
      });
    }
    let id = selectedKeys[0];
    if (id) {
      this.GetRules(id);
    }
    this.setState({
      rulePid: id,
      rulePname: info.node.dataRef.title,
    });
  };

  GetRules = (id) => {
    MenuApi.GetMenusRule({ id }).then(
      (res) => {
        if (res) {
          if (res.code === 1) {
            this.setState({
              tableList: res.data,
            });
          } else {
            message.warning(res.msg);
          }
        }
      },
      (error) => {
        console.log("failed!");
      }
    );
  };

  OnExpand = (expandedKeys) => {
    this.setState({
      expandedKeys,
      autoExpandParent: false,
    });
  };

  //使用树搜索功能，查找并展开树结构
  OnChange = (e) => {
    const value = e.target.value;
    let dataList = this.state.dataList;
    let openKeys = [];
    // eslint-disable-next-line
    dataList.map((item) => {
      if (item.children) {
        // eslint-disable-next-line
        item.children.map((items) => {
          if (items.title.indexOf(value) > -1) {
            openKeys.push(items.key);
          }
        });
      }
      if (item.title.indexOf(value) > -1) {
        openKeys.push(item.key);
      }
    });

    this.setState({
      expandedKeys: openKeys,
      searchValue: value,
      autoExpandParent: true,
    });
  };

  //添加菜单对话框开关
  AddMenuPopup = (e, item) => {
    if (item.pid === 0) {
      this.setState({
        addMenu: true,
        addPid: item.id,
        addFatherName: item.title,
      });
    } else {
      this.setState({
        addRules: true,
        rulePid: item.id,
      });
    }
  };

  //删除菜单对话框
  DeleteMenuPopup = (e, item) => {
    this.setState({
      deleteMenu: true,
      deleteId: item.id,
    });
    Modal.confirm({
      title: "删除菜单",
      icon: <ExclamationCircleOutlined />,
      content: "此操作将删除本菜单",
      okText: "确认",
      cancelText: "取消",
      onOk: () => {
        this.DeleteMenu(item.id);
      },
    });
  };

  //确认删除菜单
  DeleteMenu = (id) => {
    localStorage.setItem("refresh", "1");
    MenuApi.UpdataMenus({
      id: id,
      type: -1,
    }).then((res) => {
      if (res.code === 1) {
        message.success("操作成功");
        this.GetMenus();
        // this.GetRules(this.state.rulePid);
        this.setState({
          deleteMenu: false,
        });
      } else {
        message.waring(res.msg);
      }
    });
  };

  //确认添加菜单
  AddMenuClick = () => {
    if (this.state.pageName === "") {
      message.warning("请输入页面名称");
      return false;
    }
    if (this.state.pagePath === "") {
      message.warning("请输入页面路由");
      return false;
    }
    localStorage.setItem("refresh", "1");
    MenuApi.AddMenus({
      name: this.state.pagePath,
      title: this.state.pageName,
      pid: this.state.addPid,
      isMenu: 1,
      status: 1,
      weigh: "",
    }).then((res) => {
      if (res.code === 1) {
        message.success("添加成功");
        this.GetMenus();
        this.setState({
          addMenu: false,
        });
      } else {
        message.error("该路由已存在");
      }
    });
  };
  //添加一级菜单
  AddMainMenuClick = () => {
    if (this.state.pageNameMain === "") {
      message.warning("请输入页面名称");
      return false;
    }
    if (this.state.pagePathMain === "") {
      message.warning("请输入页面路由");
      return false;
    }
    localStorage.setItem("refresh", "1");
    MenuApi.AddMenus({
      name: this.state.pagePathMain,
      title: this.state.pageNameMain,
      pid: 0,
      isMenu: 1,
      status: 1,
      weigh: "",
    }).then((res) => {
      if (res.code === 1) {
        message.success("添加成功");
        this.GetMenus();
        this.setState({
          addMenuMain: false,
        });
      } else {
        message.error(res.msg);
      }
    });
  };
  //确认添加权限事件
  AddRuleClick = () => {
    if (this.state.ruleName === "") {
      message.warning("请输入权限名称");
      return false;
    }
    if (this.state.rulePath === "") {
      message.warning("请输入权限路由");
      return false;
    }
    MenuApi.AddMenus({
      name: this.state.rulePath,
      title: this.state.ruleName,
      pid: this.state.rulePid,
      isMenu: 0,
      status: 1,
      weigh: "",
    }).then((res) => {
      if (res.code === 1) {
        message.success("添加成功");
        this.GetMenus();
        this.GetRules(this.state.rulePid);
        this.setState({
          addRules: false,
        });
      } else {
        message.error(res.msg);
      }
    });
  };

  //添加权限对话框开关
  AddNewRule = () => {
    if (this.state.canAddRule) {
      this.setState({
        addRules: true,
      });
    } else {
      message.warning("请先选中父级权限页面");
    }
  };

  //以下六个皆为输入框绑定事件
  ChangeName = (e) => {
    this.setState({
      pageName: e.target.value,
    });
  };
  AddPath = (e) => {
    this.setState({
      pagePath: e.target.value,
    });
  };
  ChangeNameMain = (e) => {
    this.setState({
      pageNameMain: e.target.value,
    });
  };
  AddPathMain = (e) => {
    this.setState({
      pagePathMain: e.target.value,
    });
  };
  ChangeRuleName = (e) => {
    this.setState({
      ruleName: e.target.value,
    });
  };
  AddRulePath = (e) => {
    this.setState({
      rulePath: e.target.value,
    });
  };

  //关闭对话框
  HandleCancel = () => {
    this.setState({
      addMenu: false,
      addMenuMain: false,
      addRules: false,
    });
  };

  //添加一级菜单对话框开关
  AddMainMenuPopup = () => {
    this.setState({
      addMenuMain: true,
    });
  };

  render() {
    const columnsRule = [
      {
        title: "控制内容",
        dataIndex: "name",
        key: "name",
      },

      {
        title: "内容分类",
        key: "title",
        dataIndex: "title",
      },

      {
        title: "操作",
        key: "action",
        render: (text, record) => (
          <Space size="middle">
            {(this.state.allRuleList[2].show || this.state.isSuperAdmin===1) ? (
              <Button
                size="small"
                type="primary"
                onClick={() => this.DeleteMenu(record.id)}
              >
                删除
              </Button>
            ) : (
              ""
            )}
          </Space>
        ),
      },
    ];

    const columns = [
      {
        title: "控制内容",
        dataIndex: "name",
        key: "name",
      },

      {
        title: "内容分类",
        key: "title",
        dataIndex: "title",
      },
    ];
    return (
      <div className="menu-page">
        <div className="tree-container">
          <div>
            <Search
              style={{ marginBottom: 8, width: 300 }}
              placeholder="Search"
              onChange={this.OnChange}
            />

            <Tree
              onSelect={this.OnSelect}
              onExpand={this.OnExpand}
              expandedKeys={this.state.expandedKeys}
              autoExpandParent={this.state.autoExpandParent}
            >
              {this.Loop(this.state.treeData)}
            </Tree>
          </div>
          <div style={{ padding: "0 48px", marginTop: "10px" }}>
            {(this.state.allRuleList[1].show || this.state.isSuperAdmin===1) ? (
              <Button
                size="small"
                shape="circle"
                icon={<PlusCircleOutlined />}
                onClick={(e) => this.AddMainMenuPopup()}
              ></Button>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="table-container">
          <div className="bottom-container">
            <div></div>
            {(this.state.allRuleList[1].show || this.state.isSuperAdmin===1)? (
              <Button type="primary" onClick={this.AddNewRule}>
                新增
              </Button>
            ) : (
              ""
            )}
          </div>
          {this.state.canAddRule ? (
            <Table
              columns={columnsRule}
              pagination={false}
              dataSource={this.state.tableList}
            />
          ) : (
            <Table
              columns={columns}
              pagination={false}
              dataSource={this.state.tableList}
            />
          )}
        </div>
        <Modal
          title="新增"
          visible={this.state.addMenu}
          onOk={this.AddMenuClick}
          onCancel={this.HandleCancel}
        >
          <div className="common-input-style">
            <div className="introudce-auth">
              <span>*</span> 父级权限:
            </div>
            {this.state.addFatherName}
          </div>
          <div className="common-input-style">
            <div className="introudce-auth">
              <span>*</span> 页面名称:
            </div>
            <Input
              value={this.state.pageName}
              onChange={(e) => this.ChangeName(e)}
            ></Input>
          </div>
          <div className="common-input-style">
            <div className="introudce-auth">
              <span>*</span> 页面路由:
            </div>
            <Input
              value={this.state.pagePath}
              onChange={(e) => this.AddPath(e)}
            ></Input>
          </div>
        </Modal>

        <Modal
          title="新增菜单"
          visible={this.state.addMenuMain}
          onOk={this.AddMainMenuClick}
          onCancel={this.HandleCancel}
        >
          <div className="common-input-style">
            <div className="introudce-auth">
              <span>*</span> 父级权限:
            </div>
            已是一级菜单权限
          </div>
          <div className="common-input-style">
            <div className="introudce-auth">
              <span>*</span> 菜单名称:
            </div>
            <Input
              value={this.state.pageNameMain}
              onChange={(e) => this.ChangeNameMain(e)}
            ></Input>
          </div>
          <div className="common-input-style">
            <div className="introudce-auth">
              <span>*</span> 页面路由:
            </div>
            <Input
              value={this.state.pagePathMain}
              onChange={(e) => this.AddPathMain(e)}
            ></Input>
          </div>
        </Modal>

        <Modal
          title="新增权限"
          visible={this.state.addRules}
          onOk={this.AddRuleClick}
          onCancel={this.HandleCancel}
        >
          <div className="common-input-style">
            <div className="introudce-auth">
              <span>*</span> 父级权限:
            </div>
            {this.state.rulePname}
          </div>
          <div className="common-input-style">
            <div className="introudce-auth">
              <span>*</span> 权限名称:
            </div>
            <Input
              value={this.state.ruleName}
              onChange={(e) => this.ChangeRuleName(e)}
            ></Input>
          </div>
          <div className="common-input-style">
            <div className="introudce-auth">
              <span>*</span> 权限路由:
            </div>
            <Input
              value={this.state.rulePath}
              onChange={(e) => this.AddRulePath(e)}
            ></Input>
          </div>
        </Modal>
      </div>
    );
  }
}
export default MenusManage;
