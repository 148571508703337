import React, { Component } from 'react';

import { Form, Row, Col, Input, DatePicker, Select, Button } from 'antd'
// import moment from 'moment';


const { Option } = Select;
class headerSearch extends Component {
  constructor(props) {
    super(props)
    this.formRef = React.createRef();
    console.log(this.props)
    this.fromData = this.props['fromData']
    this.onSearch = this.props['onSearch']
    this.onResetData = this.props['onResetData']
  }


  headerSearchItem = ({
    type = 'input',
    key = '',
    list = []
  }) => {
    switch (type) {
      case 'option':
        return (
          <Select>
            {
              list.map((item, index) => {
                return (
                  <Option key={item + index} value={item.value}>{item.text}</Option>
                )
              })
            }
          </Select>
        );
      case 'date':
        return (
          <DatePicker
            key={key}
            // defaultValue={moment('2015/01/01', 'YYYY/MM/DD')}
            format={''}
            style={{ flex: 1 }}
          />
        )
      case 'input':
        return <Input></Input>

      case 'inputNum':
        return <Input type="number"></Input>
      case "canSerchSelect":
        return <Select showSearch
          filterOption={(input, option) =>{
            console.log(option)
           return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          }
        >
          {list.map((ele,index) => {
            return <Option key={index} value={ele.value}>{ele.text}</Option>
          })}
        </Select>
      default:
        return (
          <Input placeholder="zan" />
        )
    }
  }
  getFields = () => {
    let children = []
    for (let i = 0; i < this.fromData.length; i++) {
      children.push(
        <Col span={8} key={i}>
          {
            Array.isArray(this.fromData[i]['name']) ?
              (
                // 输入的是区间情况
                <Form.Item
                  label={`${this.fromData[i]['label']}`}
                >
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    {
                      this.fromData[i]['name'].map((item, index) => {
                        return (
                          <Form.Item
                            key={item + index}
                            name={item}
                            style={{ flex: 1, marginLeft: '3px', marginRight: '3px' }}
                            rules={[
                              {
                                required: this.fromData[i]['required'],
                              },
                            ]}
                          >
                            {
                              this.headerSearchItem({
                                type: this.fromData[i]['type'],
                                key: item + index,
                                list: this.fromData[i]['list']
                              })
                            }
                          </Form.Item>
                        )
                      })
                    }
                  </div>
                </Form.Item>
              )
              :
              (<Form.Item
                name={`${this.fromData[i]['name']}`}
                label={`${this.fromData[i]['label']}`}
                rules={[
                  {
                    required: this.fromData[i]['required'],
                    message: 'Input something!',
                  },
                ]}
              >
                {
                  this.headerSearchItem({
                    type: this.fromData[i]['type'],
                    key: this.fromData[i]['name'],
                    list: this.fromData[i]['list']
                  })
                }
              </Form.Item>)
          }
        </Col>
      )
    }
    return children;
  }
  
  onReset = () => {
    this.formRef.current.resetFields();
    this.onResetData()
  };
  
  onFinish = formData => {
    this.onSearch(formData)
  };

  render() {
    return (
      <div className="header-search">
        <Form {...this.layout} ref={this.formRef} name="control-ref" onFinish={this.onFinish}>
          <Row gutter={24}>
            {this.getFields()}
            <Col
              span={24}
              style={{
                textAlign: 'right',
              }}
            >
              <Button type="primary" htmlType="submit">
                搜索
              </Button>
              <Button
                style={{
                  margin: '0 8px',
                }}
                onClick={this.onReset}
              >
                重置
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }

}

export default headerSearch;