import request from '../../request';

const ChannelSelect = () =>{
    return request
        .get(`magicClass/getChannelSelect`, {
            params: {
            }
        })
        .then(result => {
            return result.data
        })
}

const GetChannelGoodsSelect = ({
    goodId,
}) =>{
    return request
        .get(`magicClass/getChannelGoodsSelect`, {
            params: {
                goodId
            }
        })
        .then(result => {
            return result.data
        })
}


const GetClassSelect = ({
    goodId,
}) =>{
    return request
        .get(`magicClass/getClassSelect`, {
            params: {
                goodId
            }
        })
        .then(result => {
            return result.data
        })
}

function RecordingAdd({
    uid,
    orderId,
    thirdPay,
    amount,
    goodId,
    classId
}) {
    return request
        .post(`magicClass/openThird`, {
            uid,
            orderId,
            amount,
            thirdPay,
            goodId,
            classId
        })
        .then(response => {
            return response.data
        })
}

function EditUserAgeInfo({
    uid,
    age,
    foundation,
}) {
    return request
        .post(`magicClass/userWxBindUpdate`, {
            uid,
            age,
            foundation,
        })
        .then(response => {
            return response.data
        })
}

function WxEditUserAgeInfo({
    uid,
    age,
    foundation,
    qyUserId
}) {
    return request
        .post(`qyMagicClass/userWxBindUpdate`, {
            uid,
            age,
            foundation,
            qyUserId
        },{
          needSign: true
        })
        .then(response => {
            return response.data
        })
}

// eslint-disable-next-line
export default {
    ChannelSelect,
    GetChannelGoodsSelect,
    GetClassSelect,
    RecordingAdd,
    EditUserAgeInfo,
    WxEditUserAgeInfo
}