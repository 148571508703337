import React, { Component, Fragment } from "react";
import { Button, Tabs, Modal, Input, Select, message } from "antd";
// import tools from "../../../lib/tools/index"
import { GetUserDetail } from "@/lib/api/userManage/vip.js";
import UserApi from "../../../lib/api/userManage/user";
import DetailApi from "../../../lib/api/userManage/details";
const { Option } = Select;
const { TextArea } = Input;
const { TabPane } = Tabs;
require("./index.less");
function callback(key) {}
class ExperienceDetails extends Component {
  state = {
    startEdit: false,
    isModalVisible: false,
    creatTimeArrInfo: '',
    followUp: false,
    intention: null, //意向
    remark: "", //备注
    payAmount: "", //支付金额
    orderId: "", //订单号
    thirdPayType: null, //第三方支付类型
    goodId: "", //商品ID
    classId: "", //班级id
    editAge: "",
    editFoundation: "",
    intentionList: [],
    thirdPayList: [],
    marketChannelList: [],
    userInfo: {
      age: "",
      foundation: "",
    },
    lessonInfo: [],
    courseInfo: [],
    orderInfo: [],
    logistics: [],
    followInfo: [],
    goodsList: [],
    classList: [],
  };
  UNSAFE_componentWillMount() {
    this.Init()
  }
  Init() {
    this.GetUserDetail();
    this.GetUserList();
    this.GetReocrd();
  }

  GetUserList = () => {
    UserApi.UserList({}).then(
      (res) => {
        if (res.code === 1) {
          this.setState({
            intentionList: res.data.userPurpose,
          });
        } else {
          message.error(res.msg);
        }
      },
      (error) => {
        console.log("failed!");
      }
    );
  };

  GetUserDetail = () => {
    GetUserDetail(this.props.match.params.id).then((res) => {
      if (res.data.code === 1) {
        if(res.data.data.userInfo.age===null){
          res.data.data.userInfo.age = ''
        }
        if(res.data.data.userInfo.foundation===null){
          res.data.data.userInfo.foundation = ''
        }
        this.setState({
          userInfo: res.data.data.userInfo,
          orderInfo: res.data.data.orderInfo,
          followInfo: res.data.data.followRecords,
          logistics: res.data.data.deliveryRecords,
          lessonInfo: res.data.data.integrateRecords,
          courseInfo: res.data.data.courseInfo,
        });
        if(res.data.data.orderInfo.length>0){
          this.setState({
            creatTimeArrInfo: res.data.data.orderInfo[res.data.data.orderInfo.length-1].create_time
          })
        }
      } else {
        message.error(res.data.msg)
      }
    });
  };

  GetReocrd = () => {
    DetailApi.ChannelSelect({}).then(
      (res) => {
        if (res.code === 1) {
          this.setState({
            thirdPayList: res.data.thirdPayList,
            marketChannelList: res.data.marketChannel,
          });
        } else {
          message.error(res.msg);
        }
      },
      (error) => {
        console.log("failed!");
      }
    );
  };

  EditUserInfoBtn = () => {
    this.setState({
      editAge: this.state.userInfo.age,
      editFoundation: this.state.userInfo.foundation,
      startEdit: !this.state.startEdit,
    });
  };
  //提交对用户年龄学龄的编辑
  EditUserAge = () => {
    DetailApi.EditUserAgeInfo({
      uid: this.state.userInfo.uid,
      age: this.state.editAge,
      foundation: this.state.editFoundation,
    })
      .then((res) => {
        if (res.code === 1) {
          message.success("操作成功");
          this.GetUserList();
          this.GetUserDetail();
          this.setState({
            age: "",
            foundation: "",
            startEdit: !this.state.startEdit,
          });
        } else {
          message.error(res.msg);
        }
      })
      .catch((error) => {
        console.log(error.request.status);
      });
  };

  //打开录单对话框
  Recording = () => {
    this.setState({
      isModalVisible: !this.state.isModalVisible,
    });
  };

  //添加跟进记录对话框
  FollowUpModal = () => {
    this.setState({
      followUp: !this.state.followUp,
    });
  };

  //选择用户意向
  HandleIntention = (value) => {
    this.setState({
      intention: `${value}`,
    });
  };
  //选择第三方支付方式
  HandleThirdPay = (value) => {
    this.setState({
      thirdPayType: `${value}`,
    });
  };
  //选中渠道号
  HandleMarketChannel = (value) => {
    this.setState(
      {
        marketChannelId: `${value}`,
        goodId: '',
        classId: ''
      },
      function () {
        this.GetGoods(value);
      }
    );
  };
  //选中商品
  HandleGoodsChoose = (value) => {
    this.setState(
      {
        goodId: `${value}`,
        classId: ''
      },
      function () {
        this.GetClass(value);
      }
    );
  };
  HandleclassChoose = (value) => {
    this.setState({
      classId: `${value}`,
    });
  };
  GetGoods = (id) => {
    DetailApi.GetChannelGoodsSelect({ goodId: id }).then(
      (res) => {
        if (res.code === 1 && res.data.list) {
          this.setState({
            goodsList: res.data.list,
          });
        } else {
          message.error(res.msg);
        }
      },
      (error) => {
        console.log("failed!");
      }
    );
  };
  GetClass = (id) => {
    DetailApi.GetClassSelect({ goodId: id }).then(
      (res) => {
        if (res.code === 1 && res.data.list) {
          this.setState({
            classList: res.data.list,
          });
        } else {
          message.error(res.msg);
        }
      },
      (error) => {
        console.log("failed!");
      }
    );
  };

  //写入备注
  AddRemark = (e) => {
    this.setState({
      remark: e.target.value,
    });
  };
  //写入支付金额
  AddAmount = (e) => {
    this.setState({
      payAmount: e.target.value,
    });
  };
  //输入用户年龄
  EditAge = (e) => {
    this.setState({
      editAge: e.target.value,
    });
  };
  //输入用户学龄
  EditFoundation = (e) => {
    this.setState({
      editFoundation: e.target.value,
    });
  };
  //输入订单号
  AddOrder = (e) => {
    this.setState({
      orderId: e.target.value,
    });
  };
  //提交跟进记录
  HandleRecord = () => {
    UserApi.FollowRecord({
      uid: this.state.userInfo.uid,
      purpose: this.state.intention,
      remark: this.state.remark,
    })
      .then((res) => {
        if (res.code === 1) {
          message.success("操作成功");
          this.GetUserList();
          this.GetUserDetail();
          this.setState({
            intention: null,
            remark: "",
            followUp: false,
          });
        } else {
          message.error(res.msg);
        }
      })
      .catch((error) => {
        console.log(error.request.status);
      });
  };
  //所有的关闭弹窗，对话框事件
  HandleCancel = () => {
    this.setState({
      startEdit: false,
      isModalVisible: false,
      followUp: false,
    });
  };
  Timestamp = (timestamp) => {
    var d = new Date(timestamp * 1000); //根据时间戳生成的时间对象
    var year = d.getFullYear();
    var month = d.getMonth() + 1;
    var day = d.getDate();
    var hour = d.getHours();
    var minute = d.getMinutes();
    var second = d.getSeconds();
    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }
    if (hour < 10) {
      hour = "0" + hour;
    }
    if (minute < 10) {
      minute = "0" + minute;
    }
    if (second < 10) {
      second = "0" + second;
    }
    let html =
      year +
      "-" +
      month +
      "-" +
      day +
      "  " +
      hour +
      ":" +
      minute +
      ":" +
      second;
    return html;
  };
  //录单-->确认
  HandleAddRecord = () => {
    DetailApi.RecordingAdd({
      uid: this.state.userInfo.uid,
      orderId: this.state.orderId,
      thirdPay: this.state.thirdPayType,
      amount: this.state.payAmount,
      goodId: this.state.goodId,
      classId: this.state.classId,
    })
      .then((res) => {
        if (res.code === 1) {
          message.success("操作成功");
          this.setState({
            intention: null,
            orderId: "",
            payAmount: "",
            remark: "",
            followUp: false,
            isModalVisible: false
          });
          this.GetUserList();
          this.GetUserDetail();
        } else {
          message.error(res.msg);
        }
      })
      .catch((error) => {
        console.log(error.request.status);
      });
  };

  render() {
    let thirdPayList = this.state.thirdPayList.map((item) => {
      return (
        <Option value={`${item.id}`} key={item.id + "a"}>
          {item.title}
        </Option>
      );
    });
    let marketChannelList = this.state.marketChannelList.map((item) => {
      return (
        <Option value={`${item.id}`} key={item.id + "b"}>
          {item.title}
        </Option>
      );
    });
    let goodsList = this.state.goodsList.map((item) => {
      return (
        <Option value={`${item.id}`} key={item.id + "c"}>
          {item.title}
        </Option>
      );
    });
    let classList = this.state.classList.map((item) => {
      return (
        <Option value={`${item.id}`} key={item.id + "d"}>
          {item.title}
        </Option>
      );
    });
    let optionDataList = this.state.intentionList.map((items, indexs) => {
      return (
        <Option value={`${items.value}`} key={items.value + "e"}>
          {items.title}
        </Option>
      );
    });
    return (
      <div className="user-details">
        <div className="left-info">
          <div className="avatar-box">
            <div className="avatar">
              <img src={this.state.userInfo.wechatAvatar ? this.state.userInfo.wechatAvatar : this.state.userInfo.avatar} alt="" />
              {this.state.userInfo.is_vip === 1 ? (
                <div className="vip-tag">vip</div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="common-style">
            <div className="introduce-title">用户名:</div>
            {this.state.userInfo.wechatNickName ? this.state.userInfo.wechatNickName : this.state.userInfo.nick_name}
          </div>
          <div className="common-style">
            <div className="introduce-title">手机:</div>
            {this.state.userInfo.mobile}
          </div>
          <div className="common-style">
            <div className="introduce-title">趣配音账号:</div>
            {this.state.userInfo.user_number}
          </div>
          <div className="common-style">
            <div className="introduce-title">渠道:</div>{" "}
            {this.state.userInfo.channel_name}
          </div>
          <div className="common-style">
            <div className="introduce-title">报名时间:</div>{" "}
            {this.state.creatTimeArrInfo}
          </div>
          <div className="common-style">
            <div className="introduce-title">年龄:</div>
            {this.state.startEdit ? (
              <Input
                value={this.state.editAge}
                onChange={(e) => this.EditAge(e)}
              ></Input>
            ) : (
              this.state.userInfo.age + "岁"
            )}
          </div>
          <div className="common-style">
            <div className="introduce-title">学龄:</div>
            {this.state.startEdit ? (
              <Input
                value={this.state.editFoundation}
                onChange={(e) => this.EditFoundation(e)}
              ></Input>
            ) : (
              `${this.state.userInfo.foundation}年`
            )}
          </div>
          {this.state.startEdit ? (
            <div className="flex-btn">
              <Button onClick={this.EditUserInfoBtn}>取消</Button>
              <Button type="primary" onClick={this.EditUserAge}>
                保存
              </Button>
            </div>
          ) : (
            <div className="flex-btn">
              <Button type="primary" onClick={this.EditUserInfoBtn}>
                编辑
              </Button>
            </div>
          )}
        </div>
        <div className="right-info">
          <div className="flex-btn">
            <Button onClick={this.Recording}>录单</Button>
            <Button type="primary" onClick={this.FollowUpModal}>
              添加跟进记录
            </Button>
          </div>
          <Tabs defaultActiveKey="1" onChange={callback}>
            <TabPane tab="课程信息" key="1">
              {this.state.courseInfo.map((ele) => {
                return (
                  <Fragment key={ele.order_id}>
                    <div className="level-container">
                      <div className="level-list">
                        <div className="level-left">
                          课程等级: {ele.levelTitle}
                        </div>
                        <div className="level-right">
                          班级名称： {ele.className}
                        </div>
                      </div>
                      <div className="level-list">
                        <div className="level-left">
                          SKU类型: {ele.skuType}
                        </div>
                        <div className="level-right">
                          开课时间： {ele.startTime}
                        </div>
                      </div>
                      <div className="level-list">
                        <div className="level-left">
                          老师: {ele.teacher}
                        </div>
                        <div className="level-right">
                          课程进度: {ele.courseProgress }/{ele.dailyTotal}
                        </div>
                      </div>
                    </div>
                  </Fragment>
                );
              })}
            </TabPane>
            <TabPane tab="跟进信息" key="2">
              {this.state.followInfo.map((ele, index) => {
                return (
                  <Fragment key={index + ele.create_time}>
                    <div className="level-container">
                      <div className="level-list">
                        <div className="level-left">
                          用户意向: {ele.purpose_name}
                        </div>
                        <div className="level-right">
                          跟进时间: {ele.create_time}
                        </div>
                      </div>
                      <div className="level-list">
                        <div className="level-left">
                          跟进老师: {ele.admin_name}
                        </div>
                      </div>
                      <div className="level-list">
                        <div className="information">备注:{ele.remark}</div>
                      </div>
                    </div>
                  </Fragment>
                );
              })}
            </TabPane>
            <TabPane tab="订单信息" key="3">
              {this.state.orderInfo.map((ele) => {
                return (
                  <Fragment key={ele.order_id}>
                    <div className="level-container">
                      <div className="level-list">
                        <div className="level-left">订单号: {ele.orderNumber}</div>
                        <div className="level-right">
                          下单时间: {ele.create_time}
                        </div>
                      </div>
                      <div className="level-list">
                        <div className="level-left">支付金额:{ele.amount}</div>
                        <div className="level-right">
                          商品名称:{ele.goodsName}
                        </div>
                      </div>
                      <div className="level-list">
                        <div className="level-left">
                          sku类型: {ele.skuTypeName}
                        </div>
                        <div className="level-right">
                          等级: L{ele.level}
                        </div>
                      </div>
                    </div>
                  </Fragment>
                );
              })}
            </TabPane>
            <TabPane tab="物流信息" key="4">
              {this.state.logistics.map((ele, index) => {
                return (
                  <Fragment key={index}>
                    <div className="level-container">
                      <div className="level-list">
                        <div className="level-left">
                          快递内容:{ele.goodsName}
                        </div>
                        <div className="level-right">
                          发货时间: {this.Timestamp(ele.deliver_time)}
                        </div>
                      </div>
                      <div className="level-list">
                        <div className="level-left">
                          快递单号:{ele.express_number}
                        </div>
                        <div className="level-right">
                          收货地址: {ele.consignee_address}
                        </div>
                      </div>
                    </div>
                  </Fragment>
                );
              })}
              {this.state.lessonInfo.map((ele, index) => {
                return (
                  <Fragment key={index+ele.deliver_time}>
                    <div className="level-container">
                      <div className="level-list">
                        <div className="level-left">
                          快递内容:{ele.goodsName}
                        </div>
                        <div className="level-right">
                          发货时间: {this.Timestamp(ele.deliver_time)}
                        </div>
                      </div>
                      <div className="level-list">
                        <div className="level-left">
                          快递单号:{ele.express_number}
                        </div>
                        <div className="level-right">
                          收货地址: {ele.address}
                        </div>
                      </div>
                    </div>
                  </Fragment>
                );
              })}
            </TabPane>
          </Tabs>
        </div>
        <Modal
          title="录单"
          visible={this.state.isModalVisible}
          onOk={this.HandleAddRecord}
          onCancel={this.HandleCancel}
          className="MyModal"
        >
          <div className="common-input-style">
            <div className="introudce">支付金额:</div>
            <Input
              value={this.state.payAmount}
              onChange={(e) => this.AddAmount(e)}
            ></Input>
          </div>
          <div className="common-input-style">
            <div className="introudce">订单号:</div>
            <Input
              value={this.state.orderId}
              onChange={(e) => this.AddOrder(e)}
            ></Input>
          </div>
          <div className="common-input-style">
            <div className="introudce">第三方支付类型:</div>
            <Select
              style={{
                width: 120,
              }}
              value={this.state.thirdPayType}
              onChange={this.HandleThirdPay}
            >
              {thirdPayList}
            </Select>
          </div>
          <div className="common-input-style">
            <div className="introudce">渠道号:</div>
            <Select
              style={{
                width: 120,
              }}
              value={this.state.marketChannelId}
              onChange={this.HandleMarketChannel}
            >
              {marketChannelList}
            </Select>
          </div>
          <div className="common-input-style">
            <div className="introudce">商品:</div>
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              style={{
                width: 120,
              }}
              value={this.state.goodId}
              onChange={this.HandleGoodsChoose}
            >
              {goodsList}
            </Select>
          </div>
          <div className="common-input-style">
            <div className="introudce">班级:</div>
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              style={{
                width: 120,
              }}
              value={this.state.classId}
              onChange={this.HandleclassChoose}
            >
              {classList}
            </Select>
          </div>
        </Modal>
        <Modal
          title="跟进记录"
          visible={this.state.followUp}
          onOk={this.HandleRecord}
          onCancel={this.HandleCancel}
          className="MyModal"
        >
          <div className="common-input-style">
            <div className="introudce">
              <span> * </span> 用户昵称:
            </div>
            {this.state.userInfo.nick_name}
          </div>
          <div className="common-input-style">
            <div className="introudce">
              <span> * </span> 用户意向:
            </div>
            <Select
              style={{
                width: 120,
              }}
              value={this.state.intention}
              onChange={this.HandleIntention}
            >
              {optionDataList}
            </Select>
          </div>
          <div className="common-input-style">
            <div className="introudce">
              <span> </span> 备注:
            </div>
            <TextArea
              value={this.state.remark}
              onChange={(e) => this.AddRemark(e)}
              rows={4}
            />
          </div>
        </Modal>
      </div>
    );
  }
}
export default ExperienceDetails;
